import { Backdrop, Box, Button, CircularProgress, Dialog, TextField } from "@mui/material"
import { useState } from "react"
import { SnackBar } from "../SnackBar";
import { Apis } from "../../auth/GlobalUrl";
import axios from "axios";


export const ParentResetPassword = ({ data }) => {

    const [dialog, setDialog] = useState(false)
    const [showValidation, setShowValidation] = useState('')
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "success",
    });

    const [loader, setLoader] = useState(false)

    const [password, setPassword] = useState({
        password: '',
        confirmPassword: ''
    })

    async function resetPassword(e) {
        setShowValidation("")
        e.preventDefault()
        console.log(data)
        if (password.password !== password.confirmPassword) {
            setShowValidation("")
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Password and Confirm password should be same",
                type: "error",
            });
        }
        else {

            let passwordregex =
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]{8,16}$/;

            if (password.password.match(passwordregex) !== null) {
                setShowValidation("")
                try {
                    setLoader(true)
                    setDialog(false)
                    let res = await axios.put(Apis.PARENT.resetPassword + data._id, { password: password.password })

                    console.log(res)
                    ShowSnackbar({
                        show: true,
                        vertical: "top",
                        horizontal: "right",
                        msg: "Password Updated Successfully",
                        type: "success",
                    });
                    setPassword({
                        password: '',
                        confirmPassword: ''
                    })

                    setLoader(false)
                }
                catch (err) {
                    console.log(err)
                    setLoader(false)
                }
            }
            else {
                setShowValidation('Minimum length of password should be 8 character Password must contain: At least one UPPERCASE, one lowercase, one number, one special character(!,@,#,$')
            }
        };
    }


    return (
      <>
        <Button
          variant="contained"
          color="info"
          // size="small"
          className="btn_primary"
          sx={{ ml: 2,fontSize:"10px" }}
          onClick={() => setDialog(true)}
        >
          Reset Password
        </Button>

        <Dialog
          open={dialog}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "500px",
              },
            },
          }}
        >
          <Box px={2} className="py-5 gap-4">
            <form onSubmit={resetPassword}>
              <div className="mb-3">
                <TextField
                  value={password.password}
                  onChange={(e) =>
                    setPassword({ ...password, password: e.target.value })
                  }
                  label="Password"
                  fullWidth
                />
              </div>

              <div>
                <TextField
                  value={password.confirmPassword}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value,
                    })
                  }
                  label="Confirm Password"
                  fullWidth
                />
              </div>
              <div style={{ color: "red" }}>{showValidation}</div>
              <div className="d-flex justify-content-end pt-4">
                <Button
                  className="cancel_btn me-3"
                  size="small"
                  onClick={() => {
                    setShowValidation("");
                    setPassword({
                      password: "",
                      confirmPassword: "",
                    });
                    setDialog(false);
                  }}
                  type="button"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="info"
                  size="small"
                >
                  Submit
                </Button>
              </div>
            </form>
          </Box>
        </Dialog>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
}