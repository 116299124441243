import { useEffect } from "react"
import { useState } from "react"
import styles from './index.module.css'
import { Grid } from "@mui/material"

export const ShowPastTermGoalCard = ({ data }) => {
    // const [olddata, setOldData] = useState([
    //     { title: 'Activities For The Term', data: data.activityFor_term },
    //     { title: 'Month 1 Observation', data: data.month_one?.observation, mkey: 'month_one' },
    //     { title: 'Month 2 Observation', data: data.month_two?.observation, mkey: 'month_two' },
    //     { title: 'Month 3 Observation', data: data.month_three?.observation, mkey: 'month_three' },
    //     { title: 'End Term Assessment', data: data.endterm_assessment },
    //     { title: 'Forwarding Planning For The Next Term', data: data.forwardPlanning_nextmonth },
    // ])
    const [olddata, setOldData] = useState([])
    useEffect(() => {
        setOldData([
            { title: 'Activities For The Term', data: data.activityFor_term },
            { title: 'Month 1 Observation', data: data.month_one?.observation, mkey: 'month_one' },
            { title: 'Month 2 Observation', data: data.month_two?.observation, mkey: 'month_two' },
            { title: 'Month 3 Observation', data: data.month_three?.observation, mkey: 'month_three' },
            { title: 'End Term Assessment', data: data.endterm_assessment },
            { title: 'Forwarding Planning For The Next Term', data: data.forwardPlanning_nextmonth },
        ])
    }, [data])
    return (
        <div className="bg-white px-5 mx-5 py-3 mt-3">
            {olddata.map((res, key) => {
                return (
                    <Grid key={key}>
                        <div className="mt-4 mb-2 d-flex justify-content-between" >
                            <div className="">
                                <b> {res.title}</b>
                            </div>
                            {
                                res.mkey !== undefined ?
                                    <div className="d-flex justify-content-around">
                                        <div>Submitted By :- {data[res.mkey]?.Submitted_by}</div> ,
                                        <div>Updated By :- {data[res.mkey]?.Edited_by}</div> ,
                                        <div>Submitted on :-{new Date(data[res.mkey]?.created_at).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' })}</div> ,
                                        <div>Edited on :- {new Date(data[res.mkey]?.updated_at).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' })}</div>
                                    </div> : null
                            }
                        </div>
                        <div>
                            <textarea elastic value={res.data} className={`form-control ${styles.textArea}`}
                                rows={res.data.split('\n').length}
                            ></textarea>
                        </div>
                    </Grid>
                )
            })}
        </div>
    )
}