import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Apis } from '../../auth/GlobalUrl'

export const educatorPinCrud = createAsyncThunk('educatorPinCrud', async (data) => {
    let id = localStorage.getItem('educatorId')
    if (id !== null) {
        const res = await axios.get(Apis.EDUCATOR.getPinVisiblity + id)
        return res
    }
})

export const educatorPinSlice = createSlice({
    name: 'educatorPin',
    initialState: true,
    reducers: {
        setPinStatus: (state, action) => {
            return action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(educatorPinCrud.fulfilled, (state, action) => {

            let sess = sessionStorage.getItem("EducatorPinAuth")
            if (sess == null) {
                return false
            }
            console.log(action.payload.data.pin_activity)
            return action.payload.data.pin_activity
        })
    }
})
export const { setPinStatus } = educatorPinSlice.actions