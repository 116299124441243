import { Add } from "@mui/icons-material";
import { Dialog, Typography, Button, Box, TextField, Autocomplete, Container, Backdrop, CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Apis } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";


export const AddSubsectionIntoSection = ({ sectionData, fun }) => {

    let [dialog, setDialog] = useState(false)
    let [loader, setLoader] = useState(false)
    let [subSectionName, setSubSectionName] = useState("")
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "error",
    });
    async function addSubSection() {
        setDialog(false)
        setLoader(true)
        try {
            let tenant_id = localStorage.getItem("tenant_id")
            let data = {
                subsection_name: subSectionName,
                tenant_id,
                section_id: sectionData._id
            }
            let res = await axios.post(Apis.ASSESSMENT.addSubsection, data)
            console.log(res)
            setLoader(false)
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Subsection Added successfully",
                type: "success",
            })

            fun()
        }
        catch (err) {
            console.log(err)
            setLoader(false)
            setDialog(true)
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Some error occured",
                type: "error",
            })
        }

    }


    function openMainDialog() {
        setDialog(true)

    }


    return (
        <>
            <Button className="btn_primary" onClick={openMainDialog}>AddSub</Button>
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog fullWidth={true} open={dialog}>

                <Typography variant="h4" align="center" sx={{ my: 3 }}>
                    Add Sub Section
                </Typography>
                <form onSubmit={addSubSection}>
                    <Container>
                        <TextField
                            fullWidth
                            required
                            label="Sub Section Name"
                            sx={{ my: 3 }}
                            onChange={(e) => setSubSectionName(e.target.value)}
                        />
                    </Container>
                    <Container sx={{ my: 3 }} align="right">
                        <Button
                            className="cancel_btn"
                            onClick={() => setDialog(false)}
                        >
                            Cancel
                        </Button>
                        <Button className="btn_primary" sx={{ mx: 2 }} type="submit">
                            Add
                        </Button>
                    </Container>
                </form>

            </Dialog>


        </>
    )
}