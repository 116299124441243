import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button, Checkbox, Grid, InputAdornment, TextField } from '@mui/material';
import { Sidebar } from '../../components/Sidebar';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate, Link } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import { useState } from 'react';
import { baseURL } from '../../auth/GlobalUrl';

export default function EducatorListing() {
    const [data, setData] = useState([])
    console.log(data)
    const navigate = useNavigate()
    const [assignChild, setAssignChild] = useState([])
    const [value, setValue] = useState('')
    const [tableFilter, setTableFilter] = useState([])
    // console.log(search) 
    const onSearch = (e) => {
        if (e.target.value !== ' ') {
            setValue(e.target.value)
            const filterTable = data.filter(o => Object.keys(o).some(k => String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())))
            setTableFilter([...filterTable])
        } else {
            setValue(e.target.value)
            setData(...data)
        }

    }

    useEffect(() => {
        axios.get(`${baseURL}teacher/getAllTeacher/${localStorage.getItem('tenant_id')}`)
            .then((res) => {
                console.log(res)
                setData(res.data.data)
                setAssignChild(res.data.data.teacher_child)
            }).catch((err) => {
                console.log(err)
            })
    }, [])
    return (
        <Sidebar>
            <Grid container >
                <Grid container spacing={2} mt={2} px={2} py={3} sx={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
                    <Grid item>
                        <TextField
                            id="input-with-icon-textfield"
                            onChange={onSearch}
                            value={value}
                            placeholder="Search Educator"
                            size="medium"
                            sx={{ backgroundColor: 'white', minWidth: 400 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        {/* <Link to='/teacherdetails'><AddCircleIcon color="info" /></Link> */}
                        <Button
                            variant="other"
                            sx={{
                                background: "#1799CD",
                                color: "#ffffff",
                                border: "1px solid #1799CD",
                                marginLeft: "10px",
                            }}
                        // onClick={postData}
                        >
                            Remove Educator
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid px={2}>
                <TableContainer component={Paper} sx={{ boxShadow: '0 0 5px 0 #888888' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ boxShadow: '0 0 5px 0 #888888' }}>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell align="left">Teacher Name</TableCell>
                                <TableCell align="left">Age</TableCell>
                                <TableCell align="left">Assign Child</TableCell>
                                <TableCell align="left">Phone Number</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        { value.length > 0 ? tableFilter.map((row, index) =>
                                <TableRow
                                    key={index}

                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, boxShadow: '0 0 5px 0 #888888' }}
                                >
                                    <TableCell component="th" sx={{ width: '50px' }}>
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell sx={{ width: '100px' }}>
                                        <img
                                            style={{
                                                width: "40px",
                                                height: "40px",
                                                borderRadius: "50px",
                                            }}
                                            src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                                        />
                                    </TableCell>
                                    <TableCell align="left">{row.teacher_name}</TableCell>
                                    <TableCell align="left">{row.teacher_age}</TableCell>
                                    <TableCell align="left">{row.teacher_child.length}</TableCell>
                                    <TableCell align="left">{row.teacher_number}</TableCell>
                                </TableRow>
                            ):data.map((row, index) =>
                            <TableRow
                                key={index}

                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, boxShadow: '0 0 5px 0 #888888' }}
                            >
                                <TableCell component="th" sx={{ width: '50px' }}>
                                    <Checkbox />
                                </TableCell>
                                <TableCell sx={{ width: '100px' }}>
                                    <img
                                        style={{
                                            width: "40px",
                                            height: "40px",
                                            borderRadius: "50px",
                                        }}
                                        src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
                                    />
                                </TableCell>
                                <TableCell align="left">{row.teacher_name}</TableCell>
                                <TableCell align="left">{row.teacher_age}</TableCell>
                                <TableCell align="left">{row.teacher_child.length}</TableCell>
                                <TableCell align="left">{row.teacher_number}</TableCell>
                            </TableRow>
                        )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container px={6}>
                    <Grid
                        item
                        mt={6}
                        md={12}
                        sm={12}
                        xs={12}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                        <Button
                            variant="other"
                            sx={{ border: "1px solid #FF0000", color: "red" }}
                            onClick={() => {

                                //   navigate("/educators");
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="other"
                            sx={{
                                background: "#1799CD",
                                color: "#ffffff",
                                border: "1px solid #1799CD",
                                marginLeft: "10px",
                            }}
                        // onClick={postData}
                        >
                            Add Educator
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Sidebar>
    )
}
