import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  OutlinedInput,
  IconButton,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";
import { contextData } from "../../Router";
import { Box, Container } from "@mui/system";
import * as XLSX from "xlsx";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useRef } from "react";
import { ProfileLogoName } from "../../components/ProfileLogoName";
import { ParentResetPassword } from "./ParentResetPassword";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Popover from "@mui/material/Popover";
import { ActionsButtons } from "./ActionsButtons";
export default function ParentDetails() {
  var [data, setData] = useState([]);
  const [value, setValue] = useState("");
  const [tableFilter, setTableFilter] = useState([]);
  var navigate = useNavigate();
  let tenant_id = localStorage.getItem("tenant_id");
  const [openDialog, setOpenDialog] = useState(false);
  let [openDelete, setOpenDelete] = useState(false);
  let [deleteParentId, setDeleteParentId] = useState("");
  let [openLoader, setOpenLoader] = useState(false);
  let [deleteParentDialog, setDeleteParentDialog] = useState(false);
  let [parentError, setParentError] = useState([]);
  let [childError, setChildError] = useState([]);
  // const [actionOpen,setActionOpen] = useState({action:false})
  const [actionOpen, setActionOpen] = useState(false);

  let snackbarContext = useContext(contextData);

  let arr1 = [{ row: 1, column: 2, errors: 3 }];

  let dd = useRef("ppp");

  dd.current = "new value";

  let newPassword = useRef();
  let confirmPassword = useRef();

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  useEffect(() => {
    getData();
    if (snackbarContext.state) {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Parent saved successfully",
        type: "success",
      });
      snackbarContext.setState(false);
    }
  }, []);

  const getData = () => {
    setOpenLoader(true);
    axios
      .get(`${baseURL}parent/getAllparent/${tenant_id}`)
      .then((res) => {
        // console.log(res.data.data)
        setOpenLoader(false);

        setData(res.data.data);
        setTableFilter(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = (e) => {
    setTableFilter(
      data.filter(
        (r) =>
          r.father_name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase()) ||
          r.mother_name
            .trim()
            .toLowerCase()
            .includes(e.target.value.trim().toLowerCase())
      )
    );
  };

  function deleteParent() {
    setDeleteParentDialog(false);
    setOpenLoader(true);
    let url = `${baseURL}parent/deleteParent&Child&CfromRoom/${deleteParentId}`;
    axios
      .delete(url)
      .then((res) => {
        setOpenLoader(false);

        setOpenDelete(false);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Parent deleted successfully",
          type: "success",
        });
        getData();
      })
      .catch((err) => console.log(err));
  }

  const [excelData, setExcelData] = useState("");

  async function uploadExcel() {
    setOpenDialog(false);
    let fileReader = new FileReader();
    let nameArr = excelData.name.split(".");
    if (
      nameArr[nameArr.length - 1] === "xlsx" ||
      nameArr[nameArr.length - 1] === "xls" ||
      nameArr[nameArr.length - 1] === "XLSX"
    ) {
      fileReader.readAsBinaryString(excelData);
      fileReader.onload = () => {
        let workbook = XLSX.read(fileReader.result, { type: "binary" });
        let sheetNames = workbook.SheetNames;
        let parentData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetNames[0]]
        );
        let childData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetNames[1]]
        );
        if (parentData.length === 0 || childData.length === 0) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: `Excel file is empty`,
            type: "warning",
          });
          return;
        }
        let parentErr = [];
        let childErr = [];
        let dubEmail = [];
        let parentId = [];

        let sheetemails = parentData.map(function (item) {
          return item.parent_email;
        });
        sheetemails.forEach(function (item, idx) {
          if (sheetemails.indexOf(item) != idx) {
            dubEmail.push(item);
          }
        });
        let id = parentData.map(function (item) {
          return item.id;
        });
        id.forEach(function (item, idx) {
          if (id.indexOf(item) != idx) {
            parentId.push(item);
          }
        });
        // find empty and error in child sheet
        childData.forEach((e, i) => {
          let error = [];
          if (e.id === undefined) error.push("id (empty)");
          if (e.child_name === undefined) error.push("child_name (empty)");
          if (e.goals === undefined) error.push("goals (empty)");
          if (e.child_lastname === undefined)
            error.push("child_lastname (empty)");
          if (e.child_gender === undefined) error.push("child_gender (empty)");
          if (e.child_dob === undefined) error.push("child_dob (empty)");
          if (error.length !== 0) childErr.push({ row: i + 2, error: error });
        });
        parentData.forEach((e, i) => {
          let error = [];
          if (e.id === undefined) {
            error.push("id (empty)");
          } else {
            if (parentId.indexOf(e.id) > -1) error.push("id (already exists)");
          }
          if (e.father_name === undefined) error.push("father_name (empty)");
          if (e.mother_name === undefined) error.push("mother_name (empty)");
          if (e.parent_email === undefined) {
            error.push("parent_email (empty)");
          } else {
            if (dubEmail.indexOf(e.parent_email) > -1)
              error.push("parent_email (already exists)");
          }
          if (e.parent_home_address === undefined)
            error.push("parent_home_address (empty)");
          if (error.length !== 0) parentErr.push({ row: i + 2, error: error });
        });

        if (parentErr.length === 0 && childErr.length === 0) {
          let parnum = 0;
          let errArray = [];
          parentData.forEach((res, index) => {
            let ind = parentData.findIndex(
              (e) => e.parent_email == res.parent_email
            );
            if (ind !== index) {
              parnum++;
              errArray.push(index + 1);
            }
          });
          if (parnum !== 0) {
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: `Line ${errArray.join(",")} Account Duplicate`,
              type: "warning",
            });
            return;
          }

          data.map((res) => {
            let ind = parentData.findIndex(
              (re) => re.parent_email == res.parent_email
            );
            if (ind !== -1) {
              parnum++;
              errArray.push(ind + 1);
            }
          });
          if (parnum == 0) {
            formatDataBeforeUpload(childData, parentData);
          } else {
            ShowSnackbar({
              show: true,
              vertical: "top",
              horizontal: "right",
              msg: `Line ${errArray.join(",")} account a already occur`,
              type: "warning",
            });
            return;
          }
        } else {
          setOpenDialog(true);
          setParentError(parentErr);
          setChildError(childErr);
        }
      };
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Please upload excel file",
        type: "warning",
      });
    }
  }
  function ActionParent({ item }) {
    const [anchorEl, setAnchorEl] =
      (React.useState < HTMLButtonElement) | (null > null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <>
        {/* <MoreVertIcon
          onClick={() => {
            setActionOpen(true);
          }}
        /> */}
        <div>
          <Button
            aria-describedby={id}
            variant="contained"
            onClick={handleClick}
          >
            Open Popover
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
          </Popover>
        </div>
      </>
    );
  }

  function formatDataBeforeUpload(childData, parentData) {
    // find all goals in excel of child 
    let dd = childData.map(e => e.goals.split(',')).flat(Infinity)

    let goals = []
    console.log(dd)
    dd.forEach((resee, index) => {
      if (dd.indexOf(resee) == index) {
        goals.push(resee)
      }
    })
    console.log(goals)
    // add new created goals of excel to database 
    axios.post(Apis.GOAL.addBulkGoalForExcel, { goals, tenant_id }).then((res) => {
      console.log(res)
    })
      .catch(err => console.log(err))

    setOpenLoader(true);
    let finalarr = [];
    parentData.map((res) => {
      let childarr = [];
      childData.map((ch, index) => {
        if (ch.id === res.id) {
          delete ch.id;
          let child_age = ch.configuration_age;
          delete ch.configuration_age;
          childarr.push({
            ...ch,
            goals: ch.goals.split(','),
            child_gender: ch.child_gender.toLowerCase(),
            tenant_id,
            child_age,
            created_at: new Date(),
          });
        }
      });
      delete res.id;
      finalarr.push({
        parent: {
          ...res,
          login_credentials: {
            parent_login_email: res.parent_email,
            parent_password: "",
          },
          tenant_id,
        },
        child: childarr,
      });
    });
    uploadBulkData(finalarr);
  }

  const uploadBulkData = async (data) => {
    try {
      let res = await axios.post(Apis.PARENT.bulkupload, { data });
      setExcelData("");
      setOpenLoader(false);
      getData();
    } catch (err) {
      console.log(err);
      setExcelData("");
    }
  };

  return (
    <Sidebar>
      <Dialog open={openDialog}>
        <Box className="py-5 px-3">
          <div>
            <input
              type="file"
              accept=".xlsx, .xls, .xlsb , .xlsm, .xltx"
              className="form-control"
              onChange={(e) => {
                setExcelData(e.target.files[0]);
              }}
              onClick={() => {
                setChildError([]);
                setParentError([]);
              }}
            />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Button
              className="cancel_btn me-3"
              onClick={() => {
                setOpenDialog(false);
                setExcelData("");
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={uploadExcel}
              variant="contained"
              disabled={excelData?.name == undefined}
              color="info"
              size="small"
            >
              Submit
            </Button>
          </div>
          {parentError.length !== 0 && (
            <>
              <div>
                <h6>Parent Sheet Error</h6>
              </div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Row</TableCell>

                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {parentError.map((data, ind) => (
                      <TableRow>
                        <TableCell>{data.row}</TableCell>
                        <TableCell>{data.error.join(", ")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}

          <br />

          {childError.length !== 0 && (
            <>
              <div>
                <h6> Child Sheet Error</h6>
              </div>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Row</TableCell>

                      <TableCell>Error</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {childError.map((data, ind) => (
                      <TableRow>
                        <TableCell>{data.row}</TableCell>
                        <TableCell>{data.error.join(", ")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Box>
      </Dialog>

      <Grid
        container
        px={2}
        style={{ background: "#EFF6FF", overflowX: "scroll" }}
      >
        <Grid
          container
          py={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid item md={5} sm={5} xs={11}>
            <TextField
              className="searchInput"
              id="input-with-icon-textfield"
              onChange={onSearch}
              placeholder="Search anything"
              size="medium"
              sx={{ backgroundColor: "white" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            md={7}
            sm={7}
            xs={1}
            className="d-flex justify-content-end align-items-center"
          >
            <Button
              className="btn_primary me-3 cap"
              onClick={() => {
                setChildError([]);
                setParentError([]);
                setOpenDialog(true);
              }}
            >
              Import Parents
            </Button>
            <Link to="/addParent">
              <AddCircleIcon className="fs-3" sx={{ color: "#169AC9" }} />
            </Link>
          </Grid>
        </Grid>

        <Grid container>
          <Grid mt={2} item lg={12} md={12} sm={12} xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow sx={{ bgcolor: "#FCFCFC" }}>
                    <TableCell align="left">S.No</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="left">Father Name</TableCell>
                    <TableCell align="left">Mother Name</TableCell>
                    <TableCell align="left">Number of Child</TableCell>
                    <TableCell align="left">Action</TableCell>
                    <TableCell align="left">Add Child</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableFilter.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        bgcolor: "#FCFCFC",
                      }}
                    >
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">
                        <ProfileLogoName
                          firstName={row.father_name}
                          lastName={row.father_lastname}
                        />
                      </TableCell>
                      <TableCell align="left">{row.father_name}</TableCell>
                      <TableCell align="left">{row.mother_name}</TableCell>
                      <TableCell align="left">
                        {row.child_array.length}
                      </TableCell>

                      <TableCell align="left">
                        {/* <VisibilityIcon
                          sx={{ color: "#FFB800", cursor: "pointer" }}
                          onClick={() => navigate(`/parentview/${row._id}`)}
                        />
                        &nbsp;&nbsp;
                        <BorderColorIcon
                          onClick={() => navigate(`/editparent/${row._id}`)}
                          sx={{ color: "#169AC9", cursor: "pointer" }}
                        />
                        &nbsp;&nbsp;
                        <DeleteIcon
                          onClick={() => {
                            setDeleteParentId(row._id);
                            setDeleteParentDialog(true);
                          }}
                          sx={{
                            color: "#FF0000",
                            cursor: "pointer",
                          }}
                        /> */}
                        <ActionsButtons item={row} getData={getData} />
                      </TableCell>
                      <TableCell align="left">
                        <Button
                          className="btn_primary"
                          onClick={() => navigate(`/addchild/${row._id}`)}
                          sx={{ mr: 2, fontSize: "10px" }}
                        >
                          Add Child
                        </Button>
                      </TableCell>
                      <TableCell>
                        <ParentResetPassword data={row} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
      <Dialog maxWidth={"md"} fullWidth={true} open={deleteParentDialog}>
        <Box sx={{ p: 2, py: 4 }}>
          Are you sure you want to delete parent ?
          <br />
          <small style={{ color: "#1799cd" }}>
            Note: child belongs to this parent also deleted
          </small>
          <Container align="right">
            <Button
              onClick={() => setDeleteParentDialog(false)}
              className="cancel_btn me-3"
            >
              Cancel
            </Button>
            <Button onClick={() => deleteParent()} className="btn_primary">
              Delete
            </Button>
          </Container>
        </Box>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
    </Sidebar>
  );
}
