import { Backdrop, CircularProgress, Typography } from "@mui/material"
import { Sidebar } from "../../../components/Sidebar"
import { useEffect, useState } from "react"
import axios from "axios"
import { Apis } from "../../../auth/GlobalUrl"
import { Center } from "./Center"
import { useNavigate } from "react-router-dom"
import { ImageUpload } from "../../../firebase/ImageUpload"
import { SnackBar } from "../../SnackBar"

export const AddCenter = () => {
    let [loader, setLoader] = useState(false)

    let navigate = useNavigate()
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "",
        type: "",
    });

    let [centerData, setCenterData] = useState({
        child_care_center_name: '',
        center_address: '',
        admin_array: [],
        login_activity_time: null
    })


    async function AddCenterSubmit(file) {
        setLoader(true)
        console.log(file)


        if (file.name !== undefined) {
            let logo_url = await ImageUpload(file)
            try {
                let res = await axios.post(Apis.SUPERADMIN.addChildCareCenter, { ...centerData, logo_url })
                console.log(res, 'okokok')
                navigate('/AllCenters')
                setLoader(false)
            }
            catch (err) {
                setLoader(false)
                console.log(err)
            }

        }
        else {
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Please Upload Logo",
                type: "error",
            })
        }
    }

    return (
        <Sidebar>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            <Typography className='text-center fs-2 my-2'>Add Center</Typography>

            <Center mode={'Add'} allData={{ data: centerData, setData: setCenterData }} formSubmit={AddCenterSubmit} />

        </Sidebar>
    )
}