import { configureStore } from '@reduxjs/toolkit'
import { educatorPinSlice } from './Slices/EducatorPinSlice'
import { ChildCareCentersSlice } from './Slices/ChildCareCenters'
import { ParentLogoSlice } from './Slices/ParentLogoSlices'
import { educatorLoginVisiblityTimeSlice } from './Slices/GetEducatorLogInVisiblityTime'

export const store = configureStore({
    reducer: {
        educator_pin_activity: educatorPinSlice.reducer,
        Childcare_centers: ChildCareCentersSlice.reducer,
        Parent_logo: ParentLogoSlice.reducer,
        Educator_login_activity_time: educatorLoginVisiblityTimeSlice.reducer
    },
})

