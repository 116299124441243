import { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  TextField,
} from "@mui/material";
import { useParams } from "react-router";
import axios from "axios";
import { Apis } from "../../auth/GlobalUrl";
import { TermGoalForm } from "./TermGoalForm";
import styles from "./index.module.css";
import { PastTermGoal } from "./PastTermGoal";
import { useDispatch } from "react-redux";
import { educatorPinCrud } from "../../Redux-toolkit/Slices/EducatorPinSlice";
import { ArrowBack, ArrowBackIos } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ProfileLogoName } from "../../components/ProfileLogoName";

export const TermsGoals = () => {
  let { id } = useParams();
  const [isCurrentTermGoal, setIsCurrentTermGoal] = useState(true);
  const [addTermGoalDialog, setAddTermGoalDialog] = useState(false);
  const tenant_id = localStorage.getItem("tenant_id");
  const [addTermGoalsMode, setAddTermGoalsMode] = useState(false);
  const [isCurrentGoalScreen, setIsCurrentGoalScreen] = useState(true);
  const [childRoomId, setChildRoomId] = useState("");
  const [childData, setChildData] = useState({});
  const [childLastData, setChildLastDate] = useState({})
  const [loader, setLoader] = useState(true);

  const [allTermsGoalData, setAllTermGoalData] = useState({});

  const [termAndGoalData, setTermAndGoalData] = useState({
    start_date: "",
    end_date: "",
    activityFor_term: "",
  });

  // add term and goal
  const submitTermGoal = async (e) => {
    e.preventDefault();
    setAddTermGoalDialog(false);
    setTermAndGoalData("");

    var d = new Date(termAndGoalData.start_date);
    console.log(d.toLocaleDateString());
    d.setMonth(d.getMonth() + 3);
    let data = {
      ...termAndGoalData,
      end_date: d.toLocaleDateString(),
      child_id: id,
      tenant_id,
    };

    console.log(data);

    try {
      setLoader(true);
      let res = await axios.post(Apis.EDUCATOR.addTermAndGoal, data);
      console.log(res);
      setLoader(false);
      getCurrentTermGoal();
    } catch (err) {
      setLoader(false);
      console.log(err);
    }
  };
  function inpChange({ name, value }) {
    setTermAndGoalData({ ...termAndGoalData, [name]: value });
  }

  const getCurrentTermGoal = async () => {
    try {
      let res = await axios.post(Apis.EDUCATOR.getCurrentTermGoal + id, {
        data: true,
      });
      console.log(res.data.data);
      if (res.data?.data?.length == 0) {
        setAddTermGoalsMode(true);
        setLoader(false);
      } else {
        console.log(res.data.data);
        setAllTermGoalData(res.data.data[0]);
        setAddTermGoalsMode(false);
        setLoader(false);
      }
    } catch (er) {
      console.log(er);
    }
  };

  useEffect(() => {
    getCurrentTermGoal();
    GetChildRoomId();
  }, []);

  function navClick(e) {
    setIsCurrentGoalScreen(e);
  }

  async function GetChildRoomId() {
    try {
      let res = await axios.get(Apis.CHILD.getChildRoom + id);
      // console.log(res.data)
      setChildData(res.data);
      setChildLastDate(res.data);

      setChildRoomId(res.data.child_room[0]);
    } catch (err) {
      console.log(err);
    }
  }

  let role = localStorage.getItem("role");

  return (
    <Sidebar>
      <Grid px={3}>
        <Link
          to={
            role == "Admin" ? "/childlisting" : `/allchildofroom/${childRoomId}`
          }
        >
          <ArrowBackIos />
        </Link>
      </Grid>
      <div className="d-flex px-4">
        <Grid px={3} mt={2}>
          <div>
            <ProfileLogoName
              firstName={childData.child_name}
              lastName={childLastData.child_lastname}
            />
          </div>
        </Grid>
        <Grid px={1} mt={3}>
          <div>{childData.child_name}</div>
        </Grid>
      </div>
      <div className={`mt-4 d-flex ${styles.text_current_past}`}>
        <div className={` text-center`}>
          <span
            onClick={() => navClick(true)}
            className={`${isCurrentGoalScreen ? styles.current_active : ""
              } pointer`}
          >
            Current Term Goal
          </span>
        </div>
        <div className="current_active text-center">
          <span
            onClick={() => navClick(false)}
            className={`${!isCurrentGoalScreen ? styles.current_active : ""
              } pointer`}
          >
            Past Term Goal
          </span>
        </div>
      </div>
      {/* current terms goal  */}
      {isCurrentTermGoal ? (
        <div>
          <>
            {!loader ? (
              isCurrentGoalScreen ? (
                addTermGoalsMode ? (
                  <div className="d-flex justify-content-between align-items-center m-5 p-2  bg-white">
                    <div>Current Term Goal Has Not Added Yet </div>
                    <div>
                      <Button
                        onClick={() => setAddTermGoalDialog(true)}
                        className="btn_primary"
                      >
                        Add Goals
                      </Button>
                    </div>
                  </div>
                ) : (
                  <TermGoalForm
                    setAddTermGoalsMode={setAddTermGoalsMode}
                    data={allTermsGoalData}
                    setData={setAllTermGoalData}
                    child_id={id}
                  />
                )
              ) : (
                <PastTermGoal />
              )
            ) : (
              <div></div>
            )}
          </>
        </div>
      ) : (
        <div></div>
      )}
      <Dialog open={addTermGoalDialog} fullWidth={true} maxWidth={"sm"}>
        <form onSubmit={submitTermGoal}>
          <div className="p-4 mt-2">
            <div className="d-flex">
              <label className="w-50">Start Date :-</label>

              <TextField
                onChange={(e) => inpChange(e.target)}
                required
                name="start_date"
                type="date"
                // label='dd-mm-yyyy'
                label='Start date'
                className="form-control w-50 textbox-n"
                id="date"
                onfocus="(this.type='date')"
                sx={{
                  outline: "none",
                  margin: "0 auto",
                  marginTop: "1rem",
                  padding: "0.7rem o.5rem",
                  fontFamily: "Quichsand",
                  fontSize: "1rem",
                }}
              />
              {/* <input
                onChange={(e) => inpChange(e.target)}
                required
                name="start_date"
                type="date"
                className="form-control w-50 textbox-n"
                id="date"
                onfocus="(this.type='date')"
                style={{
                  border: "2px solid grey",
                  outline: "none",
                  display: "block",
                  margin: "0 auto",
                  marginTop: "1rem",
                  width: "90%",
                  borderRadius: "5px",
                  padding: "0.7rem o.5rem",
                  fontFamily: "Quichsand",
                  fontSize: "1rem",
                }}
              /> */}
            </div>

            <div className="my-3">
              <label className="mb-2">Activities For The Term :-</label>
              <textarea
                placeholder="Activities for the term*"
                name="activityFor_term"
                value={termAndGoalData.activityFor_term}
                required
                onChange={(e) => inpChange(e.target)}
                className="form-control"
                rows={10}
              ></textarea>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                onClick={() => {
                  setAddTermGoalDialog(false);
                  setTermAndGoalData("");
                }}
                className="cancel_btn mx-3"
              >
                Cancel
              </Button>
              <Button className="btn_primary" type="submit">
                Add
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Sidebar>
  );
};
