import {
    Grid,
    TextField,
    Table,
    TableRow,
    InputAdornment,
    Button,
    TableHead,
    TableBody,
    TableCell,
    Checkbox,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import SearchIcon from "@mui/icons-material/Search";
  import { Sidebar } from "../../components/Sidebar";
  import { Link, useNavigate, useParams } from "react-router-dom";
  import axios from "axios";
  import { baseURL } from "../../auth/GlobalUrl";
  import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
  
  export default function RemoveAssignRoom() {
    const [allRooms, setAllRooms] = useState([]);
    const [all,setAll]=useState([])
    const blankImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
    const {id} = useParams()
    // console.log(`${baseURL}teacher/addChildIntoTeacher/${id}`);
    // console.log(id);
    const navigate = useNavigate()
  let [roomId,setRoomId]=useState([])
    const getData = () => {
      axios
        .get(`${baseURL}teacher/getTeacher/${id}`)
        .then((res) => {
          console.log(res.data.data[0].room_data);
          setAllRooms(res.data.data[0].room_data)
          setAll(res.data.data[0].room_data)
          // setChildId(res.data.data[0].teacher_child)
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    const postData = (e) =>{
      e.preventDefault()
console.log(roomId)
      axios.put(`${baseURL}teacher/reomveRoomIntoTeacher/${id}`,{teacher_room:roomId })
      .then((res)=>{
        console.log(res)
        navigate("/educators");
      }).catch((err)=>{
        console.log(err)
      })
    }
  
    const getDataByCheck = (e, index) => {
      var { value, checked } = e.target;
      checked?setRoomId([...roomId, value]):setRoomId(roomId.filter((e,index) => e !== value))
      // console.log(roomId)
    };

    useEffect(() => {
      getData();
    }, []);
    function searchRoom(e){

      setAllRooms(all.filter(r=>r.room_name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase())))
    }
  
    return (
      <>
        <Sidebar>
        <Grid px={3}>
       <Link to={`/assignroom/${id}`}>
       <ArrowBackIosIcon />
       </Link>
       </Grid>
          <Grid container p={4} sx={{ backgroundColor: "#EFF6FF" }}>
            <Grid container>
              <Grid item md={5} sm={5} xs={10}>
                <TextField
                onChange={searchRoom}
                className='searchInput'
                  id="input-with-icon-textfield"
                  placeholder="Search anything"
                  size="medium"
                  sx={{ backgroundColor: "white"}}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                md={7}
                sm={7}
                xs={2}
                className="d-flex justify-content-end"
              >
              
              </Grid>
            </Grid>
            <Grid container mt={4}>
              <Grid item md={12} sm={12} xs={12}>
                <Table
                  sx={{
                    border: "2px solid",
                    borderColor: "#B1B1B1",
                    width: "100%",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#FFFFFF" }}>
                    <TableRow
                      sx={{ bgcolor: "#FCFCFC", boxShadow: "0 0 5px 0 #888888" }}
                    >
                      <TableCell></TableCell>
                      <TableCell sx={{ paddingLeft: "100px", color: "#434343" }}>
                        Room Name
                      </TableCell>
                      <TableCell sx={{ color: "#434343" }}>
                      Number of Child
                    </TableCell>
                    <TableCell sx={{ color: "#434343" }}>
                      Number of Educators
                    </TableCell>
                     
                    </TableRow>
                  </TableHead>
                  {allRooms.map((item, index) => {
                    return (
                      <TableBody key={index} sx={{ backgroundColor: "white" }}>
                        <TableRow
                          sx={{
                            bgcolor: "#FCFCFC",
                            boxShadow: "0 0 5px 0 #888888",
                          }}
                        >
                          <TableCell>
                            <Checkbox
                              value={item._id}
                              color="info"
                              onChange={(e)=>getDataByCheck(e,index)}
                              type="checkbox"
                            />
                          </TableCell>
                          <TableCell
                            sx={{ paddingLeft: "100px", color: "#868686" }}
                          >
                            {item.room_name}
                          </TableCell>
                          
                          <TableCell
                            sx={{ paddingLeft: "100px", color: "#868686" }}
                          >
                            {item.room_childArray.length}
                          </TableCell>
                          <TableCell
                            sx={{ paddingLeft: "100px", color: "#868686" }}
                          >
                            {item.room_teacherArray.length}
                          </TableCell>
                          
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </Grid>
            </Grid>
            <Grid container px={6}>
              <Grid
                item
                mt={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="other"
                  sx={{ border: "1px solid #FF0000", color: "red" }}
                  onClick={() => {
                    navigate("/educators");
                    
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  onClick={postData}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Sidebar>
      </>
    );
  }
  