import {
  Box,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  Popover,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import moment from "moment/moment";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import "../../style.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CompletedAssesment } from "./CompletedAssesment";
import {
  Add,
  CoPresentOutlined,
  DisabledByDefault,
  ExpandLess,
  ExpandMore,
  Folder,
  FolderTwoTone,
  HelpOutline,
  Image,
  ImageTwoTone,
  Info,
  InfoOutlined,
  OndemandVideo,
  PictureAsPdf,
  PlayCircleOutline,
  Remove,
} from "@mui/icons-material";
import { contextData } from "../../Context";
import { SnackBar } from "../SnackBar";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { useMediaQuery } from "@mui/material";
import ReactToPrint from "react-to-print";
import { Dropdown } from "react-bootstrap";
import { ProfileLogoName } from "../../components/ProfileLogoName";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import 'jspdf-autotable';

let arr = {};
let commentsArray = {};
let newarr = {};
export const ChildAssesment = () => {
  let mediaScreen = useMediaQuery("(min-width:1000px)");
  let tenant_id = localStorage.getItem("tenant_id");
  let { id } = useParams();
  let eduId = localStorage.getItem("id");
  let assesmentLearningArea = useRef("Practical");
  let navigate = useNavigate();
  let [isAssigned, setIsAssigned] = useState(true);
  let [allAssesmentsByLearning, setAllAssesmentsByLearning] = useState({});
  let [childAge, setChildAge] = useState();
  let [loader, setLoader] = useState(true);
  let [childData, setChildData] = useState({});
  let [noDataError, setNoDataError] = useState(false);
  const [activitys, setActivitys] = useState([]);
  let [isCurrentGoalActive, setIsCurrentGoalActive] = useState(false);

  let tableRef = useRef();
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const printRef = useRef();

  const [pdfData, setPdfData] = useState({});
  const [finalPdfData, setFinalPdfData] = useState({});
  let [completeAssesmentDate, setCompleteAssesmentDate] = useState("");
  useEffect((res) => {
    getChildById();
  }, []);

  async function getAllActivities() {
    let url = `${baseURL}activity/get/allActivity/${tenant_id}`;
    await axios
      .get(url)
      .then((res) => {
        setActivitys(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getAss = (data) => {
    let obj = {};
    data.forEach((res) => {
      console.log(moment(res.created_at).format("DD-MM-YYYY"));
      if (obj[res.category] !== undefined) {
        if (obj[res.category][res.assessment_section_name] !== undefined) {
          if (
            obj[res.category][res.assessment_section_name][
              res.assessment_name
            ] !== undefined
          ) {
            obj[res.category][res.assessment_section_name][
              res.assessment_name
            ].push({
              [res.assessment_activity[0]]: moment(res.created_at).format(
                "DD-MM-YYYY"
              ),
            });
          } else {
            obj[res.category][res.assessment_section_name][
              res.assessment_name
            ] = [
              {
                [res.assessment_activity[0]]: moment(res.created_at).format(
                  "DD-MM-YYYY"
                ),
              },
            ];
          }
        } else {
          obj[res.category][res.assessment_section_name] = {};
          obj[res.category][res.assessment_section_name][res.assessment_name] =
            [
              {
                [res.assessment_activity[0]]: moment(res.created_at).format(
                  "DD-MM-YYYY"
                ),
              },
            ];
        }
      } else {
        obj[res.category] = {};
        obj[res.category][res.assessment_section_name] = {};
        obj[res.category][res.assessment_section_name][res.assessment_name] = [
          {
            [res.assessment_activity[0]]: moment(res.created_at).format(
              "DD-MM-YYYY"
            ),
          },
        ];
      }
    });
    setFinalPdfData(obj);
    console.log(obj);
  };

  function getChildById() {
    setLoader(true);
    axios
      .get(Apis.CHILD.getById + id)
      .then((res) => {
        if (res.data.data[0] !== undefined) {
          // console.log(res.data.data[0].child_assessment);
          console.log(res.data.data);
          getAss(res.data.data[0]?.child_assessment);
          console.log(res.data.data[0]?.child_assessment);
          setChildData(res.data.data[0]);
          setChildAge(res.data.data[0].child_age);

          getAllAssesmentsByLearning(res.data.data[0].child_age);

          getAllActivities();
        }
      })
      .catch((err) => console.log(err));
  }

  function getAllAssesmentsByLearning(age) {
    setNoDataError(false);
    setLoader(true);

    axios
      .post(Apis.ASSESSMENT.getByAge, {
        learning: assesmentLearningArea.current,
        age,
        tenant_id,
        child_id: id,
      })
      .then((res) => {
        setLoader(false);
        // console.log(res.data.data);
        if (Object.keys(res.data.data).length == 0) {
          setNoDataError(true);
        }
        console.log(res.data.data);
        setAllAssesmentsByLearning({
          [assesmentLearningArea.current]: res.data.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function navClick(d) {
    // setNoDataError(false);

    // setLoader(true);

    setIsCurrentGoalActive(false);
    let btn = document.getElementsByClassName("nav_item");
    let i = 0;
    for (i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.borderBottom = "none";
    }
    d.target.style.color = "#FA981D";
    d.target.style.borderBottom = "1px solid #FA981D";

    if (d.target.textContent == assesmentLearningArea.current) {
      console.log("alreadyy ");
    } else {
      assesmentLearningArea.current = d.target.textContent;
      // console.log(assesmentLearningArea.current);
      getAllAssesmentsByLearning(childAge);
    }
  }

  async function goalClick(d) {
    if (assesmentLearningArea.current == "currentGoal") {
      return;
    }
    console.log("goal click click click")
    assesmentLearningArea.current = "currentGoal";
    setIsCurrentGoalActive(true);
    setNoDataError(false);
    setLoader(true);
    let btn = document.getElementsByClassName("nav_item");
    let i = 0;
    for (i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.borderBottom = "none";
    }
    d.target.style.color = "#FA981D";
    d.target.style.borderBottom = "1px solid #FA981D";
    try {
      let res = await axios.post(Apis.ASSESSMENT.getByGoals + tenant_id, {
        id,
      });
      setLoader(false);
      console.log(res.data.data);
      if (Object.keys(res.data.data).length == 0) {
        setNoDataError(true);
      } else {
        setAllAssesmentsByLearning(res.data.data);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  }

  function changenavbar() {
    if (!isAssigned) {
      assesmentLearningArea.current = "Practical";
      getAllAssesmentsByLearning(childAge);
    }
  }

  function InnerTable({ ass }) {
    return (
      <>
        {Object.keys(ass).map((resp, index) => (
          <>
            <tr className="" key={index}>
              <td className="cap border text-gray">{resp}</td>
              {/* {console.log(ass[resp])} */}
              {activitys.map((resp2, ind) => {
                let activityDate = Object.assign(...ass[resp])[
                  resp2.activity_name
                ];

                return (
                  <>
                    <td className="border">
                      {" "}
                      {activityDate !== undefined ? activityDate : "-----"}
                    </td>
                  </>
                );
              })}
              {/* {ass[resp]?.map((resp2,ind)=>
               <>
               <td className="border text-gray">{Object.keys(resp2)?.map((item3,i)=><>{resp2[item3]?resp2[item3]:'-----'}</>)}</td>
               
                </>
               )} */}
            </tr>
          </>
        ))}
      </>
    );
  }

  function TableInt({ assData }) {
    return (
      <>
        {Object.keys(assData).map((item, index) => (
          <>
            <tr key={index}>
              <td className="cap border fs-18">{item}</td>
              <td className="text-gray border">&nbsp;</td>
              <td className="text-gray border">&nbsp;</td>
              <td className="text-gray border">&nbsp;</td>
            </tr>
            <InnerTable ass={assData[item]} />
          </>
        ))}
      </>
    );
  }

  function newactivityChange(a, b, pr) {
    // console.log(b, pr)

    newarr[a._id] = {
      comment: "",
      assessment_activity: [],
      ...newarr[a._id],
      [pr]: pr == "comment" ? b : [b],
      assessment_name: a.assesment_name,
      assessment_section_name: a.section_name,
      assessment_subsection_name: a.sub_section_name,
      category: a.learning_area,
      created_at: `${new Date().getFullYear()}-${
        new Date().getMonth() + 1 <= 9 ? "0" : ""
      }${new Date().getMonth() + 1}-${
        new Date().getDate() <= 9 ? "0" : ""
      }${new Date().getDate()}`,
      invigilator_name: localStorage.getItem("roleName"),
      assessment_id: a._id,
      month:
        Math.floor(
          moment(new Date()).diff(moment(childData.child_dob), "months", true)
        ) - 14,
      time: new Date().toLocaleTimeString(),
    };
  }

  // function activityChange(a, b, pr) {
  //   if (arr[a.learning_area] !== undefined) {
  //     if (arr[a.learning_area][a.section_name] !== undefined) {
  //       if (
  //         arr[a.learning_area][a.section_name][a.assesment_name] !== undefined
  //       ) {
  //         arr[a.learning_area][a.section_name][a.assesment_name] = {
  //           ...arr[a.learning_area][a.section_name][a.assesment_name],
  //           [pr]: [b],
  //           subsection: a.sub_section_name,
  //         };
  //       } else {
  //         arr[a.learning_area][a.section_name][a.assesment_name] = {};
  //         arr[a.learning_area][a.section_name][a.assesment_name] = {
  //           ...arr[a.learning_area][a.section_name][a.assesment_name],
  //           [pr]: [b],
  //           subsection: a.sub_section_name,
  //         };
  //       }
  //     } else {
  //       arr[a.learning_area][a.section_name] = {};
  //       arr[a.learning_area][a.section_name][a.assesment_name] = {
  //         ...arr[a.learning_area][a.section_name][a.assesment_name],

  //         [pr]: [b],
  //         subsection: a.sub_section_name,
  //       };
  //     }
  //   } else {
  //     arr[a.learning_area] = {};
  //     arr[a.learning_area][a.section_name] = {};
  //     arr[a.learning_area][a.section_name][a.assesment_name] = {
  //       ...arr[a.learning_area][a.section_name][a.assesment_name],

  //       [pr]: [b],
  //       subsection: a.sub_section_name,
  //     };
  //   }
  //   console.log(arr);
  // }

  function submitAssesment() {
    let arr1 = Object.keys(newarr);
    let finalArr = [];

    arr1.forEach((fullres) => {
      finalArr.push(newarr[fullres]);
    });

    let data = {
      ids: [id],
      child_assessment: finalArr,
    };
    console.log(data);

    if (data.child_assessment.length !== 0) {
      setLoader(true);
      let url = `${baseURL}child/addAssessmentIntoChild
      `;
      axios
        .put(url, data)
        .then(async(res) => {
          // console.log(res);
          setLoader(false);
          arr = {};
          commentsArray = {};
          newarr = {}
          // localStorage.getItem("role") !== "Admin"
          //   ? navigate(`/allchildofroom/${childData.child_room[0]}`)
          //   : navigate("/childlisting");
          if (assesmentLearningArea.current == "currentGoal") {
            try {
              let res = await axios.post(Apis.ASSESSMENT.getByGoals + tenant_id, {
                id,
              });
              setLoader(false);
              console.log(res.data.data);
              if (Object.keys(res.data.data).length == 0) {
                setNoDataError(true);
              } else {
                setAllAssesmentsByLearning(res.data.data);
              }
            } catch (err) {
              console.log(err);
              setLoader(false);
            }
          }
          else {


            getAllAssesmentsByLearning(childAge);
          }
        })

        .catch((err) => console.log(err));
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Please fill configuration",
        type: "warning",
      });
    }
  }

  function DropDownData({ assData, res }) {
    let [dropDown, setDropDown] = useState(false);

    function ImageComponant({ eData }) {
      let [imageDialog, setImageDialog] = useState(false);

      function handleClose() {
        setImageDialog(false);
      }
      const [anchorEl, setAnchorEl] = useState(null);
      const [hoverState, setHoverState] = useState(false);

      const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose2 = () => {
        setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? "simple-popover" : undefined;

      function ShowFullImage({ res2 }) {
        let [showFullImageState, setShowFullImageState] = useState(false);
        function handleClose2() {
          setShowFullImageState(false);
        }
        return (
          <Grid item md={3}>
            <Box
              sx={{
                border: "1px solid grey",
                width: "200px",
                height: "200px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {res2.videoUrl !== undefined ? (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <video className="w-100 h-100">
                    <source src={res2.videoUrl} type="video/ogg" />
                  </video>
                  <PlayCircleOutline
                    sx={{
                      fontSize: "70px",
                      position: "absolute",
                      color: "white",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowFullImageState(true)}
                  />
                </Box>
              ) : (
                <>
                  {res2.imageUrl !== undefined ? (
                    <Box>
                      <img
                        onClick={() => setShowFullImageState(true)}
                        src={res2.imageUrl}
                        className="w-100"
                        alt="image failed to load"
                        style={{
                          width: "100%",
                          height: "200px",
                          border: "1px solid grey",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ) : (
                    <Box>
                      {res2.embedUrl !== undefined ? (
                        <Button
                          sx={{ textTransform: "capitalize" }}
                          onClick={() => setShowFullImageState(true)}
                          className="btn_primary"
                        >
                          Open Link
                        </Button>
                      ) : (
                        //  <iframe
                        //  width="100"
                        //  height="100"
                        //  src={res2.embedUrl}
                        // ></iframe>
                        <Button>
                          {/* <a href={res2.pdfUrl} target="_blank"> */}
                          <PictureAsPdf
                            onClick={() => setShowFullImageState(true)}
                            sx={{
                              fontSize: "70px",

                              cursor: "pointer",
                            }}
                          />
                          {/* </a> */}
                        </Button>
                      )}
                    </Box>
                  )}
                </>
              )}
            </Box>

            <Dialog
              open={showFullImageState}
              onClose={handleClose2}
              fullWidth={true}
              maxWidth={"lg"}
            >
              <Grid sx={{ mb: 2 }} align="right">
                <DisabledByDefault
                  sx={{ color: "red", cursor: "pointer", fontSize: "25px" }}
                  onClick={() => handleClose2()}
                />
              </Grid>
              <Grid sx={{ height: "90vh" }}>
                {res2.videoUrl !== undefined ? (
                  <video
                    className="w-100"
                    width="320"
                    height="90%"
                    controls
                    autoplay
                  >
                    <source src={res2.videoUrl} type="video/ogg" />
                  </video>
                ) : (
                  <>
                    {res2.imageUrl !== undefined ? (
                      <img
                        src={res2.imageUrl}
                        className="w-100"
                        alt="image failed to load"
                        style={{ height: "100%" }}
                      />
                    ) : (
                      <>
                        {res2.embedUrl !== undefined ? (
                          <iframe
                            width="100%"
                            height="100%"
                            src={res2.embedUrl}
                          ></iframe>
                        ) : (
                          <embed src={res2.pdfUrl} width="100%" height="100%" />
                        )}
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Dialog>
          </Grid>
        );
      }

      return (
        <>
          <Typography
            sx={{
              color: "#1799CE",
              cursor: "pointer",

              textTransform: "capitalize",
            }}
          >
            <div className="d-flex justify-content-between">
              <div className="d-flex w-100">
                <div className="">{eData.assesment_name}</div>
                <div className="flex-fill" style={{ position: "relative" }}>
                  {eData.config_info?.trim() !== "" ? (
                    <div className="ms-2">
                      <InfoOutlined
                        onMouseEnter={() => setHoverState(true)}
                        onMouseLeave={() => setHoverState(false)}
                      />

                      {hoverState ? (
                        <div
                          className="p-2 text-center"
                          style={{
                            color: "black",
                            position: "absolute",
                            backgroundColor: "white",
                            boxShadow: "0px 0px 20px grey",
                            width: mediaScreen ? "70%" : "100%",
                          }}
                        >
                          {eData.config_info}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>

              <div title="Assessments Files">
                <Folder
                  onClick={() => {
                    // showImages();
                    setImageDialog(true);
                  }}
                />
              </div>
            </div>
          </Typography>
          <Typography color={"grey"}>{eData.sub_section_name}</Typography>
          {eData.activity_reference ? (
            <Dialog
              open={imageDialog}
              onClose={handleClose}
              fullWidth={true}
              maxWidth={"md"}
            >
              <Grid sx={{ mb: 2 }} align="right">
                <DisabledByDefault
                  sx={{ color: "red", cursor: "pointer", fontSize: "25px" }}
                  onClick={() => handleClose()}
                />
              </Grid>
              <Container sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  {eData.file_url.map((res2) => {
                    return <ShowFullImage res2={res2} />;
                  })}
                </Grid>
              </Container>
            </Dialog>
          ) : null}
        </>
      );
    }

    return (
      <>
        <Grid
          item
          md={!dropDown ? 6 : 12}
          onClick={() => setDropDown(!dropDown)}
          className="pointer"
          sx={{ textTransform: "capitalize" }}
        >
          <b>{res}</b>
        </Grid>

        <Grid item md={6} align="right">
          {!dropDown ? (
            <Typography
              onClick={() => setDropDown(!dropDown)}
              sx={{ cursor: "pointer" }}
            >
              <Add />
            </Typography>
          ) : null}
        </Grid>
        {dropDown ? (
          <Container>
            <Container sx={{ mt: 2 }}>
              {assData.map((e, ind) => {
                return (
                  <>
                    <ImageComponant eData={e} />

                    <Grid md={3}></Grid>
                    <Grid container py={2} rowSpacing={3}>
                      {/* all activities section  */}
                      {e.assesmentActivity.map((res, index) => {
                        // console.log(e.assesmentActivity)
                        // console.log(e)
                        if (e.oldAssessmentDone !== undefined) {
                          let rrr = Math.max(
                            ...e.oldAssessmentDone.map((res) =>
                              e.assesmentActivity.indexOf(res)
                            )
                          );
                          console.log(rrr);
                        }

                        return (
                          <Grid item md={3}>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                // disabled={e.oldAssessmentDone?.includes(res)}
                                disabled={
                                  e.oldAssessmentDone !== undefined
                                    ? Math.max(
                                        ...e.oldAssessmentDone?.map((res) =>
                                          e.assesmentActivity.indexOf(res)
                                        )
                                      ) >= index
                                    : false
                                }
                                defaultChecked={
                                  newarr?.[e._id]?.assessment_activity?.[0] ==
                                  res
                                }
                                // defaultChecked={
                                //   arr[e.learning_area] !== undefined
                                //     ? arr[e.learning_area][e.section_name] !==
                                //       undefined
                                //       ? arr[e.learning_area][e.section_name][
                                //         e.assesment_name
                                //       ] !== undefined
                                //         ? arr[e.learning_area][e.section_name][
                                //           e.assesment_name
                                //         ]["performance"] !== undefined
                                //           ? arr[e.learning_area][
                                //           e.section_name
                                //           ][e.assesment_name][
                                //           "performance"
                                //           ][0] == res
                                //           : null
                                //         : null
                                //       : null
                                //     : null
                                // }
                                type="radio"
                                name={`${e.section_name}${e.assesment_name}${e._id}`}
                                onChange={(event) =>
                                  // activityChange(e, res, "performance")
                                  newactivityChange(
                                    e,
                                    res,
                                    "assessment_activity"
                                  )
                                }
                              />
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  mx: 1,
                                  textTransform: "capitalize",
                                  color:
                                    e.oldAssessmentDone !== undefined
                                      ? Math.max(
                                          ...e.oldAssessmentDone.map((res) =>
                                            e.assesmentActivity?.indexOf(res)
                                          )
                                        ) >= index
                                        ? "#E2E2E2"
                                        : "#212136"
                                      : "black",
                                }}
                              >
                                {res}
                              </Typography>
                            </Typography>
                          </Grid>
                        );
                      })}

                      <Grid item md={3}>
                        {e.enable_educator_to_comment ? (
                          <textarea
                            rows={1}
                            onChange={(event) => {
                              console.log(e._id);
                              // activityChange(e, event.target.value, "comment")
                              newactivityChange(
                                e,
                                event.target.value,
                                "comment"
                              );
                            }}
                            style={{
                              width: "100%",
                              padding: "10px 20px",
                              marginTop: "-10px",
                            }}
                            placeholder="Add a comment.."
                            defaultValue={
                              arr[e.learning_area] !== undefined
                                ? arr[e.learning_area][e.section_name] !==
                                  undefined
                                  ? arr[e.learning_area][e.section_name][
                                      e.assesment_name
                                    ] !== undefined
                                    ? arr[e.learning_area][e.section_name][
                                        e.assesment_name
                                      ]["comment"] !== undefined
                                      ? arr[e.learning_area][e.section_name][
                                          e.assesment_name
                                        ]["comment"][0]
                                      : null
                                    : null
                                  : null
                                : null
                            }
                            // defaultValue={arr[e.learning_area]?.[e.section_name]?.[e.assesment_name]?.["comment"][0]}
                          ></textarea>
                        ) : null}
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            </Container>
            <Typography
              onClick={() => setDropDown(false)}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Remove />
            </Typography>
          </Container>
        ) : null}
      </>
    );
  }
  function goToTermGoal() {
    localStorage.getItem("role") == "Room"
      ? navigate(`/termsGoals/${id}`)
      : navigate(`/termsGoalsByAdmin/${id}`);
  }

  function LearningAreaDropdown({ learningAreaData }) {
    const [learningState, setLearningState] = useState(false);
    return (
      <>
        {isCurrentGoalActive ? (
          <div>
            <div
              onClick={() => setLearningState(!learningState)}
              className="pointer mt-3 d-flex justify-content-between bg-white p-3"
            >
              <div>{learningAreaData}</div>
              <div>{!learningState ? <ExpandLess /> : <ExpandMore />}</div>
            </div>
            {learningState ? (
              <Container
                sx={{
                  bgcolor: "white",
                  // boxShadow: "0 0 5px 0 #888888",
                }}
              >
                {Object.keys(allAssesmentsByLearning[learningAreaData]).map(
                  (res, index) => {
                    return (
                      <Grid
                        key={index}
                        container
                        sx={{ py: 2, borderBottom: "1px solid grey" }}
                      >
                        <DropDownData
                          res={res}
                          assData={
                            allAssesmentsByLearning[learningAreaData][res]
                          }
                        />
                      </Grid>
                    );
                  }
                )}
              </Container>
            ) : null}
          </div>
        ) : (
          <Container
            sx={{
              mt: 2,
              bgcolor: "white",
              boxShadow: "0 0 5px 0 #888888",
            }}
          >
            {Object.keys(allAssesmentsByLearning[learningAreaData]).map(
              (res, index) => {
                return (
                  <Grid
                    key={index}
                    container
                    sx={{ py: 2, borderBottom: "1px solid grey" }}
                  >
                    <DropDownData
                      res={res}
                      assData={allAssesmentsByLearning[learningAreaData][res]}
                    />
                  </Grid>
                );
              }
            )}
          </Container>
        )}
      </>
    );
  }

  return (
    <Sidebar>
      <Grid px={3} pb={2}>
        <ArrowBackIos
          sx={{ color: "#3FAEED", cursor: "pointer" }}
          onClick={() => history.back()}
        />
      </Grid>

      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
      <Container className="jj" id="okok">
        <div className="d-flex align-items-center justify-content-between  ">
          <div className="d-flex align-items-center gap-3">
            <div>
              {" "}
              <ProfileLogoName
                firstName={childData.child_name}
                lastName={childData.child_lastname}
              />
            </div>
            <div>{childData.child_name}</div>
          </div>
          <div>
            {/* <Button className="btn_primary" onClick={goToTermGoal}>Term And Goal</Button> */}
          </div>
        </div>

        <Grid container sx={{ mt: 2 }} className="grid_container">
          <Grid
            item
            // md={4}
            sm={3}
            sx={{ cursor: "pointer", color: "grey" }}
            onClick={() => {
              setIsAssigned(true);
              changenavbar();
            }}
          >
            <span
              style={{
                color: isAssigned ? "skyblue" : "grey",
                borderBottom: isAssigned ? "1px solid skyblue" : "none",
              }}
            >
              Assessment
            </span>
          </Grid>
          <Grid
            item
            sx={{ cursor: "pointer", color: "grey" }}
            // md={4}
            sm={3}
            onClick={() => {
              setIsAssigned(false);
            }}
          >
            <span
              style={{
                color: isAssigned ? "grey" : "skyblue",
                borderBottom: isAssigned ? "none" : "1px solid skyblue",
              }}
            >
              History
            </span>
          </Grid>
          <Grid
            item
            sx={{ cursor: "pointer", color: "grey" }}
            // md={4}
            sm={6}
            className="d-flex justify-content-end"
            onClick={() => setIsAssigned(false)}
          >
            {!isAssigned ? (
              <>
                <Button className="btn_primary me-2">
                  <input
                    style={{ border: "none" }}
                    onChange={(e) => setCompleteAssesmentDate(e.target.value)}
                    className="btn_primary"
                    type="date"
                  />
                </Button>
                {/* <Button className="btn_primary" onClick={pdfGenrator}>Download Report</Button> */}
                <ReactToPrint
                  trigger={() => (
                    <Button className="btn_primary">Download Report</Button>
                  )}
                  content={() => printRef.current}
                />
                <div className="d-none">
                  <div
                    id="tableReport"
                    style={{ width: "100%" }}
                    ref={printRef}
                    className=""
                  >
                    <div className="py-3 ps-2 ">
                      <div className="text-center py-1 fs-5">
                        {localStorage.getItem("childcare_center")}
                      </div>
                      <span className="ps-3">Child Name : </span>
                      <span className=" ps-3 cap">{childData.child_name}</span>
                    </div>
                    <table
                      className="table table-bordered bg-white"
                      style={{ width: "100%" }}
                    >
                      <thead></thead>
                      {Object.keys(finalPdfData)?.map((res, index) => (
                        <>
                          <tbody key={index}>
                            <tr>
                              <td
                                className="ps-5 fs-18"
                                style={{ color: "#F8991C" }}
                              >
                                {res}
                              </td>
                              {activitys.map((act, inde) => (
                                <th className="fs-18" key={inde}>
                                  {act?.activity_name}
                                </th>
                              ))}
                            </tr>
                          </tbody>
                          <TableInt assData={finalPdfData[res]} />
                        </>
                      ))}
                    </table>
                  </div>
                </div>
              </>
            ) : (
              <Button className="btn_primary" onClick={goToTermGoal}>
                Term And Goal
              </Button>
            )}
          </Grid>
        </Grid>
        {isAssigned ? (
          <>
            <Box>
              <Grid
                container
                sx={{
                  bgcolor: "#FCFCFC",
                  p: 2,
                  mt: 1,
                  boxShadow: "0 0 5px 0 #888888",
                }}
                className="assessment_box"
              >
                <Grid item md={2}>
                  <span className="nav_item active" onClick={navClick}>
                    Practical
                  </span>
                </Grid>
                <Grid item md={2}>
                  <span className="nav_item" onClick={navClick}>
                    Sensorial
                  </span>
                </Grid>
                <Grid item md={2}>
                  <span className="nav_item" onClick={navClick}>
                    Language
                  </span>
                </Grid>
                <Grid item md={2}>
                  <span className="nav_item" onClick={navClick}>
                    Maths
                  </span>
                </Grid>
                <Grid item md={2}>
                  <span className="nav_item" onClick={navClick} variant="body2">
                    Culture
                  </span>
                </Grid>
                <Grid item md={2}>
                  <span
                    className="nav_item"
                    onClick={goalClick}
                    variant="body2"
                  >
                    Current Goals
                  </span>
                </Grid>
              </Grid>

              {loader ? (
                <Box my={4}>
                  <LinearProgress />
                </Box>
              ) : (
                <Box>
                  {noDataError ? (
                    <Box mt={3}>No Assessments Available...</Box>
                  ) : (
                    <div>
                      {false ? (
                        ""
                      ) : (
                        <>
                          {Object.keys(allAssesmentsByLearning).map((head) => {
                            console.log(head);
                            return (
                              <>
                                <LearningAreaDropdown learningAreaData={head} />
                                {/* <Container
                                  sx={{
                                    mt: 2,
                                    border: '2px solid red',
                                    bgcolor: "white",
                                    boxShadow: "0 0 5px 0 #888888",
                                  }}
                                >
                                  {Object.keys(allAssesmentsByLearning[head]).map((res, index) => {
                                    return (
                                      <Grid
                                        key={index}
                                        container
                                        sx={{ py: 2, borderBottom: "1px solid grey" }}
                                      >
                                        <DropDownData
                                          res={res}
                                          assData={allAssesmentsByLearning[head][res]}
                                        />
                                      </Grid>

                                    );
                                  })}
                                </Container> */}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}
                </Box>
              )}
              <Container
                sx={{ display: "flex", justifyContent: "flex-end", mt: 6 }}
              >
                <Button
                  sx={{ mx: 2, background: "#ffffff" }}
                  className="cancel_btn"
                  onClick={() =>
                    localStorage.getItem("role") !== "Admin"
                      ? navigate(`/assessment/${eduId}`)
                      : navigate("/childlisting")
                  }
                >
                  Cancel
                </Button>
                <Button className="btn_primary" onClick={submitAssesment}>
                  Submit
                </Button>
              </Container>
            </Box>
          </>
        ) : (
          <CompletedAssesment
            ref={tableRef}
            id={id}
            setPdfData={setPdfData}
            // getAss = {getAss}
            completeAssesmentDate={completeAssesmentDate}
          />
        )}
      </Container>
    </Sidebar>
  );
};
