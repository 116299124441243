import { ArrowBackIos } from "@mui/icons-material";
import TroubleshootOutlined from "@mui/icons-material/TroubleshootOutlined";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../baseURL";
import { SnackBar } from "../SnackBar";

export const ForgotPassword = () => {
  const [userDetails, setUserDetails] = useState({ email: "", role: "" });
  let [loader, setLoader] = useState(false);
  const [dialog, setDialog] = useState(false);
  let navigate = useNavigate();
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  let matches = useMediaQuery("(min-width:800px)");

  function formSubmit(e) {
    e.preventDefault();
    setLoader(TroubleshootOutlined);
    console.log(userDetails);
    let url = `${baseURL}forgotPassword`;

    axios
      .post(url, {
        email: userDetails.email,
        role: userDetails.role,
      })
      .then((res) => {
        console.log(res);
        setLoader(false);
        setDialog(true);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: err.response.data.errormessage,
          type: "error",
        });
      });
  }
  return (
    <>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={dialog} maxWidth={"sm"} fullWidth={true}>
        <Box p={4} sx={{ border: "4px solid grey", m: 1 }}>
          <Typography
            variant="h5"
            align="center"
            sx={{ mt: 4, color: "green" }}
          >
            Go to email and reset your password.
          </Typography>
          <Box mt={4} align="center">
            <Button className="btn_primary" onClick={() => navigate("/")}>
              Ok
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Box sx={{ height: "100vh", bgcolor: "#EFF6FF" }}>
        <Box p={4}>
          <Link to="/">
            <ArrowBackIos />
          </Link>
        </Box>
        <Box
          sx={{ width: "100%", height: "80vh" }}
          className="d-sm-flex flex-column justify-content-center align-items-center"
        >
          <Typography variant="h4" my={3} align="center">
            Forgot Password
          </Typography>

          <form onSubmit={formSubmit}>
            <Box
              className="px-5 mx-2"
              sx={{
                width: matches ? "740px" : "100%",
                boxShadow: "3px 3px 3px grey",
                p: 4,
                pb: 10,
                bgcolor: "white",
              }}
            >
              <label>Email :</label>
              <br />
              <TextField
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
                value={userDetails.email}
                type="email"
                required
                fullWidth
                sx={{ mt: 2 }}
              />

              <label className="mt-2">Role :</label>
              <Box sx={{ mt: 2 }}>
                <input
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, role: e.target.value })
                  }
                  value="Parent"
                  required
                  type="radio"
                  className="me-2"
                  name="role"
                />
                <label className="me-4">Parent</label>
                <input
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, role: e.target.value })
                  }
                  value="Admin"
                  required
                  type="radio"
                  className="me-2"
                  name="role"
                />
                {/* <label>Educator</label>
                <input
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, role: e.target.value })
                  }
                  value="Admin"
                  required
                  type="radio"
                  className="mx-2"
                  name="role"
                /> */}
                <label>Admin</label>
              </Box>
              <Box align="center" mt={4}>
                <Button type="submit" className="btn_primary">
                  Submit
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};
