import { Button, LinearProgress } from "@mui/material"
import { ShowPastTermGoalCard } from "./ShowPastTermGoalCard"
import { useEffect, useRef, useState } from "react"
import { Apis } from "../../auth/GlobalUrl"
import { useParams } from "react-router-dom"
import axios from "axios"
import React from "react"


import { useReactToPrint } from 'react-to-print';
import { PrintTermAndGoal } from "./PrintTermAndGoal"

export const PastTermGoal = () => {

    const [loader, setLoader] = useState(true)
    const [pastsGoalData, setPastGoalData] = useState([])
    const [showIndex, setShowIndex] = useState(null)
    const [pdfData, setPdfData] = useState({})

    let { id } = useParams()

    const getPastTermGoalData = async () => {
        try {
            let res = await axios.post(Apis.EDUCATOR.getCurrentTermGoal + id, { data: false })
            console.log(res.data.data)
            setPastGoalData(res.data.data)
            console.log(res.data.data[0])
            setPdfData(res.data.data[0])
            setLoader(false)
        }
        catch (err) {

            console.log(err)
        }
    }

    useEffect(() => {
        getPastTermGoalData()
    }, [])





    return (
        <div className="mx-5 mt-5">

            {
                !loader ?
                    <>
                        {pastsGoalData.map((res, key) => {
                            return (
                                <div key={key}>
                                    <div className="d-flex bg-white mt-4 py-2 px-4 align-items-center justify-content-between border rounded">
                                        <div>{new Date(res.start_date).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' })} - {new Date(res.end_date).toLocaleString('default', { day: 'numeric', year: 'numeric', month: 'long' })}</div>
                                        <div className="d-flex">
                                            <div>
                                                <Button onClick={() => showIndex == key ? setShowIndex(null) : setShowIndex(key)} className='btn_primary me-3'>{showIndex == key ? 'Hide Term Goal' : 'View Term Goal'}</Button>
                                            </div>
                                            <div>
                                                <PrintTermAndGoal res={res} />
                                            </div>
                                        </div>
                                    </div>
                                    {showIndex == key ?
                                        <ShowPastTermGoalCard data={res} /> : null}
                                </div>
                            )

                        })}
                        {!loader && pastsGoalData.length == 0 ? <div className="mt-4 d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
                            <div>
                                <div style={{ width: '200px', height: '200px' }}>
                                    <img src='/images/noData.png' className="w-100 h-100" />
                                </div>
                                <div className="mt-2">No Term And Goal Available...</div>

                            </div>
                        </div> : null}
                    </>
                    :
                    <div><LinearProgress sx={{ color: 'red' }} /></div>
            }

        </div >
    )
}