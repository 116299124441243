import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  Typography,
  Checkbox,
  ListItemText,
  checkboxClasses,
  OutlinedInput,
} from "@mui/material";
import { Sidebar } from "../../components/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { ArrowBackIos } from "@mui/icons-material";

export default function EditChild() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [def, setdef] = useState(false);
  const [childData, setchildData] = useState({});
  let [allGoals, setAllGoals] = useState([])
  let tenant_id = localStorage.getItem('tenant_id')

  //for get child data
  const getChildData = () => {
    axios
      .get(Apis.CHILD.getById + id)
      .then((res) => {
        let data = res.data.data;
        if (data.length !== 0) {
          setdef(true);
          let [objData] = data;
          console.log(objData);
          setchildData(objData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // onChange function
  const getData = ({ value, name }) => {
    setchildData({ ...childData, [name]: value });
  };

  //for update to api function
  const editChildData = (e) => {
    e.preventDefault();
    axios
      .put(Apis.CHILD.edit + id, childData)
      .then((res) => {
        console.log(res);
        navigate("/childlisting");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function getAllGoals() {
    try {
      let res = await axios.get(Apis.GOAL.getAll + tenant_id)
      console.log(res.data.data)
      setAllGoals(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getChildData();
    getAllGoals()

  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <Sidebar>
        <Grid px={3}>
          <Link to="/childlisting">
            <ArrowBackIos />
          </Link>
        </Grid>
        <Grid container px={4} py={2} style={{ background: "#EFF6FF" }}>
          {def ? (
            <form onSubmit={editChildData} className="container-fluid">
              <Grid container className="d-flex justify-content-center">
                <Grid item md={11} sm={11} xs={11} mb={2}>
                  {" "}
                  <Typography align="center" variant="h5">
                    Edit Child
                  </Typography>{" "}
                </Grid>
                <div className="row m-0 bg-white p-md-5 p-3">


                  <div className="col-md-6 col-12 p-2">
                    <Typography>Child Name</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      required
                      name="child_name"
                      value={childData.child_name}
                      onChange={(e) => getData(e.target)}
                      placeholder="Enter Child Name"
                      className="w-100"
                      sx={{ background: "#ffffff" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-2">


                    <Typography>Child Last Name</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      required
                      name="child_lastname"
                      value={childData.child_lastname}
                      onChange={(e) => getData(e.target)}
                      placeholder="Child Last Name"
                      className="w-100"
                      sx={{ background: "#ffffff" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-2">



                    <Typography>Gender</Typography>

                    <FormControl fullWidth autoComplete="off">
                      <Select
                        fullWidth
                        name="child_gender"
                        onChange={(e) => getData(e.target)}
                        sx={{ background: "#ffffff" }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={childData.child_gender}
                        required
                        label="11"
                      >
                        <MenuItem value="male">Male</MenuItem>
                        <MenuItem value="female">Female</MenuItem>
                        <MenuItem value="other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6 col-12 p-2">

                    <Typography>Childcare Center Name</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      required
                      value={localStorage.getItem("childcare_center")}
                      name="child_school"
                      onChange={(e) => getData(e.target)}
                      placeholder="Enter Center Name"
                      className="w-100"
                      sx={{ background: "#ffffff" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-2">

                    <Typography>Configuration Age</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      required
                      name="child_age"
                      value={childData.child_age}
                      onChange={(e) => getData(e.target)}
                      type="number"
                      placeholder="Age"
                      className="w-100"
                      sx={{ background: "#ffffff" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-2">

                    <Typography>DOB</Typography>

                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      required
                      name="child_dob"
                      value={childData.child_dob}
                      type="date"
                      onChange={(e) => getData(e.target)}
                      placeholder="DD/MM/YYYY"
                      className="w-100"
                      sx={{ background: "#ffffff" }}
                    />
                  </div>
                  <div className="col-md-6 col-12 p-2">



                    <Typography>Goals</Typography>

                    <FormControl fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={childData.goals !== undefined ? childData.goals : []}
                        name='goals'
                        onChange={(e) => getData(e.target)}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => childData.goals?.join(',')}
                        MenuProps={MenuProps}
                      >
                        {allGoals.sort((a, b) => a.goal_name.localeCompare(b.goal_name)).map(res => (
                          <MenuItem key={res.goal_name} value={res.goal_name}>
                            <Checkbox
                              sx={{
                                [`&, &.${checkboxClasses.checked}`]: {
                                  color: 'blue',
                                },
                              }}
                              checked={childData.goals?.indexOf(res.goal_name) > -1} />
                            <ListItemText primary={res.goal_name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>





                </div>



              </Grid>

              <Grid container px={6}>
                <Grid
                  item
                  mt={6}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: "flex", justifyContent: "end" }}
                >
                  <Button
                    variant="other"
                    sx={{ border: "1px solid #FF0000", color: "red" }}
                    type="reset"
                    onClick={() => navigate("/childlisting")}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="other"
                    sx={{
                      background: "#1799CD",
                      color: "#ffffff",
                      border: "1px solid #1799CD",
                      marginLeft: "10px",
                    }}
                    type="submit"
                  >
                    Update Child
                  </Button>
                </Grid>
              </Grid>
            </form>
          ) : null}
        </Grid>
      </Sidebar>
    </>
  );
}
