import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { baseURL } from "../../auth/GlobalUrl";
import { contextData } from "../../Router";
import "../../style.css";
import { SnackBar } from "../SnackBar";
export const SubRecord = ({
  stateData,
  setRecordPage,
  checkedChilds,
  dateAndTime,
  setCheckedChilds,
  getAllChild,
  showSnackbarMessage,
}) => {
  const navigate = useNavigate();

  const [anchorEl1, setAnchorEl1] = React.useState(null);
  let [newSubRecord, setNewSubRecord] = useState("");
  let [newSubActivity, setNewSubActivity] = useState("");
  let [subRecordData, setSubRecordData] = useState({});
  let [loader, setLoader] = useState(false);
  let [selectedRecord, setSelectedRecord] = useState({
    record_name: stateData.record_name,
  });
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  let contextState = useContext(contextData);

  useEffect(() => {
    console.log(stateData);
  }, []);

  function selectSubrecordName(e) {
    let all = document.querySelectorAll(".subRecord");
    all.forEach((res) => {
      res.style.color = "#456C0F";
      res.style.backgroundColor = "#E7FFC5";
    });
    e.target.style.color = "white";
    e.target.style.backgroundColor = "#456C0F";
    setSelectedRecord({
      ...selectedRecord,
      subrecord_name: e.target.textContent,
    });
  }
  function selectAmountName(e) {
    let all = document.querySelectorAll(".subActivity");
    all.forEach((res) => {
      res.style.color = "#991B1B";
      res.style.backgroundColor = "#FFF1F6";
    });
    e.target.style.color = "white";
    e.target.style.backgroundColor = "#991B1B";
    setSelectedRecord({ ...selectedRecord, amount: e.target.textContent });
  }
  function showErrorMessage(msg) {
    ShowSnackbar({
      show: true,
      vertical: "top",
      horizontal: "right",
      msg,
      type: "warning",
    });
  }
  function submitRecord() {
    console.log("clicked sub");
    let oo = {
      arr: checkedChilds,
      obj: { ...selectedRecord, ...dateAndTime.content },
    };
    console.log(oo);
    if (oo.arr.length == 0) {
      showErrorMessage("Select child");
      return;
    }
    if (oo.obj.subrecord_name == undefined) {
      showErrorMessage("Select Record");
      return;
    }

    setRecordPage(true);

    let url = `${baseURL}child/addRecordsIntoChild`;
    console.log({
      ids: checkedChilds,
      record: {
        ...selectedRecord,
        created_at: new Date(),
        ...dateAndTime.content,
      },
    });

    axios
      .put(url, {
        ids: checkedChilds,
        record: {
          ...selectedRecord,
          created_at: new Date(),
          ...dateAndTime.content,
          educator_name: localStorage.getItem("educatorName"),
        },
      })
      .then((res) => {
        console.log(res);

        setCheckedChilds([]);
        showSnackbarMessage("Record added successfully");
        getAllChild();

        let dat = new Date();
        let yr = dat.getFullYear();
        let mnt = dat.getMonth();
        let day = dat.getDate();
        let fullDate = `${yr}-${mnt + 1 < 10 ? "0" : ""}${mnt + 1}-${
          day < 10 ? "0" : ""
        }${day}`;
        dateAndTime.set({
          created_at: fullDate,
          time: `${
            new Date().getHours() < 10 ? "0" : ""
          }${new Date().getHours()}:${
            new Date().getMinutes() < 10 ? "0" : ""
          }${new Date().getMinutes()}`,
        });
      })
      .catch((err) => console.log(err));
    // navigate('/addRecord')
  }

  return (
    <>
      {!loader ? (
        <>
          <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

          <Box sx={{ width: "100%" }}>
            <Container sx={{ mt: 2 }} align="center">
              <Typography className="recordHeading">
                {stateData.record_name}
              </Typography>
            </Container>
            {stateData.sub_record.length !== 0 ? (
              <Grid container>
                {stateData.sub_record.map((res) => {
                  return (
                    <Grid
                      item
                      className="subRecord"
                      onClick={(e) => selectSubrecordName(e)}
                    >
                      {res}
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Box sx={{m:3}}>No records available....</Box>
            )}
            <Grid container>
              {stateData.record_activity.map((res) => {
                return (
                  <Grid
                    item
                    className="subActivity"
                    onClick={(e) => selectAmountName(e)}
                  >
                    {res}
                  </Grid>
                );
              })}
            </Grid>

            <Container sx={{ width: "100%", mb: 2 }}>
              <TextField
                placeholder="Enter Amount"
                className="w-100"
                onChange={(e) =>
                  setSelectedRecord({
                    ...selectedRecord,
                    comment_amount: e.target.value,
                  })
                }
              />
            </Container>

            <Container sx={{ width: "100%" }}>
              <textarea
                placeholder="Comment..."
                className="w-100 p-2"
                onChange={(e) =>
                  setSelectedRecord({
                    ...selectedRecord,
                    comment: e.target.value,
                  })
                }
                rows="5"
              ></textarea>
            </Container>

            <Container sx={{ mt: 6, marginBottom: "10px" }} align="right">
              <Button
                className="cancel_btn"
                onClick={() => setRecordPage(true)}
              >
                Back
              </Button>
              <Button
                className="btn_primary"
                sx={{ ml: 2 }}
                onClick={() => submitRecord()}
              >
                Save
              </Button>
            </Container>
          </Box>
        </>
      ) : (
        <Box sx={{ bgcolor: "white", width: "100%", my: 4 }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};
