import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { childCareCentersApis, clearCenters } from "../Redux-toolkit/Slices/ChildCareCenters";
import { Box, Button, Dialog, Modal, Typography } from "@mui/material";
import { ParentLogoApis } from "../Redux-toolkit/Slices/ParentLogoSlices";

export const CheckRoleAdmin = () => {
  const login = useRef(true);
  const [model, setModel] = useState(false)

  const role = localStorage.getItem("role");
  const tenant_id = localStorage.getItem("tenant_id");
  const childcare_center = localStorage.getItem("childcare_center");
  const nav_name = localStorage.getItem("nav_name");
  let dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    dispatch(childCareCentersApis()).then((res) => {
      // this  action is use to fetch latest logo of curent admin
      dispatch(ParentLogoApis())
      if (res.payload[0]?.center_data?.length == 0) {
        setModel(true)

      }
    })
    return () => {
      dispatch(clearCenters())
    }
  }, [])
  function noAccess() {
    localStorage.clear()
    navigate('/')
  }

  if (role == 'Admin' && nav_name !== null) {
    login.current = true;
  }
  else {
    login.current = false;
  }

  return (
    <>
      <Dialog
        open={model}

      >
        <Box className="p-3">

          <Typography className="text-danger fs-4">You don't have permission to access !</Typography>
          <div className="d-flex justify-content-end mt-3">
            <Button className="btn_primary" onClick={noAccess}>Ok</Button>
          </div>
        </Box>
      </Dialog>
      {
        login.current ? <Outlet /> : <Navigate to="/" replace />
      }
    </>
  )
};
