import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  CircularProgress,
  Dialog,
  Backdrop,
} from "@mui/material";
import React, { useContext } from "react";
import { Sidebar } from "../../components/Sidebar";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import storage from "../../firebase/firebase";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";
import styles from "./educater.module.css";

const EditEducator = () => {
  var navigate = useNavigate();
  const { id } = useParams();
  const [showPass, setShowPass] = useState(false);

  const [teacher, setTeacher] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [qualification, setQualification] = useState("");
  const [address, setAddress] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [image, setImage] = useState("");
  const [openLoader, setOpenLoader] = useState(true);
  const [pin, setPin] = useState("");
  const [teacher_lastname, setTeacher_lastname] = useState("");

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let date = new Date();
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();

  let fullDate = `${year}-${month}-${day}`;

  let checkSnackbar = useContext(contextData);

  const handlesubmit = (e) => {
    e.preventDefault();

    const data = {
      teacher_name: teacher,
      teacher_gender: gender,
      teacher_age: age,
      teacher_dob: dob,
      teacher_email: email,
      teacher_number: phonenumber,
      teacher_lastname,
      teacher_qualification: qualification,
      teacher_address: address,
      teacher_profile: imageURL,
      pin: pin,
      tenant_id: localStorage.getItem("tenant_id"),
      teacher_login_credentials: {
        teacher_login_email: userEmail,
        teacher_password: userPassword,
      },
    };
    console.log(data);

    setOpenLoader(true);
    axios
      .put(Apis.EDUCATOR.update + id, data)

      .then((res) => {
        setOpenLoader(false);

        checkSnackbar.setState(true);
        console.log(res);
        navigate("/educators");
      })
      .catch((err) => {
        console.log(err);
        setOpenLoader(false);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: err.response.data.errormessage.details[0].message,
          type: "warning",
        });
      });
  };

  //get Educator Data function
  const getEducator = () => {
    axios
      .get(Apis.EDUCATOR.getById + id)
      .then((res) => {
        setOpenLoader(false);
        let [data] = res.data.data;
        console.log(data);
        setTeacher(data.teacher_name);
        setTeacher_lastname(data.teacher_lastname);
        setGender(data.teacher_gender);
        setAge(data.teacher_age);
        setDob(data.teacher_dob);
        setEmail(data.teacher_email);
        setPhonenumber(data.teacher_number);
        setQualification(data.teacher_qualification);
        setAddress(data.teacher_address);
        setImage(data.teacher_profile);
        setImageURL(data.teacher_profile);
        setUserEmail(data.teacher_login_credentials.teacher_login_email);
        setUserPassword(data.teacher_login_credentials.teacher_password);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getEducator();
  }, []);

  return (
    <>
      <Sidebar>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid px={3}>
          <Link to="/educators">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Typography sx={{ textAlign: "center", my: 4 }} variant="h5">
          Update Educator
        </Typography>
        <Grid container paddingX={6}>
          <form onSubmit={handlesubmit}>

            <div className="row m-0 bg-white p-md-5 p-3">

              <div className="col-md-6 col-12 p-2">
                <Typography>First Name</Typography>

                <TextField
                  id="outlined-basic"
                  size="medium"
                  className="w-100"
                  name="teacher"
                  value={teacher}
                  placeholder="First Name"
                  variant="outlined"
                  onChange={(e) => {
                    setTeacher(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Last Name</Typography>

                <TextField
                  id="outlined-basic"
                  size="medium"
                  className="w-100"
                  name="teacher_lastname"
                  value={teacher_lastname}
                  placeholder="Last Name"
                  variant="outlined"
                  onChange={(e) => {
                    setTeacher_lastname(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Address</Typography>

                <TextField
                  id="outlined-basic"
                  type="text"
                  size="medium"
                  className="w-100"
                  name="address"
                  value={address}
                  required
                  placeholder="Enter Address"
                  variant="outlined"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>DOB</Typography>

                <input
                  onChange={(e) => {
                    setDob(e.target.value);
                  }}
                  value={dob}
                  required
                  style={{ height: "57px" }}
                  name="dob"
                  className={`form-control m-0 w-100 inp_field ${styles.dob_edit}`}
                  type="date"
                  max={fullDate}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Email</Typography>

                <TextField
                  disabled
                  id="outlined-basic"
                  type="email"
                  name="email"
                  autoComplete="off"
                  value={email}
                  size="medium"
                  className="w-100"
                  required
                  placeholder="Enter Email"
                  variant="outlined"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Phone Number</Typography>

                <TextField
                  id="outlined-basic"
                  type="tel"
                  size="medium"
                  className="w-100"
                  name="phonenumber"
                  autoComplete="off"
                  required
                  value={phonenumber}
                  placeholder="Enter Phone Number"
                  variant="outlined"
                  onChange={(e) => {
                    setPhonenumber(e.target.value);
                  }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Qualification</Typography>

                <TextField
                  id="outlined-basic"
                  type="text"
                  size="medium"
                  name="qualification"
                  value={qualification}
                  className="w-100"
                  required
                  placeholder="Enter Qualification"
                  variant="outlined"
                  onChange={(e) => {
                    setQualification(e.target.value);
                  }}
                />


              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Pin</Typography>

                <TextField
                  id="outlined-basic"
                  type="text"
                  size="medium"
                  name="qualification"
                  value={pin}
                  inputProps={{ maxLength: 4, minLength: 4 }}
                  className="w-100"
                  placeholder="Enter Pin"
                  variant="outlined"
                  onChange={(e) => {
                    setPin(e.target.value);
                  }}
                />
              </div>
              <div className="col-md-6 col-12 p-2">
                <Typography>Gender</Typography>

                <FormControl fullWidth autoComplete="off">
                  <Select
                    size="medium"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="gender"
                    value={gender}
                    placeholder="Enter Gender"
                    required
                    fullWidth
                    onChange={(e) => {
                      setGender(e.target.value);
                    }}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="others">Others</MenuItem>
                  </Select>
                </FormControl>
              </div>


            </div>














            <Grid
              item
              md={12}
              mt={1}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                variant="outlined"
                color="error"
                sx={{
                  ml: "10px",
                  textTransform: "none",
                  mr: "8px",
                }}
                onClick={() => {
                  navigate("/educators");
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="info"
                sx={{ textTransform: "none" }}
              >
                Update
              </Button>
            </Grid>
          </form>
        </Grid>
      </Sidebar>
    </>
  );
};
export default EditEducator;
