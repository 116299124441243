import { DeleteOutlineOutlined } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";



export function DeleteConfirmComp({ msg, id, deleteFun }) {
    const [deleteDialog, setDeleteDialog] = useState(false)

    async function deleteAdmin() {
        setDeleteDialog(false)
        deleteFun(id)
    }

    return (
        <>
            <DeleteOutlineOutlined
                color="error"
                sx={{ cursor: "pointer" }}
                onClick={() => setDeleteDialog(true)}
            />
            <Dialog open={deleteDialog}>
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {msg}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() =>
                            setDeleteDialog(false)
                        }
                        sx={{ color: "#3FAEED" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "red" }}
                        onClick={deleteAdmin}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
}