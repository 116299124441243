

export const FindExtraDays = (dat, num) => {
    var d = new Date(dat);
    d.setMonth(d.getMonth() + num);
    let currentDate = new Date()
    const diffTime = Math.abs(currentDate - d);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays - 1
}

export const IncreaseDateByMonth = (dat, num) => {
    var d = new Date(dat);
    d.setMonth(d.getMonth() + num);
    return d
}

export const diffBtnTwoDates = (date1, date2) => {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
}

export const isMonthActive = (dat, num) => {
    var d1 = new Date(dat);
    d1.setMonth(d1.getMonth() + num);
    var d2 = new Date(dat);
    d2.setMonth(d2.getMonth() + num + 1);
    if (d1 < new Date() && d2 > new Date()) {
        return true
    }
    else {
        return false
    }

}
