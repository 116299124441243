import { useEffect, useState } from "react"
import { Sidebar } from "../../../components/Sidebar"
import { useNavigate, useParams } from "react-router-dom"
import { Center } from "./Center"
import axios from "axios"
import { Apis } from "../../../auth/GlobalUrl"
import { Backdrop, CircularProgress, Typography } from "@mui/material"
import { DeleteImageFromFirebase, ImageUpload } from "../../../firebase/ImageUpload"


export const EditCenter = () => {

    const [loader, setLoader] = useState(true)
    let navigate = useNavigate()
    let { id } = useParams()
    let [careData, setCareData] = useState({
        child_care_center_name: '',
        center_address: '',
        admin_array: [],
        login_activity_time: null
    })

    async function getChildCareCenter() {
        try {
            let res = await axios.get(Apis.SUPERADMIN.getChildCareCenterByid + id)
            setLoader(false)
            if (res.data.data.length !== undefined) {
                let { child_care_center_name, center_address, logo_url, admin_array, login_activity_time } = res.data.data[0]
                setCareData({ child_care_center_name, center_address, logo_url, admin_array, login_activity_time })
            }
            else {
                alert('something went wrong')
            }
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }
    }

    async function EditCenterSubmit(e) {
        let logo_url = careData.logo_url
        setLoader(true)
        if (e.name !== undefined) {
            await DeleteImageFromFirebase(logo_url)
            logo_url = await ImageUpload(e)
        }
        setLoader(true)
        try {
            let res = await axios.put(Apis.SUPERADMIN.updateChildCareCenterByid + id, { ...careData, logo_url })
            setLoader(false)
            navigate('/AllCenters')
            console.log(res)
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }


    }

    useEffect(() => {
        getChildCareCenter()
    }, [])

    return (
        <Sidebar>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />

            </Backdrop>
            <Typography className='text-center fs-2 my-2'>Edit Center</Typography>
            {!loader ?
                <Center mode={'Edit'} allData={{ data: careData, setData: setCareData }} formSubmit={EditCenterSubmit} />
                : null}
        </Sidebar>
    )
}