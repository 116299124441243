import { useRef } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
// import bcrypt from 'bcryptjs'

export const CheckRoleSuperAdmin = () => {
  const login = useRef(false);
  const role = localStorage.getItem("role");
  const bcyy = localStorage.getItem("superAdminToken")

  let StringSuperAdmin = 'JAI SHREE RAM'
  let navigate = useNavigate()

  // var adminPasssword = bcrypt.compare(
  //   StringSuperAdmin,
  //   bcyy
  // );

  // adminPasssword.then((res) => {
  //   if (!res) {
  //     localStorage.clear()
  //     navigate('/')
  //   }
  // })

  if (role === "SuperAdmin") {
    login.current = true;
  }
  else {
    login.current = false
  }

  return login.current ? <Outlet /> : <Navigate to="/" replace />;

};
