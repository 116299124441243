import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { BorderColor, Delete, MoreVert, Visibility } from "@mui/icons-material";
import { Box, Dialog, Container, Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";

export const ActionsButtons = ({ item, getData }) => {
  console.log(item)

  var navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  let [deleteParentId, setDeleteParentId] = useState("");
  const [loader, setLoader] = useState(false)
  let [deleteParentDialog, setDeleteParentDialog] = useState(false);
  let [openDelete, setOpenDelete] = useState(false);
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  function deleteParent(id) {
    setDeleteParentDialog(false);
    setLoader(true)
    handleClose()
    let url = `${baseURL}parent/deleteParent&Child&CfromRoom/${id}`;
    console.log(url)
    axios
      .delete(url)
      .then((res) => {
        getData();
        setLoader(false)
        setOpenDelete(false);
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Parent deleted successfully",
          type: "success",
        });
      })
      .catch((err) => console.log(err));
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog maxWidth={"md"} fullWidth={true} open={deleteParentDialog}>
        <Box sx={{ p: 2, py: 4 }}>
          Are you sure you want to delete parent ?
          <br />
          <small style={{ color: "#1799cd" }}>
            Note: child belongs to this parent also deleted
          </small>
          <Container align="right">
            <Button
              onClick={() => setDeleteParentDialog(false)}
              className="cancel_btn me-3"
            >
              Cancel
            </Button>
            <Button onClick={() => deleteParent(item._id)} className="btn_primary">
              Delete
            </Button>
          </Container>
        </Box>
      </Dialog>
      <MoreVert
        aria-describedby={id}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box py={2}>
          <div style={{}}>
            <div
              className="d-flex mx-4 pointer"
              onClick={() => navigate(`/parentview/${item._id}`)}
            >
              <span>
                <Visibility sx={{ color: "#FFB800", cursor: "pointer" }} />
              </span>
              <span className="ms-3">
                View
              </span>
            </div>
            <hr className="my-2 mx-3" />
            <div
              className="d-flex mx-4 pointer"
              style={{ marginTop: "15px" }}
              onClick={() => navigate(`/editparent/${item._id}`)}
            >
              <span>
                <BorderColor sx={{ color: "#169AC9", cursor: "pointer" }} />
              </span>
              <span className="ms-3">
                Edit
              </span>
            </div>
            <hr className="my-2 mx-3" />
            <div
              className="d-flex mx-4 pointer"
              style={{ marginTop: "15px" }}
              onClick={() => {
                setDeleteParentDialog(true);
              }}
            >
              <span>
                <Delete
                  sx={{
                    color: "#FF0000",
                    cursor: "pointer",
                  }}
                />
              </span>
              <span className="ms-3">Delete</span>
            </div>
          </div>
        </Box>
      </Popover>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
    </div >
  );
};
