import React, { useEffect } from "react";
import {
  Grid,
  Button,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Card,
  Typography,
  Backdrop,
  CircularProgress,
  Checkbox,
  OutlinedInput,
  ListItemText,
  checkboxClasses,
} from "@mui/material";
import { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { Apis } from "../../auth/GlobalUrl";

export default function AddChild() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  let [allGoals, setAllGoals] = useState([])
  console.log(localStorage.getItem('childcare_center'))

  let dat = new Date();
  let tenant_id = localStorage.getItem('tenant_id')
  let yr = dat.getFullYear();
  let mnt = dat.getMonth();
  let day = dat.getDate();
  let fullDate = `${yr}-${mnt + 1 < 10 ? "0" : ""}${mnt + 1}-${day < 10 ? "0" : ""}${day}`;
  var [childData, setchildData] = useState([
    { child_parentArray: [id], created_at: fullDate, child_school: localStorage.getItem('childcare_center'), tenant_id, goals: [] },
  ]);

  useEffect(() => {
    getAllGoals()
  }, [])

  async function getAllGoals() {
    try {
      let res = await axios.get(Apis.GOAL.getAll + tenant_id)
      console.log(res.data.data)
      setAllGoals(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }

  const getData = (e, index) => {
    var { name, value } = e.target;
    let arr = [...childData];
    arr[index][name] = value;
    setchildData(arr);
  };

  //for add new child in an array
  const postData = (e) => {
    e.preventDefault();
    setchildData([...childData, { created_at: fullDate, child_parentArray: [id], child_school: localStorage.getItem('childcare_center'), tenant_id, goals: [] }])
    console.log(childData, "child data");
  };

  //for submit data to api
  const postToApi = (e) => {

    e.preventDefault();
    let childupload = [...childData]
    console.log(childupload)
    childupload = childupload.map(e => {
      return {
        ...e, child_lastname: e.child_lastname.trim(), child_name: e.child_name.trim()
      }
    })


    setLoader(true)
    axios
      .post(Apis.CHILD.addChild, { data: childData })
      .then((res) => {
        let childIdArr = res.data.data.map(item => item._id);
        axios
          .put(Apis.CHILD.addChildIdIntoParent + id, {
            child_array: childIdArr,
          })
          .then((res) => {
            setLoader(false)
            navigate("/parentdetails");
          })
          .catch((err) => {
            setLoader(false)
          });
      })
      .catch((err) => {
        setLoader(false)
      });
  };

  function deleteChild(index) {
    let ararar = [...childData]
    ararar.splice(index, 1)
    setchildData(ararar)
  }


  return (
    <>
      <Sidebar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container px={4} py={2} style={{ background: "#EFF6FF" }}>
          <form onSubmit={postToApi} className="container-fluid">
            {childData.map((res, index) => {
              return (
                <Grid
                  container
                  key={index}
                  className="d-flex justify-content-center mt-2"
                >
                  <Grid item md={11} sm={11} xs={11}>
                    <Typography align="center" variant="h5" className="mb-2">
                      Child {index + 1} Details
                    </Typography>
                  </Grid>
                  <Grid item md={11} sm={11} xs={11}>


                    <div className="row m-0 bg-white p-md-5 p-3">

                      <div className="col-12 d-flex justify-content-end">
                        {childData.length > 1 ?
                          <CloseIcon
                            onClick={() => {
                              deleteChild(index)
                            }}
                          /> : null}
                      </div>




                      <div className="col-md-6 col-12 p-2">
                        <Typography>Child Name</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          required
                          name="child_name"
                          value={childData[index].child_name}
                          onChange={(e) =>
                            e.target.value == " "
                              ? (e.target.value = "")
                              : getData(e, index)
                          }
                          placeholder="Child First Name"
                          className="w-100"
                          sx={{ background: "#ffffff" }}
                        />
                      </div>
                      <div className="col-md-6 col-12 p-2">
                        <Typography>Child Last Name</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          required
                          value={childData[index].child_lastname}
                          name="child_lastname"
                          onChange={(e) =>
                            e.target.value == " "
                              ? (e.target.value = "")
                              : getData(e, index)
                          }
                          placeholder="Child Last Name"
                          className="w-100"
                          sx={{ background: "#ffffff" }}
                        />
                      </div>
                      <div className="col-md-6 col-12 p-2">
                        <Typography>Gender</Typography>


                        <select
                          name="child_gender"
                          value={childData[index].child_gender !== undefined ? childData[index].child_gender : ''}
                          onChange={(e) => getData(e, index)}
                          style={{ background: "#ffffff", height: '55px' }}
                          className="form-control"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          required
                          label="11"
                        >
                          <option value={""} disabled>Select gender</option>
                          <option value={"male"}>Male</option>
                          <option value={"female"}>Female</option>
                          <option value={"other"}>Other</option>
                        </select>
                      </div>
                      <div className="col-md-6 col-12 p-2">


                        <Typography>Childcare Center Name</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          required
                          value={localStorage.getItem("childcare_center")}
                          name="child_school"
                          onChange={(e) =>
                            e.target.value == " "
                              ? (e.target.value = "")
                              : getData(e, index)
                          }
                          placeholder="Enter Center Name"
                          className="w-100"
                          sx={{ background: "#ffffff" }}
                        />
                      </div>
                      <div className="col-md-6 col-12 p-2">
                        <Typography>Configuration Age</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          value={childData[index].child_age}
                          required
                          name="child_age"
                          onChange={(e) => getData(e, index)}
                          type="number"
                          placeholder="Age"
                          className="w-100"
                          sx={{ background: "#ffffff" }}
                        />
                      </div>
                      <div className="col-md-6 col-12 p-2">

                        <Typography>DOB</Typography>

                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          label='mm-dd-yyyy'
                          required
                          value={childData[index].child_dob}
                          name="child_dob"
                          type="date"
                          onChange={(e) => getData(e, index)}
                          placeholder="DD/MM/YYYY"
                          className="w-100"
                          sx={{ background: "#ffffff" }}
                        />

                      </div>
                      <div className="col-md-6 col-12 p-2">
                        <Typography>Goals</Typography>

                        <FormControl className="w-100" >
                          <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                          <Select fullWidth
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={childData[index].goals}
                            name='goals'
                            // onChange={handleChange}
                            onChange={(e) => getData(e, index)}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => childData[index].goals.join(',')}
                          >
                            {allGoals.sort((a, b) => a.goal_name.localeCompare(b.goal_name)).map(res => (
                              <MenuItem key={res.goal_name} value={res.goal_name}>
                                <Checkbox
                                  sx={{
                                    [`&, &.${checkboxClasses.checked}`]: {
                                      color: 'blue',
                                    },
                                  }}
                                  checked={childData[index].goals.indexOf(res.goal_name) > -1} />
                                <ListItemText primary={res.goal_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>



                  </Grid >
                </Grid >
              );
            })}
            <Grid container px={6}>
              <Grid
                item
                mt={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "end" }}
              >
                <Button
                  variant="other"
                  sx={{ border: "1px solid #FF0000", color: "red" }}
                  type="reset"
                  onClick={() => navigate("/parentdetails")}
                >
                  Cancel
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  type="submit"
                >
                  Submit
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  onClick={postData}
                >
                  Add more child
                </Button>
              </Grid>
            </Grid>
          </form >
        </Grid >
      </Sidebar >
    </>
  );
}


