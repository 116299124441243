import { Add, ControlPoint } from "@mui/icons-material";
import {
  Typography,
  Grid,
  Backdrop,
  CircularProgress,
  Dialog,
  Button,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Link } from "react-router-dom";
import axios from "axios";
import { SnackBar } from "../SnackBar";
import { contextData } from "../../Router";
import { ConfigurationCommonComp } from "./ConfigurationCommonFile";
import { Box } from "@mui/system";

export const AddConfigurationHome = () => {
  let [assesmentDialog, setAddAssesmentDialog] = useState(false);
  let [addDialog, setAddDialog] = useState(true);

  let snackBarState = useContext(contextData);
  let [loader, setLoader] = useState(true);
  let [allGoals, setAllGoals] = useState([])
  let [openLoader, setOpenLoader] = useState(false);
  let tenant_id = localStorage.getItem("tenant_id");
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let navigate = useNavigate();

  let [allActAgeSecSub, setAllActAgeSecSub] = useState({});

  let latestData = useRef([
    {
      learning_area: "Practical",
      assesmentActivity: [],
      ageGroup: [],
      section_name: "",
      assesment_name: "",
      enable_educator_to_comment: false,
      file_url: [],
      sub_section_name: "",
      activity_reference: false,
      tenant_id,
      config_info: '',
      goal_name: ''
    },
  ]);
  let [allConfiguration, setAllConfiguration] = useState([
    {
      learning_area: "Practical",
      assesmentActivity: [],
      ageGroup: [],
      section_name: "",
      assesment_name: "",
      enable_educator_to_comment: false,
      file_url: [],
      sub_section_name: "",
      activity_reference: false,
      tenant_id,
      config_info: '',
      goal_name: ''
    },
  ]);
  function getAllActAgeSecSub() {
    setOpenLoader(true);
    let url = `${baseURL}home/getActAgeSecSub/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        setAllActAgeSecSub({ ...allActAgeSecSub, ...res.data.data });

        console.log({ ...allActAgeSecSub, ...res.data.data });
        setLoader(false);
        setOpenLoader(false);
      })
      .catch((err) => console.log(err));
  }
  async function getAllGoals() {
    try {
      let res = await axios.get(Apis.GOAL.getAll + tenant_id)
      console.log(res.data.data)
      setAllGoals([...res.data.data.sort((a, b) => a.goal_name.localeCompare(b.goal_name))]);

      console.log({ ...allActAgeSecSub, goal: res.data.data })
    }
    catch (err) {
      console.log(err)
    }
  }



  function submitAssessment() {
    setOpenLoader(true);
    console.log(latestData.current[0])

    axios
      .post(Apis.ASSESSMENT.add, latestData.current[0])
      .then((res) => {
        console.log(res);

        if (res.data.data.learning_area !== undefined) {
          setAddAssesmentDialog(false);
          setAddDialog(true);
          latestData.current = [];
          setAllConfiguration([
            {
              learning_area: "Practical",
              assesmentActivity: [],
              ageGroup: [],
              section_name: "",
              assesment_name: "",
              enable_educator_to_comment: false,
              file_url: [],
              sub_section_name: "",
              activity_reference: false,
              tenant_id,
              config_info: '',
              goal_name: ''
            },
          ]);

          setOpenLoader(false);
          snackBarState.setState(true);

          navigate("/configuration");
        } else {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Assessment already exist",
            type: "warning",
          });
          setOpenLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);

        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: err.response.data.errormessage.details[0].message,
          type: "error",
        });
        setOpenLoader(false);
        // console.log(err.response.data.errormessage.details[0].message);
      });
  }
  useEffect(() => {
    getAllActAgeSecSub();
    getAllGoals()
  }, []);

  return (
    <Sidebar>
      <Grid px={3}>
        <Link to="/configuration">
          <ArrowBackIosIcon />
        </Link>
      </Grid>
      <Typography sx={{ textAlign: "center", my: 2 }} variant="h5">
        Add Assessment
      </Typography>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      {/* <AddConfiguration
        fun={cancelBtn}
        alldata={allData}
        wholeDialog={setAddAssesmentDialog}
        submitFun={addAssesment}
        mode="add"
      /> */}

      {!loader ? (
        <>
          {allConfiguration.map((res, index) => {
            return (
              <ConfigurationCommonComp
                index={index}

                latestData={latestData}
                allGoals={allGoals}
                allData={{
                  state: allConfiguration,
                  setState: setAllConfiguration,
                }}
                mode="add"
                functions={{ getAllMaterial: getAllActAgeSecSub, getAllGoals: getAllGoals }}
                configData={res}
                allMaterial={allActAgeSecSub}
              />
            );
          })}
          <Box sx={{ mx: 5, my: 3 }} align="right">
            <Button
              onClick={() => navigate("/configuration")}
              className="cancel_btn me-3"
            >
              Cancel
            </Button>
            <Button className="btn_primary" onClick={submitAssessment}>
              Save
            </Button>
          </Box>
        </>
      ) : null}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Sidebar>
  );
};
