import {
  Grid,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableHead,
  Typography,
  TableRow,
  Backdrop,
  CircularProgress,
  TextareaAutosize,
  Box,
  TableBody
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import { ProfileLogoName } from "../../components/ProfileLogoName";

const ChildRecords = () => {
  const parentId = localStorage.getItem("parentId");
  console.log(parentId)

  const [childData, setChildData] = useState([])
  console.log(childData)

  let [singleChild, setSingleChild] = useState([])
  console.log(singleChild)

  const [singleChildData, setSingleChildData] = useState()
  console.log(singleChildData)


  const [filterByDate, setFilterByDate] = useState([])
  console.log(filterByDate)
  console.log(filterByDate.length)

  const [childAss, setChildAss] = useState([])
  const [lastDate, setLastDate] = useState('')
  console.log(lastDate)
  const [getLastDate, setGetLastDate] = useState('')

  let [open, setopen] = useState(true)
  const blankImage = 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
  let currdate = new Date()
  console.log(currdate)
  let date = new Date();
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  let month =
    date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  let year = date.getFullYear();
  let fullDate = `${year}-${month}-${day}`;

  const getData = () => {
    axios
      .get(`${baseURL}parent/getparent/${parentId}`)
      .then((res) => {
        console.log(res);
        setChildData(res.data.data.Child_data)
        let childIdd = res.data.data.Child_data[0]._id
        axios.get(`${baseURL}child/getChild/${childIdd}`)
          .then((res) => {
            console.log(res)
            let [data] = res.data.data
            console.log(data)
            setSingleChild(res.data.data)
            setSingleChildData(data)
            let all = data.child_records
            console.log(all)
            var filterLatestDate = all.filter((item, index) => item.created_at === fullDate)
            console.log(filterLatestDate)
            // let [date1] = filterLast
            // setLastDate(date1.created_at)
            // console.log(date1.created_at)
            setChildAss(all)
            setFilterByDate(filterLatestDate)
            // setopen(false)
          }).catch((err) => {
            console.log(err)
          })
        setopen(false)
      })
      .catch((err) => {
        console.log(err);
        setopen(false)
      });
  };



  const getSingleChildData = (e, ind) => {
    let btn = document.getElementsByClassName("child_item");
    let i = 0;
    for (i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.borderBottom = "none";
    }
    e.target.style.color = "#FA981D";
    e.target.style.borderBottom = "1px solid #FA981D";
    e.preventDefault()
    let data = childData[ind]
    console.log(data)
    setSingleChild([data])
    setChildAss(data.child_records)
    let all = data.child_records
    console.log(all)
    var filterLatestDate = all.filter((item, index) => item.created_at === fullDate)
    console.log(filterLatestDate)
    setChildAss(all)
    setFilterByDate(filterLatestDate)
    document.getElementById('myDate').value = fullDate;
    // if(all.length!==0){
    //   var filterLast = [all[all.length-1]]
    //   console.log(filterLast)
    //   let [date1] = filterLast
    //   console.log(date1)
    //   // setFilterByDate(filterLast)
    //   date1.created_at!==''?setLastDate(date1.created_at):''
    // }
  }



  const getDate = (e, i) => {
    console.log(e.target.value)
    setLastDate(e.target.value)
    // if(e.target.value!==''){
    let filter = childAss.filter((item, index) => item.created_at === e.target.value)
    setFilterByDate(filter)
    console.log(filter)
    // }else{
    //  getData()
    // }
  }



  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Sidebar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid p={3} sx={{ backgroundColor: "#EFF6FF" }}>
          <Grid
            container
            py={2}
            px={4}
            sx={{
              backgroundColor: "white",
              boxShadow: "1",
              boxShadow: "0 0 5px 0 #888888",
            }}
          >
            {childData.map((item, index) => (
              <Grid item pr={3} key={index}>
                <Typography
                  value={item}
                  onClick={(e) => getSingleChildData(e, index)}
                  className={index == 0 ? "child_item active" : "child_item"}
                  sx={{ cursor: "pointer" }}
                >
                  {item.child_name}
                </Typography>
              </Grid>
            ))}
          </Grid>
          {singleChild.map((item, index) => (
            <Grid
              key={index}
              mt={3}
              px={2}
              pt={2}
              sx={{ backgroundColor: "white", boxShadow: "0 0 5px 0 #888888" }}
            >
              <Grid
                px={1}
                container
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Grid item>
                  <Grid container>
                    <Grid item>
                      {/* <img
                          style={{
                            width: "80px",
                            height: "80px",
                            borderRadius: "50px",
                          }}
                          src={item.child_profileUrl ? item.child_profileUrl : blankImage}
                        /> */}
                      <ProfileLogoName
                        firstName={item.child_name}
                        lastName={item.child_lastname}
                      />
                    </Grid>
                    <Grid item pt={2} pl={2}>
                      <Typography>{item.child_name}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item pt={2}>
                  <Grid container>
                    <Grid item>
                      <input
                        type="date"
                        id="myDate"
                        className="form-control"
                        max={fullDate}
                        defaultValue={fullDate}
                        onChange={(e) => getDate(e, index)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {filterByDate.length !== 0 ? (
                <TableContainer component={Paper} sx={{ boxShadow: "0" }}>
                  <Table sx={{ minWidth: 650, border: "none" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Record</TableCell>
                        <TableCell align="left">Sub Record</TableCell>
                        <TableCell align="left">Qty.</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell align="left">Educator Name</TableCell>
                        <TableCell align="left">Comment</TableCell>
                      </TableRow>
                    </TableHead>
                    {filterByDate.map((item, index) => (
                      <TableBody key={index}>
                        <TableRow>
                          <TableCell sx={{ color: "#1799CE" }}>
                            {item.record_name}
                            <br />
                            <span style={{ fontSize: "12px", color: "gray" }}>
                              {item.time}{" "}
                              {+item.time.slice(0, 2) > 12 ? "PM" : "AM"} &nbsp;
                              {item.created_at}
                            </span>
                          </TableCell>
                          <TableCell align="left">
                            {item.subrecord_name}
                          </TableCell>
                          <TableCell align="left">
                            {item.amount ? item.amount : "--"}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "180px" }}>
                            {item.comment_amount ? item.comment_amount : "--"}
                          </TableCell>
                          <TableCell align="left">
                            {item.educator_name}
                          </TableCell>
                          <TableCell align="left" sx={{ width: "180px" }}>
                            {/* {item.comment} */}
                            <TextareaAutosize
                              minRows={1}
                              maxRows={2}
                              // aria-label="maximum height"
                              placeholder="Comments"
                              value={item.comment}
                              style={{ width: "100%" }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </TableContainer>
              ) : (
                <Typography px={2} py={4}>
                  No Data Found
                </Typography>
              )}
            </Grid>
          ))}
        </Grid>
      </Sidebar>
    </>
  );
};
export default ChildRecords;
