import { Grid, LinearProgress } from "@mui/material";
import { Sidebar } from "../../components/Sidebar";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../baseURL";


export const Home = () => {

  const [data, setData] = useState({});
  let [loader, setLoader] = useState(true)
  let tenant_id = localStorage.getItem('tenant_id')
  const getData = () => {
    axios
      .get(`${baseURL}home/getAllData/home/${tenant_id}`)
      .then((res) => {
        console.log(res.data.data)
        setData(res.data.data);
        setLoader(false)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
    if (window.location.pathname === "/home") {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

  }, []);


  return (
    <Sidebar>
      <>
        {!loader ?
          <Grid container px={2} spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid item md={3}>
              <Card sx={{ height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6" align="center" >
                    Assessments
                  </Typography>
                  <Typography variant="body2" align="center">
                    {data.assessmentData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card sx={{ height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6" align="center">
                    Educators
                  </Typography>
                  <Typography variant="body2" align="center">
                    {data.teacherData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card sx={{ height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6" align="center">
                    Parents
                  </Typography>
                  <Typography variant="body2" align="center">
                    {data.parentData}

                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card sx={{ height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6" align="center">
                    Children
                  </Typography>
                  <Typography variant="body2" align="center">
                    {data.childData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item md={3}>
              <Card sx={{ height: '100%', width: '100%' }}>
                <CardContent>
                  <Typography variant="h6" align="center">
                    Rooms
                  </Typography>
                  <Typography variant="body2" align="center">
                    {data.roomData}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid> :
          <Box sx={{ mt: 5, px: 4 }}>
            <LinearProgress sx={{ color: "red", bgcolor: "skyblue" }} />
          </Box>
        }

        {/* <Grid container spacing={2} mt={4} px={2}>
          <Grid item md={12} sm={12} xs={12}>
            <img src="images/graph.png" />
          </Grid>
        </Grid> */}

      </>
    </Sidebar>
  )
};
