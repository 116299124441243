import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { contextData } from "../../Router";
import { baseURL } from "../baseURL";
import { SnackBar } from "../SnackBar";
import { EditAddAdminDesign } from "./EditAddAdminDesign";

export const EditAdmin = () => {
  const navigate = useNavigate();
  let [adminData, setAdminData] = useState({});
  let [loader, setLoader] = useState(true);
  let snackBarState = useContext(contextData);
  let [oldCenters, setOldCenters] = useState([])
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let { id } = useParams();
  useEffect(() => {
    getAdminById();
  }, []);
  function getAdminById() {

    let url = `${baseURL}admin/getAdmin/${id}`;

    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data, "{{{{{{{{{{{{{{{++++++++++++++++++++++++}}}}}}}}}}}}}}")
        setAdminData(res.data.data[0]);
        setOldCenters(res.data.data[0].child_center)
        console.log(res.data.data[0]);
        setLoader(false);
      })
      .catch((err) => console.log(err));
  }

  function editAdmin(data) {


    let deletedId = oldCenters.filter(e => data.child_center.indexOf(e) == -1)


    axios
      .put(`${baseURL}admin/updateAdmin/${id}`, { ...data, remove_childCare_center: deletedId })
      .then((res) => {
        console.log(res);
        setLoader(false);
        snackBarState.setState(true);
        navigate("/adminlisting");
      })
      .catch((err) => {
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Some error occured",
          type: "error",
        });
        // if (err.response) {
        //   ShowSnackbar({
        //     show: true,
        //     vertical: "top",
        //     horizontal: "right",
        //     msg: err.response.data.errormessage,
        //     type: "error",
        //   });
        // }
        // console.log(err.response.data.errormessage);
      });
    console.log(data);
  }
  return (
    <Sidebar>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      {loader ? null : (
        <EditAddAdminDesign
          admindata={adminData}
          submitFun={editAdmin}
          mode={"editAdmin"}
        />
      )}
    </Sidebar>
  );
};
