import React, { useContext } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
  Dialog,
  Box,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect } from "react";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";

export default function EditParent() {
  var navigate = useNavigate();
  var { id } = useParams();
  const [showPass, setShowPass] = useState(false);


  var [userEmail, setUserEmail] = useState("");
  var [userPassword, setUserPassword] = useState("");
  var [father, setfather] = useState("");
  var [father_lastname, setFather_lastname] = useState("");
  var [mother, setmother] = useState("");
  var [parentNo, setparentNo] = useState("");
  var [parentAlt, setparentAlt] = useState("");
  var [childs, setchilds] = useState("");
  var [parentEmail, setparentEmail] = useState("");
  var [fatherOcc, setfatherOcc] = useState("");
  var [motherOcc, setmotherOcc] = useState("");
  var [workAdd, setworkAdd] = useState("");
  var [homeAdd, sethomeAdd] = useState("");
  const [loader, setLoader] = useState(true);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let snackbarContext = useContext(contextData);

  const postData = (e) => {
    setLoader(true);
    e.preventDefault();
    let data = {
      father_name: father,
      mother_name: mother,
      parent_phone_number: parentNo,
      parent_alternative_phone_number: parentAlt,
      number_of_child: childs,
      father_lastname,
      parent_email: parentEmail,
      father_occupation: fatherOcc,
      mother_occupation: motherOcc,
      parent_work_address: workAdd,
      parent_home_address: homeAdd,
      tenant_id: localStorage.getItem("tenant_id"),
      login_credentials: {
        parent_password: "",
        parent_login_email: "",
      },
    };
    axios
      .put(`${baseURL}parent/updateParent/${id}`, data)
      .then((res) => {
        console.log(res);
        setLoader(false);
        snackbarContext.setState(true);
        // navigate(`/addchild/${res.data.data._id}`)
        navigate("/parentdetails");
      })
      .catch((err) => {
        setLoader(false);
        if (err.response !== undefined) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage.details[0].message,
            type: "success",
          });
        }
      });
  };

  const getData = () => {
    axios
      .get(`${baseURL}parent/getParent/${id}`)
      .then((res) => {
        setLoader(false);

        console.log(res.data.data);
        let parentData = res.data.data;
        setfather(parentData.father_name);
        setmother(parentData.mother_name);
        setparentNo(parentData.parent_phone_number);
        setparentAlt(parentData.parent_alternative_phone_number);
        setchilds(parentData.number_of_child);
        setFather_lastname(parentData.father_lastname);

        setparentEmail(parentData.parent_email);
        setfatherOcc(parentData.father_occupation);
        setmotherOcc(parentData.mother_occupation);
        setworkAdd(parentData.parent_work_address);
        sethomeAdd(parentData.parent_home_address);
        setUserEmail(parentData.login_credentials.parent_login_email);
        setUserPassword(parentData.login_credentials.parent_password);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, []);


  return (
    <>
      <Sidebar>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid px={3}>
          <Link to="/parentdetails">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Typography align='center' py={2} variant="h5">
          Edit Parent
        </Typography>

        <Grid container pb={2}>
          <Grid container paddingX={6}>

            <div className="row m-0 bg-white p-md-5 p-3">

              <div className="col-md-6 col-12 p-2">
                <Typography>Father First Name</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="father_name"
                  placeholder="Enter Father Name"
                  value={father}
                  onChange={(e) => setfather(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Father Last Name</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="father_lastname"
                  placeholder="Father Last Name"
                  value={father_lastname}
                  onChange={(e) => setFather_lastname(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Mother Name</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="mother_name"
                  placeholder="Enter Mother Name"
                  value={mother}
                  onChange={(e) => setmother(e.target.value)}
                  Typography="DD/MM/YY"
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Phone Number</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  required
                  name="parent_phone_number"
                  type="number"
                  placeholder="Enter Number"
                  value={parentNo}
                  onChange={(e) => setparentNo(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Alternative Number</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  required
                  type="number"
                  name="parent_alternative_phone_number"
                  placeholder="Enter Number"
                  value={parentAlt}
                  onChange={(e) => setparentAlt(e.target.value)}
                  Typography="DD/MM/YY"
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Number of Child</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  required
                  type="number"
                  name="number_of_child"
                  placeholder="Enter child Number"
                  value={childs}
                  onChange={(e) => setchilds(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Email</Typography>
                <TextField
                  disabled
                  id="outlined-basic"
                  variant="outlined"
                  autoComplete="off"
                  required
                  name="parent_email"
                  type="email"
                  placeholder="Enter Email"
                  value={parentEmail}
                  onChange={(e) => setparentEmail(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Father's Occupation</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="father_occupation"
                  placeholder="Enter Occupation"
                  value={fatherOcc}
                  onChange={(e) => setfatherOcc(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Mother's Occupation</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="mother_occupation"
                  placeholder="Enter Occupation"
                  value={motherOcc}
                  onChange={(e) => setmotherOcc(e.target.value)}
                  Typography="DD/MM/YY"
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Work Address</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="parent_work_address"
                  placeholder="Enter Work Address"
                  value={workAdd}
                  onChange={(e) => setworkAdd(e.target.value)}
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

              <div className="col-md-6 col-12 p-2">
                <Typography>Home Address</Typography>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  name="parent_home_address"
                  required
                  placeholder="Enter Home Address"
                  value={homeAdd}
                  onChange={(e) => sethomeAdd(e.target.value)}
                  Typography="DD/MM/YY"
                  className="w-100"
                  sx={{ background: "#ffffff" }}
                />
              </div>

            </div>

            <Grid container px={6}>
              <Grid
                item
                mt={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="other"
                  sx={{ border: "1px solid #FF0000", color: "red" }}
                  onClick={() => {
                    navigate("/parentdetails");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  onClick={postData}
                >
                  Update Parent
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Sidebar>
    </>
  );
}
