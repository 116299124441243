import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import {
  BorderColor,
  Delete,
  Edit,
  MoreVert,
  RemoveRedEye,
  Visibility,
} from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";
export const ActionChildButton = ({ data, getData }) => {
  console.log(data);
  console.log(getData)
  const [open1, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [children, setChildren] = useState([]);
  let [all, getAll] = useState([]);
  let [loader, setLoader] = useState(true);

  const [deletedChildId, setDeletedChildId] = useState("");
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  var navigate = useNavigate();
  const handleClickOpen = (id) => {
    setOpen(true);
    setDeletedChildId(id);
  };
  const handleClose1 = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // useEffect(() => {
  //   getData();
  // }, []);
  // const getData = () => {
  //   var url = `${baseURL}child/getAllChild/${localStorage.getItem(
  //     "tenant_id"
  //   )}`;
  //   axios
  //     .get(url)
  //     .then((res) => {
  //       console.log(res.data.data);
  //       setChildren(res.data.data);
  //       getAll(res.data.data);
  //       setLoader(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const deleteChild = (e, id) => {
    console.log(deletedChildId);

    e.preventDefault();
    axios
      .delete(`${baseURL}child/deleteChild/${id}`)
      .then((res) => {
        getData();
        console.log(res);

        handleClose1();
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Child deleted successfully",
          type: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
      <Dialog open={open1}>
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this child?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} sx={{ color: "#3FAEED" }}>
            Cancel
          </Button>
          <Button
            onClick={(e) => deleteChild(e, data._id)}
            sx={{ color: "red" }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <MoreVert
        aria-describedby={id}
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box py={2}>
          <div
            className="d-flex mx-4 pointer"
            onClick={() => navigate(`/childview/${data._id}`)}
          >
            <span>
              <RemoveRedEye sx={{ color: "#FFB800" }} />
            </span>
            <span className="ms-3">
              View
            </span>
          </div>
          <hr className="my-2 mx-3" />
          <div
            className="d-flex pointer mx-4"
            style={{ marginTop: "15px" }}
            onClick={() => navigate(`/editchild/${data._id}`)}
          >
            <span>
              <Edit sx={{ color: "#169AC9" }} />
            </span>
            <span className="ms-3">
              Edit
            </span>
          </div>
          <hr className="my-2 mx-3" />

          <div
            className="d-flex mx-4 pointer"
            style={{ marginTop: "15px" }}
            onClick={() => handleClickOpen(data._id)}
          >
            <Delete sx={{ color: "#FF0000" }} />
            <span className="ms-3">Delete</span>
          </div>

        </Box>
      </Popover>
    </div>
  );
};
