export const baseURL = "https://childcare-714af9baa16a.herokuapp.com/"

export const Apis = {
    checkPreviousLogin: `${baseURL}getData`,
    EDUCATOR: {
        verifyPin: `${baseURL}verifyPin/`,
        add: `${baseURL}teacher/addTeacher`,
        getById: `${baseURL}teacher/getTeacher/`,
        delete: `${baseURL}teacher/deleteTeacher/`,
        update: `${baseURL}teacher/updateTeacher/`,
        getAll: `${baseURL}teacher/getAllTeacher/`,
        addBulk: `${baseURL}teacher/addBulkTeacher`,
        setEducatorPinFalse: `${baseURL}pinInactive/`,
        addTermAndGoal: `${baseURL}terms/addTermsGoal`,
        resetPassword: `${baseURL}updateEducatorPassword/`,
        editTermAndGoal: `${baseURL}terms/editTermAndGoal/`,
        getCurrentTermGoal: `${baseURL}terms/getCurrentTermGoal/`,
        getPinVisiblity: `${baseURL}teacher/getTeacherActivityOnly/`,
        getLoginVisiblityTime: `${baseURL}center/getChildCareCenterByTenantId/`
    },
    PARENT: {
        bulkupload: `${baseURL}parent/addBulkParent`,
        resetPassword: `${baseURL}updateParentPassword/`
    },
    CHILD: {
        edit: `${baseURL}child/editChild/`,
        getById: `${baseURL}child/getChild/`,
        addChild: `${baseURL}child/addChild`,
        getChildRoom: `${baseURL}child/getChildRoom/`,
        addChildIdIntoParent: `${baseURL}parent/addChildIdIntoParent/`
    },
    ROOM: {
        add: `${baseURL}room/addRoom`,
        delete: `${baseURL}room/deleteRoom/`,
        update: `${baseURL}room/updateRoom/`,
        getRoomData: `${baseURL}room/getRoom/`,
        getAllEducatorsOfRoom: `${baseURL}room/getEducatorFromRoom/`,
    },
    BASELINE: {
        add: `${baseURL}baseline/createBaseLine`,
        get: `${baseURL}baseline/getBaseLineData/`
    },
    GOAL: {
        add: `${baseURL}goal/addGoal`,
        getAll: `${baseURL}goal/getGoal/`,
        addBulkGoalForExcel: `${baseURL}goal/addBulkGoal`
    },
    ASSESSMENT: {
        getByAge: `${baseURL}assesment/getAssessmentByAge`,
        getByGoals: `${baseURL}child/getAssessmentDataByGoal/`,
        add: `${baseURL}assesment/addassesment`,
        getById: `${baseURL}assesment/`,
        deleteAssessment: `${baseURL}assesment/delete/`,
        getAllByTenant_id: `${baseURL}assesment/get/allAssesments/`,
        addSubsection: `${baseURL}subsection/addsubSection`,
        getAllSubsectionBySectionId: `${baseURL}section/getSubsectionOfSection/`
    },
    SUPERADMIN: {
        getAllAdmins: `${baseURL}admin/getAllAdmin`,
        addChildCareCenter: `${baseURL}center/createCenter`,
        getAllChildCareCenters: `${baseURL}center/getAllChildCareCenter`,
        getChildCareCenterByid: `${baseURL}center/getChildCareCenterById/`,
        updateChildCareCenterByid: `${baseURL}center/updateCenter/`,
        getAllCentersByAdminid: `${baseURL}admin/getCenterDataOfAdmin/`,
        deleteChildCareCenter: `${baseURL}superadmin/deleteCenter/`
    },
    LOGO: {
        get: `${baseURL}logo/getLogo/`
    },
    ADMIN: {
        changeCred: `${baseURL}updateAdminPassword/`
    }

}




