import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, Outlet } from "react-router-dom"
import { ParentLogoApis } from "../Redux-toolkit/Slices/ParentLogoSlices"
import { GetEducatorLoginVisiblityTime } from "../Redux-toolkit/Slices/GetEducatorLogInVisiblityTime"

export const CheckRoomAuth = () => {

    let login = useRef(false)



    let role = localStorage.getItem("role")
    let roomName = localStorage.getItem("roomName")
    let tenant_id = localStorage.getItem("tenant_id")
    let roomId = localStorage.getItem("roomId")

    let dispatch = useDispatch()

    useEffect(() => {
        // this  action is use to fetch latest logo of curent room 
        dispatch(ParentLogoApis())
        dispatch(GetEducatorLoginVisiblityTime())
    }, [])


    if (role == "Room" && roomName !== null && roomId !== null && tenant_id !== null) {
        login.current = true
    }
    else {
        login.current = false
    }


    return login.current ? <Outlet></Outlet> : <Navigate to="/" replace />
}