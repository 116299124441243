import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import storage from "./firebase";


export const ImageUpload = async (file) => {
    const storageRef = ref(storage, `/images/${Math.random()}${file.name}`);
    const url2 = await uploadBytes(storageRef, file);
    let url = await getDownloadURL(url2.ref)
    return url

}

export const DeleteImageFromFirebase = async (url) => {
    const desertRef = ref(storage, url);
    let res = await deleteObject(desertRef)
    console.log("Image deleted successfully")
    return res
}