import axios from "axios"
import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { baseURL } from "../../auth/GlobalUrl"
import { Sidebar } from "../../components/Sidebar"
import { contextData } from "../../Router"
import { SnackBar } from "../SnackBar"
import { EditAddAdminDesign } from "./EditAddAdminDesign"

export const AddAdmin = () => {
  let navigate = useNavigate()
  let snackBarState = useContext(contextData);

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let [adminData, setAdminData] = useState({
    admin_name: "",
    admin_login_credentials: {
      admin_email: "",
      admin_password: '',
    },
    admin_contact: '',
    admin_address: "",
    email: '',
    child_center: []
  })

  function onSubmit(data) {


    console.log(data)


    let url = `${baseURL}admin/addAdmin`;
    axios
      .post(url, data)
      .then((res) => {
        // setLoader(false)

        snackBarState.setState(true)
        navigate('/adminlisting')
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
        if (err.response) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage,
            type: "error",
          });
        }

      });
  }
  return (
    <>
      <Sidebar>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
        <EditAddAdminDesign admindata={adminData} submitFun={onSubmit} mode={'addAdmin'} />
      </Sidebar>
    </>
  )
}