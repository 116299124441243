import { Navigate, Outlet } from "react-router-dom"
import { useEffect, useRef } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { educatorPinCrud, setPinStatus } from "../Redux-toolkit/Slices/EducatorPinSlice"
import axios from "axios"
import { Apis } from "./GlobalUrl"
import { io } from "socket.io-client"


export const EducatorPinAuth = () => {
    let id = localStorage.getItem('id')
    var socket = io('https://childcare-714af9baa16a.herokuapp.com', {
        auth: {
            id
        },

    })

    let time = localStorage.getItem("educatorLoginTime") * 1


    let timout;
    let educatorStatus = useSelector(s => s.educator_pin_activity)

    let dispatch = useDispatch()



    async function resetTime() {

        clearTimeout(timout)
        timout = setTimeout(() => {
            console.log('okokokokokokokokokokokokok***********************8')
            setEducatorPinFalse()
        }, time !== null ? time : 30000);
    }
    console.log('*********************************{{{{{{{{{{{{{}}}}}}}}}}}}}]')

    // useEffect(() => {
    //     resetTime()
    // }, [sel])

    async function setEducatorPinFalse() {
        try {
            id = localStorage.getItem("id")

            dispatch(setPinStatus(false))

            console.log(id, "{{{}}}{function dispatch dispatch")
            sessionStorage.clear()
            let res = await axios.put(Apis.EDUCATOR.setEducatorPinFalse + id)
            // localStorage.removeItem("id")
            localStorage.removeItem("educatorId")
            localStorage.removeItem("teacher_name")
            socket.emit("logout", { id: id })

        }
        catch (err) {
            console.log(err)
        }
    }

    let EducatorPinAuthstate = sessionStorage.getItem("EducatorPinAuth")

    useEffect(() => {


        let main_con = document.getElementById('main_con')
        main_con.addEventListener('mousemove', () => {
            
            resetTime()
        })
        main_con.addEventListener('keydown', () => {
            resetTime()
        })
        main_con.addEventListener('touchmove', () => {
            // alert("okok")
            resetTime()
        })
        resetTime()
        setTimeout(() => {
            dispatch(educatorPinCrud())
        }, 5000)


        return () => {

            // socket.disconnect(id)
            // okok()
        }


    }, [])
    let eduId = localStorage.getItem("educatorId")

    // async function okok() {
    //     let res = await axios.put(Apis.EDUCATOR.setEducatorPinFalse + eduId)


    return (
        <>
            <div id="main_con"  >
                {educatorStatus && EducatorPinAuthstate !== null ? <Outlet></Outlet> : <Navigate to='/allEducatorsForRoom' />}
            </div>
        </>
    )
}