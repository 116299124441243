import { Backdrop, CircularProgress, Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const ViewAdmin = () => {
  const { id } = useParams();
  let [adminData, setAdminData] = useState({});
  let [loader, setLoader] = useState(true);
  let navigate = useNavigate()
  useEffect(() => {
    console.log(id);
    getAdminById();
  }, []);
  function getAdminById() {
    let url = `${baseURL}admin/getAdmin/${id}`;
    axios
      .get(url)
      .then((res) => {
        setAdminData(res.data.data);
        setLoader(false);
        console.log(res.data.data);
      })
      .catch((err) => console.log(err));
  }
  return (
    <Sidebar>
      {loader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (

        <>
          <Grid px={3}>
            <Link to='/adminlisting'>
              <ArrowBackIosIcon />
            </Link>
          </Grid>

          <Grid sx={{ backgroundColor: "#EFF6FF", padding: "0 60px" }}>
            <Grid item >
              <Typography variant='h4' sx={{ textAlign: 'center' }} pb={1}>Admin Details</Typography>
            </Grid>
            <Grid sx={{ backgroundColor: "white", boxShadow: "0 0 5px 0 #888888" }} px={3} py={2}>
              <Grid container>

              </Grid>
              <Grid container>
                <Grid item md={3}>
                  <Typography>Admin Name :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.admin_name}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container pt={2}>
                <Grid item md={3}>
                  <Typography>Address :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.admin_address}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={3}>
                  <Typography>Contact :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.admin_contact}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={3}>
                  <Typography>Childcare Center :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.child_care_center}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={2}>
                <Grid item md={3}>
                  <Typography>Email :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.admin_login_credentials.admin_email}
                  </Typography>
                </Grid>
              </Grid>
              {/* <Grid container pt={2}>
                <Grid item md={3}>
                  <Typography>Password :</Typography>
                </Grid>
                <Grid item>
                  <Typography sx={{ color: "#1799CE" }}>
                    {adminData.admin_login_credentials.admin_password}
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>

          </Grid>
        </>)}
    </Sidebar>
  );
};
