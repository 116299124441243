import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Apis } from "../../../auth/GlobalUrl";
import { DeleteImageFromFirebase } from "../../../firebase/ImageUpload";

export const DeleteCenter = ({ res, setLoader, getAll }) => {
    let [dialog, setDialog] = useState(false)

    async function deleteCenter2() {
        setDialog(false)
        setLoader(true)
        console.log("deleted sucee seccess")
        try {
            let res2 = await axios.delete(Apis.SUPERADMIN.deleteChildCareCenter + res._id)
            DeleteImageFromFirebase(res.logo_url)
            console.log(res2)
            getAll()
            setLoader(false)
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }
    }

    return (
        <>
            <Delete
                sx={{ cursor: "pointer", color: "red" }}
                onClick={() => { setDialog(true) }} />

            <Dialog open={dialog}>
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure You Want To Delete Childcare Center ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialog(false)}
                        sx={{ color: "#3FAEED" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "red" }}
                        onClick={deleteCenter2}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}