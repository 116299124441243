import { createTheme } from "@mui/material";

export const Theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      main: "#cde0f7",
    },
    secondary: {
      main: "#45841b",
    },
  },
});
