import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  Backdrop,
  CircularProgress,
  Popover,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { SnackBar } from "../SnackBar";
import { contextData } from "../../Router";
import { Info, Stop } from "@mui/icons-material";
import { Box } from "@mui/system";
import { getLatestAge } from "../getLatestAge";
import * as XLSX from "xlsx";
import { ResetEducatorPassword } from "./ResetEducatorPassword";
import { DeleteEducatorComp } from "./DeleteEducator";
import { ProfileLogoName } from "../../components/ProfileLogoName";
import { ActionEductor } from "./ActionEducator";
import { io } from "socket.io-client"
var socket = io('https://childcare-714af9baa16a.herokuapp.com')

const Educators = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [openDialog, setOpenDialog] = useState(false)
  const [all, setAll] = useState([]);
  const [bulkEducators, setBulkEducators] = useState('')
  const [teacherError, setTeacherError] = useState([])


  let [loader, setLoader] = useState(true);
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "success",
  });




  let checkSnackbar = useContext(contextData);

  let arr1 = [{ row: 1, column: 2, errors: 3 }];

  let tenant_id = localStorage.getItem("tenant_id");
  let child_care_center = localStorage.getItem("childcare_center");

  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  useEffect(() => {
    getAllEducators();

    if (checkSnackbar.state) {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Educator saved successfully",
        type: "success",
      });
      checkSnackbar.setState(false);
    }
  }, []);


  const onSearch = (e) => {
    setData(
      all.filter(
        (r) =>
          r.teacher_name
            .toLowerCase()
            .trim()
            .includes(e.target.value.toLowerCase().trim())
        //   ||
        // r.teacher_age
        // .toString()
        // .trim()
        // .includes(e.target.value.toString().trim())
      )
    );
  };


  function getAllEducators() {
    let tenant_id = localStorage.getItem("tenant_id");
    // ResetPasswordEducator(false);
    axios
      .get(Apis.EDUCATOR.getAll + tenant_id)
      .then((res) => {

        console.log(res.data.data);
        setData(res.data.data);
        setAll(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  socket.on("getOnlineUser", (info) => {
    let datas = data.map(e => {
      if (e._id === info.userId) {
        e.pin_activity = true;
        return e
      } else {
        return e
      }
    })
    setData(datas);
  })
  socket.on("getOfflineUser", (info) => {
    let datas = data.map(e => {
      if (e._id === info.userId) {
        e.pin_activity = false;
        return e
      } else {
        return e
      }
    })
    setData(datas);
  })
  function DataGrid({ item }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose2 = () => {
      setAnchorEl(null);
    };

    const open2 = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <Box>
        <Info
          sx={{
            cursor: "pointer",
            color: "white",
            bgcolor: "black",
            borderRadius: "50%",
            fontSize: "20px",
          }}
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        />
        <Popover
          id={id}
          open={open2}
          anchorEl={anchorEl}
          onClick={handleClose2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ p: 3 }}>
            {item.room_data.map((e, index) => (
              <>
                <Typography>
                  <span style={{ color: "black" }}>
                    <Stop sx={{ fontSize: "16px", color: "grey" }} />
                  </span>{" "}
                  {e.room_name}
                </Typography>
              </>
            ))}
          </Box>
        </Popover>
      </Box>
    );
  }


  function uploadEducatorExcel() {
    setOpenDialog(false)

    let fileReader = new FileReader();
    let nameArr = bulkEducators.name.split(".")
    fileReader.readAsBinaryString(bulkEducators);
    if (nameArr[nameArr.length - 1] === "xlsx" || nameArr[nameArr.length - 1] === "xls" || nameArr[nameArr.length - 1] === "XLSX") {
      fileReader.onload = () => {
        let workbook = XLSX.read(fileReader.result, { type: "binary" });
        let sheetNames = workbook.SheetNames;
        let teacherData = XLSX.utils.sheet_to_json(
          workbook.Sheets[sheetNames[0]]
        );
        console.log(teacherData);

        if (teacherData.length !== 0) {
          let teacherErr = [];
          let dubEmail = [];

          let sheetemails = teacherData.map(function (item) {
            return item.teacher_email
          });
          sheetemails.forEach(function (item, idx) {
            if (sheetemails.indexOf(item) != idx) { dubEmail.push(item) }
          });
          teacherData.forEach((e, i) => {
            let error = []
            if (e.teacher_name === undefined) error.push("teacher_name (empty)")
            if (e.teacher_lastname === undefined) error.push("teacher_lastname (empty)")
            if (e.teacher_email === undefined) { error.push("teacher_email (empty)") } else {
              if (dubEmail.indexOf(e.teacher_email) > -1) error.push("teacher_email (already exists)")
            }
            if (e.teacher_number === undefined) error.push("teacher_number (empty)")
            if (e.teacher_lastname === undefined) error.push("teacher_lastname (empty)")
            if (e.teacher_gender === undefined) error.push("teacher_gender (empty)")
            if (e.teacher_address === undefined) error.push("teacher_address (empty)")
            if (e.teacher_dob === undefined) error.push("teacher_dob (empty)")
            if (e.teacher_qualification === undefined) error.push("teacher_qualification (empty)")
            if (e.pin === undefined) error.push("pin (empty)")

            if (error.length !== 0) teacherErr.push({ row: i + 2, error: error })
          })
          if (teacherErr.length === 0) {
            let dupArray = []
            let dup2Arr = []
            let educatorMandatoryFieds = [
              "teacher_name", "teacher_lastname", "teacher_email", "teacher_address", "teacher_qualification", "teacher_dob", "pin"
            ]
            let arrCheck = teacherData[0]
            let mandFields = []
            // check all mandatory field from excel data
            educatorMandatoryFieds.forEach((res) => {
              if (arrCheck[res] == undefined) {

                mandFields.push(res)
              }
            })

            if (mandFields.length !== 0) {
              ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: mandFields.join(',') + ' are mandatory',
                type: "warning",
              });
              mandFields = []
              return
            }

            // check duplicate educators 
            teacherData.forEach((res, index) => {
              // inside excel data 
              if (teacherData.findIndex(ee => ee.teacher_email == res.teacher_email) !== index) {
                dupArray.push(index + 1)
              }
              // inside whole database 
              let allInd = all.findIndex(al => al.teacher_email == res.teacher_email)
              if (allInd !== -1) {
                dup2Arr.push(index)
              }
            })


            if (dupArray.length !== 0) {
              ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: dupArray.join(',') + 'are duplicate from excel',
                type: "warning",
              });
              return
            }
            if (dup2Arr.length !== 0) {

              ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: dup2Arr.join(',') + 'are duplicate from Database',
                type: "warning",
              });
              return
            }

            setLoader(true)
            let finalArr = []
            teacherData.forEach((res) => {
              finalArr.push({
                ...res,
                teacher_gender: res.teacher_gender,
                tenant_id,
                child_care_center,
                teacher_login_credentials: {
                  teacher_password: "",
                  teacher_login_email: res.teacher_email
                },
              })
            })

            axios.post(Apis.EDUCATOR.addBulk, { data: finalArr })
              .then((res) => {
                getAllEducators()
                setBulkEducators('')
                setLoader(false)
              })
              .catch((err) => {
                setBulkEducators('')
              })
          } else {
            setTeacherError(teacherErr)
            setOpenDialog(true)
          }

        } else {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: "Excel file is empty",
            type: "warning",
          });

          return
        }

      }
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Please upload excel file",
        type: "warning",
      });

      return
    }

  }





  return (
    <>
      <Sidebar>
        <Dialog open={openDialog}>
          <Box px={2} className="py-5 gap-4">
            <input
              onChange={(e) => {
                setBulkEducators(e.target.files[0]);
                // e.target.value = ''
              }}
              onClick={() => setTeacherError([])}
              type="file"
              accept=".xlsx, .xls, .xlsb , .xlsm, .xltx"
              className="form-control"
            />
            <div className="d-flex justify-content-end pt-4">
              <Button
                onClick={() => {
                  setOpenDialog(false);
                  setBulkEducators("");
                }}
                className="cancel_btn me-3"
                size="small"
              >
                Cancel
              </Button>
              <Button
                onClick={uploadEducatorExcel}
                disabled={bulkEducators?.name == undefined}
                variant="contained"
                color="info"
                size="small"
              >
                Submit
              </Button>
            </div>
            {teacherError.length !== 0 && (
              <>
                <div>
                  <h6>Error</h6>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Row</TableCell>

                        <TableCell>Error</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teacherError.map((data, index) => (
                        <TableRow>
                          <TableCell>{data.row}</TableCell>
                          <TableCell>{data.error.join(", ")}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </Box>
        </Dialog>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid Container sx={{ display: "flex", justifyContent: "end" }}>
          <Grid item={4} sx={{ position: "absolute" }}>
            <Collapse in={show}>
              <Alert
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                Educator Successfuly Deleted
              </Alert>
            </Collapse>
          </Grid>
        </Grid>
        <Grid container paddingX={2}>
          <Grid container m={0}>
            <Grid
              container
              spacing={2}
              py={2}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid item>
                <TextField
                  id="input-with-icon-textfield"
                  onChange={onSearch}
                  className="searchInput"
                  placeholder="Search anything"
                  size="medium"
                  sx={{ backgroundColor: "white" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  className="btn_primary me-3 cap"
                  onClick={() => {
                    setTeacherError([]);
                    setOpenDialog(true);
                  }}
                >
                  Import Educators
                </Button>
                <Link to="/teacherdetails">
                  <AddCircleIcon
                    color="info"
                    className="fs-3
                  "
                  />
                </Link>
              </Grid>
            </Grid>
          </Grid>

          <Grid container mt={2}>
            <TableContainer component={Paper}>
              <Table sx={{ width: "100%" }}>
                <TableHead>
                  <TableRow
                    sx={{
                      bgcolor: "#FCFCFC",
                    }}
                  >
                    <TableCell>S.No</TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left">Educator Name</TableCell>
                    <TableCell align="left">Age</TableCell>
                    <TableCell align="left">Number Of Rooms</TableCell>
                    <TableCell align="left">Rooms</TableCell>
                    <TableCell align="left">Action</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        bgcolor: "#FCFCFC",
                      }}
                    >
                      <TableCell align="left">
                        {index ? index + 1 : "1"}
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          <div>
                            <ProfileLogoName
                              firstName={item.teacher_name}
                              lastName={item.teacher_lastname}
                            />
                            <div
                              style={{
                                position: "relative",
                                bottom: "25px",
                                left: "30px",
                              }}
                            >
                              {item.pin_activity && (
                                <>
                                  <span
                                    class="logged-in"
                                    style={{ color: "green", fontSize: "25px" }}
                                  >
                                    ●
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell align="left">{item.teacher_name}</TableCell>
                      <TableCell align="left">
                        {getLatestAge(item.teacher_dob)} years
                      </TableCell>
                      <TableCell align="left">
                        {item.teacher_room.length}
                      </TableCell>

                      <TableCell align="left">
                        {item.room_data.length !== 0 ? (
                          <DataGrid item={item} />
                        ) : (
                          "----"
                        )}
                      </TableCell>

                      <TableCell align="left" component="th" scope="row">
                        {/* <VisibilityIcon
                          sx={{ color: "orange", cursor: "pointer" }}
                          onClick={() => navigate(`/teacherview/${item._id}`)}
                        />
                        <BorderColorOutlinedIcon
                          color="info"
                          sx={{ cursor: "pointer" }}
                          onClick={() => navigate(`/editeducator/${item._id}`)}
                        />
                        <DeleteEducatorComp
                          getAllData={getAllEducators}
                          data={item}
                        /> */}
                        <ActionEductor
                          data={item}
                          getAllEducators={getAllEducators}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="info"
                          size="small"
                          onClick={() => navigate(`/assignroom/${item._id}`)}
                        >
                          Assign Room
                        </Button>
                        {/* <ResetEducatorPassword data={item} /> */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Sidebar>
    </>
  );
};
export default Educators;
