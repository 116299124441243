import { useState } from "react"
import styles from "./index.module.css"
import { Backdrop, Button, CircularProgress } from "@mui/material"
import axios from "axios"
import { Apis } from "../../auth/GlobalUrl"
import { FindExtraDays, IncreaseDateByMonth, diffBtnTwoDates, isMonthActive } from "./FindExtraDays"
import { SnackBar } from "../SnackBar"



export const TermGoalForm = ({ data, setData, setAddTermGoalsMode }) => {

  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "",
    type: "",
  });


  const [allMonthsData, setAllMonthsData] = useState([
    { data: data?.activityFor_term ?? "", isShow: true, isEdit: false, editKey: 'activityFor_term' },
    { data: data.month_one?.observation ?? "", isShow: new Date(data.start_date) < new Date(), isEdit: false, editKey: 'month_one' },
    { data: data.month_two?.observation ?? "", isShow: new Date(data.start_date) < new Date(), isEdit: false, editKey: 'month_two' },
    { data: data.month_three?.observation ?? "", isShow: new Date(data.start_date) < new Date(), isEdit: false, editKey: 'month_three' },
  ])
  let teacher_name = localStorage.getItem("teacher_name")
  const [nextTermAndGoal, setNextTermAndGoal] = useState({
    endterm_assessment: '',
    forwardPlanning_nextmonth: ''
  })

  const [loader, setLoader] = useState(false)

  const editMonth = async (ind, editKey) => {



    let termsAndGoalData;
    if (editKey !== 'activityFor_term') {
      if (allMonthsData[ind].data.trim() == '') {
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: 'Please Fill Observation',
          type: "warning",
        });
        return
      }

      termsAndGoalData = {
        [editKey]: {
          observation: allMonthsData[ind].data,
          updated_at: new Date(),
          Submitted_by: data[editKey].Submitted_by !== undefined ? data[editKey].Submitted_by : teacher_name,
          Edited_by: teacher_name,
          created_at: data[editKey].created_at !== undefined ? data[editKey].created_at : new Date()
        }
      }
    }
    else {
      if (allMonthsData[ind].data.trim() == '') {
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: 'Please Fill Activity',
          type: "warning",
        });
        return
      }
      termsAndGoalData = {
        [editKey]: allMonthsData[ind].data
      }
    }

    let obj = { ...data }
    obj[editKey] = termsAndGoalData[editKey]
    setData({ ...obj })
    let arr = [...allMonthsData]
    arr[ind].isEdit = false
    setAllMonthsData([...arr])
    termAndGoalSubmit(termsAndGoalData)


  }

  const moveToEdit = (ind) => {
    let arr = [...allMonthsData]
    arr[ind].isEdit = true
    setAllMonthsData([...arr])
  }

  const termAndGoalSubmit = async (termsAndGoalData) => {
    setLoader(true)
    try {
      setLoader(true)
      let res = await axios.put(Apis.EDUCATOR.editTermAndGoal + data._id, termsAndGoalData)
      setLoader(false)


    }
    catch (err) {
      console.log(err)
      setLoader(false)
    }
  }

  const textAreaChange = ({ name, value }, ind, editKey) => {

    let arr = [...allMonthsData]
    arr[ind].data = value
    arr[ind].updated_at = new Date()
    arr[ind].Edited_by = teacher_name
    setAllMonthsData([...arr])
    // let obj = { ...data }
    // if (editKey !== 'activityFor_term') {

    //   obj[editKey] = {
    //     observation: value,
    //     updated_at: new Date(),
    //     created_at: data[editKey].created_at !== undefined ? data[editKey].created_at : new Date()
    //   }
    // }
    // else {
    //   obj[editKey] = value
    // }
    // setData({ ...obj })


  }

  const finalSubmit = async (e) => {
    e.preventDefault()
    console.log(allMonthsData)

    // This logic is needed when teacher add all month data and directly hit final submit button without save button 

    let finalData = {
      activityFor_term: allMonthsData[0].data,
      month_one: {
        observation: allMonthsData[1].data,
        Edited_by: allMonthsData[1].Edited_by !== undefined ? allMonthsData[1].Edited_by : data.month_one.Edited_by,
        Submitted_by: data.month_one.Submitted_by !== undefined ? data.month_one.Submitted_by : teacher_name,
        created_at: data.month_one.created_at !== undefined ? data.month_one.created_at : new Date(),
        updated_at: allMonthsData[1].updated_at !== undefined ? allMonthsData[1].updated_at : data.month_one.updated_at
      },
      month_two: {
        observation: allMonthsData[2].data,
        Edited_by: allMonthsData[2].Edited_by !== undefined ? allMonthsData[1].Edited_by : data.month_two.Edited_by,
        Submitted_by: data.month_two.Submitted_by !== undefined ? data.month_two.Submitted_by : teacher_name,
        created_at: data.month_two.created_at !== undefined ? data.month_one.created_at : new Date(),
        updated_at: allMonthsData[2].updated_at !== undefined ? allMonthsData[2].updated_at : data.month_two.updated_at
      },
      month_three: {
        observation: allMonthsData[3].data,
        Edited_by: allMonthsData[3].Edited_by !== undefined ? allMonthsData[1].Edited_by : data.month_three.Edited_by,
        Submitted_by: data.month_three.Submitted_by !== undefined ? data.month_three.Submitted_by : teacher_name,
        created_at: data.month_three.created_at !== undefined ? data.month_one.created_at : new Date(),
        updated_at: allMonthsData[3].updated_at !== undefined ? allMonthsData[3].updated_at : data.month_three.updated_at
      },
    }
    console.log(finalData)
    let datafin = { ...data, ...finalData }

    console.log(datafin)

    if (datafin.month_one.observation.trim() !== '' && datafin.month_two.observation.trim() !== '' && datafin.month_three.observation.trim() !== '') {

      // console.log(allMonthsData)

      await termAndGoalSubmit({ ...finalData, ...nextTermAndGoal, current_activity: false })
      setAddTermGoalsMode(true)

    }
    else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: 'Please Fill All Month Data',
        type: "warning",
      });
    }


  }

  function isMonthOverDue(key, num) {
    let d = IncreaseDateByMonth(data.start_date, num)
    if (new Date(d) < new Date() && data[key].observation.trim() == '') {
      return true
    }
  }
  function isFilled(key) {
    if (data[key].observation.trim() !== '') return true
  }
  function isDelayFilled(key, num) {

    if (data[key].created_at !== undefined) {
      let created_date = data[key].created_at
      let startDatePlus = IncreaseDateByMonth(data.start_date, num)
      console.log(startDatePlus)
      console.log(created_date)
      if (new Date(startDatePlus) > new Date(created_date)) {
        return ''
      }
      else {
        return diffBtnTwoDates(startDatePlus, created_date) + 1 + 'over due by day'
      }
    }
    else {
      return true
    }

  }
  function cancelForm(k, e) {

    let ddd = [...allMonthsData]
    if (e == 'activityFor_term') {
      ddd[k].data = data[e]
    }
    else {
      ddd[k].data = data[e].observation
    }
    ddd[k].isEdit = false
    setAllMonthsData(ddd)
  }
  console.log(isDelayFilled('month_one', 1))

  return (
    <div className="mx-5">
      <form onSubmit={finalSubmit}>
        <div className="bg-white px-4 pt-4 pb-5 mt-4">
          <div className={`  ${styles.startEnd_date}`}>
            <div className={`${styles.start_date1}`}>
              Start Date :{" "}
              {new Date(data.start_date).toLocaleString("default", {
                day: "numeric",
                year: "numeric",
                month: "long",
              })}
            </div>
            <div className={`${styles.end_date1}`}>
              Finish Date :{" "}
              {new Date(data.end_date).toLocaleString("default", {
                day: "numeric",
                year: "numeric",
                month: "long",
              })}
            </div>
          </div>

          <div className={`row mt-3 align-items-center ${styles.dattattat}`}>
            <div className={`${styles.status_class}`} >
              Status
            </div>
            <div
              className={`  border
                             ${isMonthActive(data.start_date, 0)
                  ? styles.active_color
                  : `${styles.bgGrey}`
                }
                             ${isMonthOverDue("month_one", 1)
                  ? styles.over_due_color
                  : `${styles.bgGrey}`
                }
                             ${isFilled("month_one") ? styles.filled_color : `${styles.bgGrey}`}
                           ${styles.month1} `}
            >
              Month 1
            </div>

            <div
              className={`  border
                             ${isMonthActive(data.start_date, 1)
                  ? styles.active_color
                  : ""
                } ${styles.top_bar_btn}
                             ${isMonthOverDue("month_two", 2)
                  ? styles.over_due_color
                  : ""
                }
                             ${isFilled("month_two") ? styles.filled_color : ""}
                       ${styles.month2} `}
            >
              Month 2
            </div>

            <div
              className={`col-3 py-1 border
                             ${isMonthActive(data.start_date, 2)
                  ? styles.active_color
                  : ""
                } ${styles.top_bar_btn}
                             ${isMonthOverDue("month_three", 3)
                  ? styles.over_due_color
                  : ""
                }
                             ${isFilled("month_three")
                  ? styles.filled_color
                  : ""
                }
                           ${styles.month3} `}
            >
              Month 3
            </div>
            <div className={`${styles.over_due} `} >
              Over due by: # days
            </div>
          </div>

          <div className="row mt-1 align-items-center">
            <div className={`col-1`}></div>
            <div className={`col-3 py-1`}>
              {isDelayFilled("month_one", 1) == true ? (
                <>
                  {new Date(
                    new Date(data.start_date).setMonth(
                      new Date(data.start_date).getMonth() + 1
                    )
                  ) < new Date()
                    ? FindExtraDays(data.start_date, 1) + 1 + " days"
                    : null}
                </>
              ) : (
                isDelayFilled("month_one", 1)
              )}
            </div>
            <div className={`col-3 py-1`}>
              {isDelayFilled("month_two", 2) == true ? (
                <>
                  {new Date(
                    new Date(data.start_date).setMonth(
                      new Date(data.start_date).getMonth() + 2
                    )
                  ) < new Date()
                    ? FindExtraDays(data.start_date, 2) + 1 + " days"
                    : null}
                </>
              ) : (
                isDelayFilled("month_two", 2)
              )}
            </div>
            <div className={`col-3 py-1`}>
              {isDelayFilled("month_three", 3) == true ? (
                <>
                  {new Date(
                    new Date(data.start_date).setMonth(
                      new Date(data.start_date).getMonth() + 3
                    )
                  ) < new Date()
                    ? FindExtraDays(data.start_date, 3) + 1 + " days"
                    : null}
                </>
              ) : (
                isDelayFilled("month_three", 3)
              )}
            </div>
          </div>
        </div>

        {allMonthsData.map((res, key) => {
          return (
            <div key={key} style={{ display: res.isShow ? "block" : "none" }}>
              <div className="mt-4 mb-2 d-flex justify-content-between">
                <div className="">
                  {key == 0
                    ? "Activities For The Term"
                    : "Month " + key + " Observation"}
                </div>
                {key !== 0 ? (
                  <>
                    {data[res.editKey].Submitted_by !== undefined ?
                      <>

                        <div>
                          Submitted By :- {data[res.editKey].Submitted_by}
                        </div>
                        <div>
                          Updated By :- {data[res.editKey].Edited_by}
                        </div>
                      </> : null}
                    {data[res.editKey].created_at !== undefined ? (
                      <div className="d-flex justify-content-around">
                        <div>
                          Submitted on :-{" "}
                          {new Date(
                            data[res.editKey].created_at
                          ).toLocaleString("default", {
                            day: "numeric",
                            year: "numeric",
                            month: "long",
                          })}
                        </div>
                        <div>
                          Edited on :-{" "}
                          {new Date(
                            data[res.editKey].updated_at
                          ).toLocaleString("default", {
                            day: "numeric",
                            year: "numeric",
                            month: "long",
                          })}
                        </div>
                      </div>
                    ) : null}
                  </>

                ) : (
                  ""
                )}
              </div>
              <div>
                <textarea
                  required
                  disabled={!res.isEdit}
                  name={res.editKey}
                  onChange={(e) => textAreaChange(e.target, key, res.editKey)}
                  rows={8}
                  value={res.data}
                  className="form-control"
                ></textarea>
              </div>
              <div className="d-flex justify-content-end mt-2">
                {res.isEdit ?
                  <Button className="cancel_btn me-2" onClick={() => cancelForm(key, res.editKey)}>Cancel</Button>
                  : null}
                <Button
                  onClick={() => {
                    res.isEdit
                      ? editMonth(key, res.editKey)
                      : moveToEdit(key);
                  }}
                  className="btn_primary"
                  disabled={
                    key !== 0
                      ? new Date(
                        IncreaseDateByMonth(data.start_date, key - 1)
                      ) > new Date()
                      : false
                  }
                >
                  {res.isEdit ? "Save" : "Edit"}
                </Button>
              </div>
            </div>
          );
        })}

        {new Date(data.start_date) < new Date() ? (
          <div>
            <div className="mt-4 mb-2 d-flex">End Term Assessment</div>
            <div>
              <textarea
                required
                onChange={(e) =>
                  setNextTermAndGoal({
                    ...nextTermAndGoal,
                    endterm_assessment: e.target.value,
                  })
                }
                rows={5}
                className="form-control"
              ></textarea>
            </div>

            <div className="mt-4 mb-2 d-flex">
              Forward Planning for Next Term
            </div>
            <div>
              <textarea
                required
                onChange={(e) =>
                  setNextTermAndGoal({
                    ...nextTermAndGoal,
                    forwardPlanning_nextmonth: e.target.value,
                  })
                }
                rows={5}
                className="form-control"
              ></textarea>
            </div>

            <div className="d-flex justify-content-center mt-4">
              <Button
                className="btn_primary"
                type="submit"
                disabled={
                  new Date(IncreaseDateByMonth(data.start_date, 2)) >
                  new Date()
                }
              >
                Submit Term Goal
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ color: "red" }}>Term And Goal Not Started Yet</div>
        )}
      </form>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
    </div>
  );
}