import React from 'react'
import { Grid, Button, TextField, InputAdornment, Select, MenuItem, FormControl, InputLabel, FormControlLabel, Checkbox, Box, FormGroup, Typography } from '@mui/material'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import SearchIcon from '@mui/icons-material/Search';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { Sidebar } from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
export default function InjuryonIntake() {
    const navigate = useNavigate();
    return (
        <>
            <Sidebar>
                <Grid m={3}>
                    <Typography>Injury On Intake Report</Typography>
                    <Typography>&nbsp;</Typography>
                    <Grid container px={4} py={5} style={{ background: 'white', border: '2px solid #bdbdbd', borderRadius: 4 }}>
                        <Grid container className='d-flex justify-content-end '>
                            <Grid item md={8} sm={8} xs={12} sx={{ height: '100%' }}>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginRight: '8px' }}> + </Button>
                                <TextField
                                    id="input-with-icon-textfield"

                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <AutoAwesomeMotionIcon /> </Button>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <DeleteIcon /> </Button>

                            </Grid>
                        </Grid>
                        <Grid container my={3} className='d-flex justify-content-center'>
                            <Grid item md={5} lg={5} xs={8} className='d-flex justify-content-between'>
                                <Typography variant='h4'>Add Details</Typography>
                            </Grid>
                        </Grid>

                        <Grid container mt={2}>
                            <Grid item md={6} sm={6} xs={12} >
                                <Grid container>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Typography>Name Of child</Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select/Enter</InputLabel>
                                            <Select
                                                sx={{ background: '#ffffff' }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={age}
                                                label="00"
                                            // onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <Grid container>
                                    <Grid item lg={4} md={4} sm={4} xs={12} px={2}><Typography>Medication administered</Typography></Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12} >
                                        <Grid container>
                                            <Grid item lg={5} md={5} sm={5} xs={6}>
                                                <TextField
                                                    id="date"
                                                    type="date"
                                                    defaultValue="2022-05-24"
                                                    sx={{ width: '100%' }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={7} md={7} sm={7} xs={6}>
                                                <Grid container>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">11</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="11"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">00</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="00"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">AM</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="AM"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem >AM</MenuItem>
                                                                <MenuItem >PM</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container my={3}>
                            <Grid item md={6} sm={6} xs={11}>
                                <Grid container>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Typography>Responsible Person</Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Select/Enter</InputLabel>
                                            <Select
                                                sx={{ background: '#ffffff' }}
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                // value={age}
                                                label="00"
                                            // onChange={handleChange}
                                            >
                                                <MenuItem value={10}>Ten</MenuItem>
                                                <MenuItem value={20}>Twenty</MenuItem>
                                                <MenuItem value={30}>Thirty</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} sm={6} xs={1} px={2}>
                                <FormControlLabel control={<Checkbox color='info' />} label="Me" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={6} sm={6} xs={12}>
                                <Grid container my={2}>
                                    <Grid item md={6}>
                                        <Typography className=''>Room</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField id="outlined-basic" variant="outlined" className='w-100' label='Room Name' sx={{ background: '#ffffff' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={6} sm={6} xs={12}>
                                <Grid container my={2}>
                                    <Grid item md={6}>
                                        <Typography className=''>Room Lead</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                        <TextField id="outlined-basic" variant="outlined" className='w-100' label='Room Lead Name' sx={{ background: '#ffffff' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={6} sm={6} xs={12} className='d-flex justify-content-center'>
                                <Box
                                    className='w-100'
                                    component="img"
                                    sx={{
                                        height: '100%',
                                        width: '100%',

                                    }}
                                    alt="The house from the offer."
                                    src="https://t3.ftcdn.net/jpg/02/38/00/56/360_F_238005627_QgvAPoIEet2f14EAZHt0CqAmyLCByqIE.jpg"
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <Grid container py={5} px={3}>
                                    <Grid item md={6} xs={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Abrasion, scrape" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Bite" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Broken bone/fracture" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Brise" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Burn" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Concussion" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6} xs={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Cut" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Allergic reaction" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Anaphylaxis reaction" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Asthma respiratory" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Sunburn" />
                                            <FormControlLabel control={<Checkbox color='info' />} label="Other" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={6} sm={6} xs={12}></Grid>
                            <Grid item md={5} sm={5} xs={12}>
                                <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                            </Grid>
                        </Grid>
                        <Grid container py={3}>
                            <Grid item md={7} sm={7} xs={12}>
                                <Grid container>
                                    <Grid item lg={4} md={4} sm={4} xs={12}><Typography>Incident Date/Time</Typography></Grid>
                                    <Grid item lg={8} md={8} sm={8} xs={12} >
                                        <Grid container>
                                            <Grid item lg={5} md={5} sm={5} xs={6}>
                                                <TextField
                                                    id="date"
                                                    type="date"
                                                    defaultValue="2022-05-24"
                                                    sx={{ width: '100%' }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item lg={7} md={7} sm={7} xs={6}>
                                                <Grid container>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">11</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="11"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">00</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="00"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid md={4}>
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">AM</InputLabel>
                                                            <Select
                                                                sx={{ background: '#ffffff' }}
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                // value={age}
                                                                label="AM"
                                                            // onChange={handleChange}
                                                            >
                                                                <MenuItem >AM</MenuItem>
                                                                <MenuItem >PM</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item md={12} sm={12} xs={12} py={2}>
                                <Typography>Incident Description</Typography>
                            </Grid>
                            <Grid item md={6} sm={6} xs={11}>
                                <TextField
                                    sx={{ background: '#ffffff' }}
                                    placeholder="Describe the story..."
                                    multiline
                                    className='w-100'
                                    rows={8}
                                    col
                                />
                            </Grid>
                            <Grid item md={1} sm={1} xs={1} sx={{ padding: '90px 0' }}>
                                <KeyboardVoiceIcon />
                            </Grid>
                        </Grid>

                        <Grid container py={3}>
                            <Grid item md={6} sm={6} xs={12}>
                                <Grid container>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <Typography>Location of the incident</Typography>
                                    </Grid>
                                    <Grid item md={6} sm={6} xs={12}>
                                        <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item md={3} sm={3} xs={12}><Typography>Was medical personal contacted?</Typography></Grid>
                            <Grid item md={4} sm={4} xs={12}>
                                <FormControl>
                                    <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                    >
                                        <FormControlLabel value="Yes" control={<Radio color='info' />} label="Yes" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <FormControlLabel value="No" control={<Radio color='info' />} label="No" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container py={2}>
                            <Grid item>
                                <Typography sx={{ color: 'gray' }}>
                                    If yes please provide the full name and number of the practitioner who gave further treatment
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container my={2}>
                            <Grid item md={6} >
                                <Grid container my={2}>
                                    <Grid item md={5}>
                                        <Typography className=''>Medical Practitioner Nmae</Typography>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6}>
                                <Grid container my={2}>
                                    <Grid item md={5} px={2} className=''>
                                        <Typography>Contact Number</Typography>
                                    </Grid>
                                    <Grid item md={7}>
                                        <TextField id="outlined-basic" variant="outlined" label='' className='w-100' sx={{ background: '#ffffff' }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid item mt={6} >
                                <Button sx={{ mx: 2 }} className="cancel_btn">Cancel</Button>
                                <Button className="btn_primary" onClick={() => navigate('/intakesummary')}>Next</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Sidebar>
        </>
    )
}
