import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Apis } from "../../auth/GlobalUrl"
import { Box, Button, Grid, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material"
import { LogoutSharp, Search } from "@mui/icons-material"

export const AllEducatorsForRoom = () => {
    let { id } = useParams()
    let roomId = localStorage.getItem('roomId')
    const [allEducators, setAllEducators] = useState([])
    const [allData, setAllData] = useState([])

    let roomName = localStorage.getItem('roomName')

    let navigate = useNavigate()
    async function getAllEducatorsOfRoom() {
        try {
            let res = await axios.get(Apis.ROOM.getAllEducatorsOfRoom + roomId)
            console.log(res.data.data[0].teacher_data)
            setAllEducators(res.data.data[0].teacher_data)
            setAllData(res.data.data[0].teacher_data)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllEducatorsOfRoom()
    }, [])
    function logout() {
        localStorage.clear()
        navigate('/')
    }


    function inpChange(e) {
        setAllEducators(allData.filter(re => re.teacher_name.toLowerCase().trim().includes(e.target.value.trim())))
    }
    return (
        <>
            <Box sx={{ minHeight: '100vh', bgcolor: '#EFF6FF' }}>
                <Box onClick={logout} className="d-flex justify-content-end pt-4 px-md-5 px-sm-3 px-2" sx={{ cursor: 'pointer' }}>
                    <b>
                        <LogoutSharp sx={{ color: "red" }} />{" "}
                        <span style={{ color: "gray" }}>Logout</span>
                    </b>
                </Box>

                <Box className="px-md-5 px-sm-3 px-2 py-3">


                    <div className="d-sm-flex align-items-center">
                        <div className="w-sm-50 w-100 fs-4 mb-2">
                            {roomName} - Assigned Eductors
                        </div>
                        <div className="w-sm-50 w-100 text-end">
                            <TextField
                                onChange={inpChange}
                                className="searchInput"
                                placeholder="Search"
                                size="medium"
                                sx={{ backgroundColor: "white" }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <Box sx={{ overflowX: 'scroll' }}>

                        <Table className="bg-white mt-5" >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Sno.</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allEducators.map((res, key) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell>{key + 1}</TableCell>
                                            <TableCell>{res.teacher_name}</TableCell>
                                            <TableCell>
                                                <Button
                                                    onClick={() => {
                                                        navigate(`/verifyPin/${res._id}`)
                                                    }}
                                                    className="btn_primary">Enter Pin</Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </Box>

                </Box>

            </Box>



        </>
    )
}