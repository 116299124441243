import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar";
import { contextData } from "../../Router";
import { baseURL } from "../baseURL";
import { SnackBar } from "../SnackBar";
import { ConfigurationCommonComp } from "./ConfigurationCommonFile";
import { Apis } from "../../auth/GlobalUrl";

export const EditAllConfiguration = () => {
  const tenant_id = localStorage.getItem("tenant_id");
  const [allActAgeSecSub, setAllActAgeSecSub] = useState({});
  const [loader, setLoader] = useState(true);
  const [allConfiguration, setAllConfiguration] = useState([]);
  const navigate = useNavigate();
  const latestData = useRef([]);

  let snackBarState = useContext(contextData);
  let [allGoals, setAllGoals] = useState([])
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  let { id } = useParams();

  let ttt = [];
  let num = 0
  useEffect(() => {
    getAllActAgeSecSub();
    getAllConfiguration();
    getAllGoals()
    console.log(id);
  }, []);
  async function getAllGoals() {
    try {
      let res = await axios.get(Apis.GOAL.getAll + tenant_id)
      console.log(res.data.data)
      setAllGoals([...res.data.data]);
      console.log(res.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }
  function getAllConfiguration() {
    let url = `${baseURL}assesment/get/allAssesments/${tenant_id}`;
    axios
      .get(url)
      .then((res2) => {
        console.log(res2.data.data);
        setAllConfiguration(
          res2.data.data.filter((e) => e.learning_area == id)
        );
        latestData.current = res2.data.data.filter(
          (e) => e.learning_area == id
        );
        num++
        if (num == 2) {
          setLoader(false);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getAllActAgeSecSub() {
    let url = `${baseURL}home/getActAgeSecSub/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        setAllActAgeSecSub(res.data.data);
        console.log(res.data.data);
        num++
        if (num == 2) {
          setLoader(false);

        }
      })
      .catch((err) => console.log(err));
  }
  function submitConfigurations(e) {
    e.preventDefault();
    console.log(latestData.current);
    // return
    let arr = [...latestData.current];
    let num = 0;
    arr.forEach((res, index) => {
      let ind = arr.findIndex(
        (e) =>
          e.learning_area == res.learning_area &&
          e.section_name.trim().toLowerCase() ==
          res.section_name.trim().toLowerCase() &&
          e.assesment_name.trim().toLowerCase() ==
          res.assesment_name.trim().toLowerCase()
      );
      if (ind !== index) {
        num++;
        arr[ind].isDuplicate = true;
        arr[index].isDuplicate = true;
        let item = document.getElementById(`scrollbox${index}`);
        item.scrollIntoView({ behavior: "smooth", block: "start" });
      } else {
        arr[ind].isDuplicate = false;
        arr[index].isDuplicate = false;
      }
    });
    // setAllConfiguration(arr);

    if (num == 0) {
      // console.log("data submitted");

      let num2 = 0;
      allConfiguration.forEach((rs, ind) => {
        if (rs.assesment_name.trim() == "") {
          let item = document.getElementById(`scrollbox${ind}`);
          item.scrollIntoView({ behavior: "smooth", block: "start" });
          num2++;
        }
      });
      if (num2 == 0) {
        setLoader(true);

        let arr = [...latestData.current];
        // console.log(arr)
        let ids = arr.map((e) => e._id);
        let obj1 = arr.map((e) => {
          delete e._id;
          delete e.updated_at;
          delete e.__v;
          delete e.created_at;
          return e;
        });

        let obj = { ids, data: obj1 };
        console.log(obj);

        let url = `${baseURL}assesment/updateMany`;
        axios
          .put(url, obj)
          .then((res) => {
            console.log(res);
            navigate("/configuration");
            snackBarState.setState(true);
            setLoader(false);
          })
          .catch((err) => console.log(err));
      } else {
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Either assessment or section name is missing",
          type: "error",
        });
      }

      // let url = `${baseURL}assesment/get/allAssesments/${tenant_id}`;
      //     axios
      //       .get(url)
      //       .then((res2) => {

      //         // console.log(res2.data.data);
      //         fun(res2.data.data)
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //       });
      // function fun(TopArray){

      // arr.forEach((res)=>{
      // TopArray.forEach((res2)=>{
      //   if(res.learning_area==res2.learning_area && res.section_name==res2.section_name && res.assesment_name==res2.assesment_name){

      //     if(res._id==res2._id){
      //       console.log('update need')
      //     }
      //   }
      //   if(res.learning_area==res2.learning_area && res.section_name==res2.section_name && res.assesment_name==res2.assesment_name){

      //     if(res._id!==res2._id){
      //       console.log('update need')
      //     }
      //   }
      //   if(res.learning_area!==res2.learning_area || res.section_name!==res2.section_name || res.assesment_name!==res2.assesment_name){
      // if(res._id==res2._id){
      // console.log('update need')
      // }
      //   }
      // })
      // })

      // arr.forEach((res)=>{

      // let ind=TopArray.findIndex(e=>e.section_name==res.section_name && e.assesment_name==res.assesment_name && e._id!==res._id)
      // if(ind!==-1){
      //   // console.log(res,'eroor error error')
      // }
      // else{
      //   console.log(res,'data submitted submitted')
      // }

      // let ind2=TopArray.findIndex(e=>(e.section_name!==res.section_name || e.assesment_name!==res.assesment_name) && e._id==res._id)
      // if(ind2!==-1){
      // console.log(res,'second submitted si')
      // }

      // })

      // }
    } else {
      ShowSnackbar({
        show: true,
        vertical: "top",
        horizontal: "right",
        msg: "Duplicate assessment are not allowed",
        type: "warning",
      });
      return;
    }
  }
  return (
    <Sidebar>
      <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

      <Typography align="center" variant="h4" my={2}>
        Edit All Assessments
      </Typography>
      {loader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <form>
          {allConfiguration.map((res, index) => {
            return (
              <Box mb={2}>
                <ConfigurationCommonComp
                  index={index}
                  allGoals={allGoals}
                  latestData={latestData}
                  allData={{
                    state: allConfiguration,
                    setState: setAllConfiguration,
                  }}
                  mode="allEdit"
                  functions={{ getAllMaterial: getAllActAgeSecSub, getAllGoals: getAllGoals }}
                  configData={res}
                  allMaterial={allActAgeSecSub}
                />
              </Box>
            );
          })}
          <Grid
            sx={{ display: "flex", justifyContent: "flex-end", mx: 5, my: 3 }}
          >
            <Button
              onClick={() => navigate("/configuration")}
              sx={{ mx: 2 }}
              className="cancel_btn"
            >
              Cancel
            </Button>
            <Button type="submit" onClick={submitConfigurations} className="btn_primary">
              Done
            </Button>
          </Grid>
        </form>
      )}
    </Sidebar>
  );
};
