import { Add, Edit, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {
  Button,
  Grid,
  InputAdornment,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import { ProfileLogoName } from "../../components/ProfileLogoName";


export const AllChildForRoutines = () => {
  let { id } = useParams();
  let [childData, setChildData] = useState([]);
  let [expand, setExpand] = useState(false);
  const [todayRecord, setTodayRecord] = useState([])

  let [all, setAll] = useState([]);

  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  useEffect(() => {
    getAllChildOfRoom();
  }, []);
  let navigate = useNavigate();

  function getAllChildOfRoom() {
    let url = `${baseURL}room/getRoom/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data[0].child_data);
        setChildData(res.data.data[0].child_data);
        setAll(res.data.data[0].child_data);
        let dat = new Date();
        let yr = dat.getFullYear();
        let mnt = dat.getMonth();
        let day = dat.getDate();
        let fullDate = `${yr}-${mnt + 1 < 10 ? "0" : ""}${mnt + 1}-${day < 10 ? "0" : ""
          }${day}`;
        console.log(fullDate)
        console.log(res.data.data[0].child_data)
        return
        res.data.data[0].child_data.child_records.forEach((res) => {
          if (fullDate == res.created_at) {
            console.log(res)
          }
          // setTodayRecord([...todayRecord,res])
        })

      })
      .catch((err) => console.log(err));
  }

  function onSearch(e) {
    let { value } = e.target;
    setChildData(
      all.filter((r) =>
        r.child_name.toLowerCase().includes(value.toLowerCase().trim())
      )
    );
  }

  function openDropDown(index) {
    childData[index].key = !childData[index].key
    setExpand(!expand)
  }

  function DataGrid({ other }) {
    console.log(other);
    // return
    let [expand, setExpand] = useState(false);
    return (
      <>
        <Box sx={{ display: "flex", justifyContent: "", py: 2 }}>
          <Grid item>
            <Edit sx={{ color: "brown" }} />
          </Grid>
          <Grid item>
            <Add sx={{ color: "red" }} />
          </Grid>
          <Grid item>
            {expand ? (
              <ExpandLess
                onClick={() => setExpand(!expand)}
                sx={{ color: "orange", cursor: "pointer" }}
              />
            ) : (
              <ExpandMore
                onClick={() => setExpand(!expand)}
                sx={{ color: "orange", cursor: "pointer" }}
              />
            )}
          </Grid>
        </Box>
        <br />
        {expand ? (
          <Box className="pb-3 d-flex flex-column-reverse" align="center">
            {other.map((res2, index) =>
              index !== other.length - 1 ? (
                <Box>
                  <Grid sx={{ color: "#5B940E" }} key={index}>
                    {res2.record_name}
                  </Grid>
                  <Grid
                    variant="small"
                    sx={{ fontSize: "14px", color: "#9A9A9A", mb: 2 }}
                    key={index}
                  >
                    {res2.time} , {res2.educator_name}
                  </Grid>
                </Box>
              ) : null
            )}
          </Box>
        ) : null}
      </>
    );
  }

  return (
    <Sidebar>
      <Grid px={3}>
        <Link to="/routines">
          <ArrowBackIos />
        </Link>
      </Grid>
      <Grid container px={4}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid item md={5} xs={12} pt={3}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Search name"
              size="medium"
              onChange={onSearch}
              sx={{ backgroundColor: "white", width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item pt={3}>
            <Button
              onClick={() => navigate(`/addRecord/${id}`)}
              className="btn_primary"
            >
              Add Record
            </Button>
          </Grid>
        </Grid>

        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
          <Table mt={2}>
            <TableHead>
              <TableRow sx={{ height: "80px" }}>
                <TableCell sx={{ width: "100px" }}></TableCell>
                <TableCell>Child Name</TableCell>
                <TableCell>Records</TableCell>
                <TableCell sx={{ width: "200px" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            {childData.length !== 0
              ? childData.map((res, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        style={{ verticalAlign: "top", paddingRight: "10px" }}
                        align="right"
                      >
                        {/* <img
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        src={res.child_profileUrl ? res.child_profileUrl : blankImage}
                        alt="#"
                      /> */}
                        <ProfileLogoName
                          firstName={res.child_name}
                          lastName={res.child_lastname}
                        />
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {" "}
                        {res.child_name}
                      </TableCell>
                      <TableCell style={{ verticalAlign: "top" }}>
                        {res.child_records.length !== 0 ? (
                          <>
                            <span style={{ color: "#5B940E" }}>
                              {
                                res.child_records[res.child_records.length - 1]
                                  .record_name
                              }
                              <br />
                            </span>
                            <Typography
                              variant="small"
                              sx={{ fontSize: "14px", color: "#9A9A9A" }}
                            >
                              {res.child_records.length !== 0
                                ? res.child_records[
                                    res.child_records.length - 1
                                  ].time
                                : null}{" "}
                              ,{" "}
                              {res.child_records.length !== 0
                                ? res.child_records[
                                    res.child_records.length - 1
                                  ].educator_name
                                : null}
                            </Typography>
                          </>
                        ) : (
                          "---"
                        )}
                        {res.key ? (
                          <Box className="pt-3  d-flex flex-column-reverse">
                            {res.child_records.map((res2, index) =>
                              index !== res.child_records.length - 1 ? (
                                <Box>
                                  <Grid sx={{ color: "#5B940E" }} key={index}>
                                    {res2.record_name}
                                  </Grid>
                                  <Grid
                                    variant="small"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#9A9A9A",
                                      mb: 2,
                                    }}
                                    key={index}
                                  >
                                    {res2.time} , {res2.educator_name}
                                  </Grid>
                                </Box>
                              ) : null
                            )}
                          </Box>
                        ) : null}
                      </TableCell>
                      {/* <TableCell ><DataGrid other={res.child_records} /></TableCell> */}
                      <TableCell style={{ verticalAlign: "top" }}>
                        <Grid container>
                          <Grid item>
                            <Edit sx={{ color: "brown" }} />
                          </Grid>
                          <Grid item>
                            <Add sx={{ color: "red" }} />
                          </Grid>
                          <Grid item>
                            {res.key ? (
                              <ExpandLess
                                onClick={() => openDropDown(index)}
                                sx={{ color: "orange", cursor: "pointer" }}
                              />
                            ) : (
                              <ExpandMore
                                onClick={() => openDropDown(index)}
                                sx={{ color: "orange", cursor: "pointer" }}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))
              : "No Data Found"}
          </Table>
        </TableContainer>
        {/* <Grid
          container
          sx={{
            borderBottom: "1px solid #CCCCCC",
            py: 4,
            px: 2,
            mt: 5,
            bgcolor: "white",
          }}
        >
          <Grid item md={5}>
            Today,Monday 10 Jan
          </Grid>
          <Grid item md={4}>
            Set Regular Days
          </Grid>
          <Grid item md={1}></Grid>
          <Grid item md={2}>
            Show all
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          {childData.map((res, index) => {
            return (
              <Box
                key={index}
                sx={{
                  borderBottom: "1px solid #CCCCCC",
                  pt: 2,

                  bgcolor: "white",
                }}
                className="addRecord_table_grid"
              >
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Grid item>
                {res.child_profileUrl!==undefined?
                    <img
                      style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                      }}
                      src={res.child_profileUrl?res.child_profileUrl:blankImage}
                      alt="#"
                    />:
                    <img
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                    src={blankImage}
                    alt="#"/>
          }
                  </Grid>
                  <Grid item mx={3}>
                    {res.child_name}
                  </Grid>
                </Box>

                <Box item align="center">
                  {res.child_records.length !== 0 ? (
                    <span style={{ color: "#5B940E" }}>
                      {
                        res.child_records[res.child_records.length - 1]
                          .record_name
                      }
                      <br />
                    </span>
                  ) : null}

                  <Typography
                    variant="small"
                    sx={{ fontSize: "14px", color: "#9A9A9A" }}
                  >
                    {res.child_records.length !== 0
                      ? res.child_records[res.child_records.length - 1].time
                      : null}{" "}
                    ,{" "}
                    {res.child_records.length !== 0
                      ? res.child_records[res.child_records.length - 1]
                          .educator_name
                      : null}
                  </Typography>
                </Box>
                <DataGrid other={res.child_records} />
              </Box>
            );
          })}
        </Box> */}
      </Grid>
    </Sidebar>
  );
};
