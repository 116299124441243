import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Apis } from '../../auth/GlobalUrl'

export const GetEducatorLoginVisiblityTime = createAsyncThunk('GetEducatorLoginVisiblityTime', async (data) => {
    let id = localStorage.getItem('tenant_id')
    if (id !== null) {
        const res = await axios.get(Apis.EDUCATOR.getLoginVisiblityTime + id)
        return res.data.data
    }
})

export const educatorLoginVisiblityTimeSlice = createSlice({
    name: 'educatorLoginActivityTime',
    initialState: 5000,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(GetEducatorLoginVisiblityTime.fulfilled, (state, action) => {
            localStorage.setItem("educatorLoginTime", action.payload.login_activity_time * 60000)
            return action.payload.login_activity_time * 60000
        })
    }
})
