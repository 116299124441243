import { Backdrop, Card, CircularProgress, Collapse, Dialog, DialogTitle, Grid, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Sidebar } from "../../../components/Sidebar"
import { Link, useNavigate } from "react-router-dom";
import { AddCircle, Delete, Edit, RemoveRedEye } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { SnackBar } from "../../SnackBar";
import axios from "axios";
import { Apis } from "../../../auth/GlobalUrl";
import { DeleteCenter } from "./DeleteCenter";

export const ChildcareListing = () => {
    const [loader, setLoader] = useState(true)

    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "error",
    });
    let [allCenters, setAllCenters] = useState([])

    async function getAllCenters() {
        try {
            let res = await axios.get(Apis.SUPERADMIN.getAllChildCareCenters)
            console.log(res.data.data)
            setLoader(false)
            setAllCenters(res.data.data)
        }
        catch (err) {
            setLoader(false)
            console.log(err)
        }

    }


    useEffect(() => {
        getAllCenters()
    }, [])

    return (

        <Sidebar>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className="mx-4">

                <div className="d-flex align-items-center">
                    <div className="w-100">
                        <Typography variant="h4" align="center">
                            Center Listing
                        </Typography>
                    </div>
                    <div>
                        <Link to="/AddCenter">
                            <AddCircle color="info" />
                        </Link>
                    </div>
                </div>
                <Card
                    className="mt-4"
                >
                    <Table sx={{ width: "100%", background: "white" }}>
                        <TableHead sx={{ width: "100%" }}>
                            <TableRow sx={{ height: '60px' }}>

                                <TableCell>
                                    <b>Name</b>
                                </TableCell>
                                <TableCell>
                                    <b>Address</b>
                                </TableCell>

                                <TableCell>
                                    <b>Action</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allCenters.map((res, key) => {
                                return (
                                    <TableRow key={key} sx={{ width: "100%", height: '50px' }}>
                                        <TableCell>{res.child_care_center_name}</TableCell>
                                        <TableCell>
                                            {res.center_address}
                                        </TableCell>

                                        <TableCell>

                                            <Link to={`/EditCenter/${res._id}`}>
                                                <Edit
                                                    sx={{ color: "#169AC9", cursor: "pointer" }}
                                                />
                                            </Link>
                                            <DeleteCenter getAll={getAllCenters} setLoader={setLoader} res={res} />
                                        </TableCell>
                                    </TableRow>

                                )
                            })}

                        </TableBody>
                    </Table>
                    <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
                </Card>
                {allCenters.length == 0 ?
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingTop: "150px",
                        }}
                    >
                        <img src="images/noData.png" alt="" style={{ width: "100px" }} />
                    </div>
                    : ''}
            </div>
        </Sidebar >

    )
}