import { Delete } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

export const DeleteAssessment = ({ id, deleteAssesment, index }) => {
    let [dialog, setDialog] = useState(false)

    function deleteAssesment2() {
        deleteAssesment(id, index)
        setDialog(false)
    }

    return (
        <>
            <Delete
                sx={{ cursor: "pointer", color: "#169AC9" }}
                onClick={() => { setDialog(true) }} />

            <Dialog open={dialog}>
                <DialogTitle id="alert-dialog-title">
                    Confirm Delete
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure You Want To Delete Assessment?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialog(false)}
                        sx={{ color: "#3FAEED" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ color: "red" }}
                        onClick={deleteAssesment2}>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}