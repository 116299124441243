import React, { useContext } from "react";
import {
  Grid,
  Button,
  TextField,
  Card,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import { useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { contextData } from "../../Router";
import { SnackBar } from "../SnackBar";

export default function AddParent() {
  var navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  var [userEmail, setUserEmail] = useState("");
  var [userPassword, setUserPassword] = useState("");
  var [father, setfather] = useState("");
  var [mother, setmother] = useState("");
  var [parentNo, setparentNo] = useState("");
  var [parentAlt, setparentAlt] = useState("");
  let [father_lastname, setFather_lastname] = useState("")
  var [childs, setchilds] = useState("");
  var [parentEmail, setparentEmail] = useState("");
  var [fatherOcc, setfatherOcc] = useState("");
  var [motherOcc, setmotherOcc] = useState("");
  var [workAdd, setworkAdd] = useState("");
  var [homeAdd, sethomeAdd] = useState("");
  let [loader, setLoader] = useState(false);
  let snackbarContext = useContext(contextData);
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const postData = (e, nextPage) => {
    e.preventDefault();
    setLoader(true)
    let data = {
      father_name: father.trim(),
      mother_name: mother.trim(),
      parent_phone_number: parentNo,
      parent_alternative_phone_number: parentAlt,
      number_of_child: childs,
      parent_email: parentEmail,
      father_lastname,
      father_occupation: fatherOcc,
      mother_occupation: motherOcc,
      parent_work_address: workAdd,
      parent_home_address: homeAdd,
      tenant_id: localStorage.getItem("tenant_id"),
      login_credentials: {
        parent_password: "",
        parent_login_email: "",
      },
    };
    console.log(data)


    axios
      .post(`${baseURL}parent/addparent`, data)
      .then((res) => {
        setLoader(false)
        if (res.data.data._id == undefined) {
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: res.data.data,
            type: "warning",
          });
        } else {
          snackbarContext.setState(true);
          nextPage == 'addChild' ?
            navigate(`/addchild/${res.data.data._id}`) :
            navigate(`/parentdetails`)

        }
      })
      .catch((err) => {
        if (err.response !== undefined) {
          setLoader(false)
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage.details[0].message,
            type: "warning",
          });
        }
      });
  };

  return (
    <>
      <Sidebar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

        <Grid px={3}>
          <Link to="/parentdetails">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Grid container px={4} pb={2}>
          <Grid container className="d-flex justify-content-center">
            <Typography sx={{ textAlign: "center" }} py={2} variant="h5">
              Add Parent
            </Typography>
            <Grid item md={11} sm={11} xs={11}>
              {/* <Card
                variant="outlined"
                className="w-100"
                px={2}
                sx={{ background: "#FFFFFF", boxShadow: "0 0 5px 0 #888888" }}
              > */}
              <div className="row m-0 bg-white p-md-5 p-3">

                <div className="col-md-6 col-12 p-2">
                  <Typography>Father First Name</Typography>
                  <TextField
                    required
                    id="outlined-basic"
                    variant="outlined"
                    name="father_name"
                    placeholder="Father First Name"
                    onChange={(e) => setfather(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>

                <div className="col-md-6 col-12 p-2">
                  <Typography>Father Last Name</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="mother_name"
                    placeholder="Father Last Name"
                    onChange={(e) => setFather_lastname(e.target.value)}
                    Typography="DD/MM/YY"
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>


                <div className="col-md-6 col-12 p-2">
                  <Typography>Mother Name</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="mother_name"
                    placeholder="Enter Mother Name"
                    // onChange={getData}
                    onChange={(e) => setmother(e.target.value)}
                    Typography="DD/MM/YY"
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>
                <div className="col-md-6 col-12 p-2">
                  <Typography>Alternative Number</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    required
                    type="number"
                    name="parent_alternative_phone_number"
                    placeholder="Enter Number"
                    // onChange={getData}
                    onChange={(e) => setparentAlt(e.target.value)}
                    Typography="DD/MM/YY"
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>


                <div className="col-md-6 col-12 p-2">
                  <Typography>Number of Child</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    required
                    type="number"
                    name="number_of_child"
                    placeholder="Enter child Number"

                    onChange={(e) => setchilds(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>
                <div className="col-md-6 col-12 p-2">
                  <Typography>Email</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    autoComplete="off"
                    required
                    name="parent_email"
                    type="email"
                    placeholder="Enter Email"
                    onChange={(e) => setparentEmail(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>

                <div className="col-md-6 col-12 p-2">
                  <Typography>Father's Occupation</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="father_occupation"
                    placeholder="Enter Occupation"
                    // onChange={getData}
                    onChange={(e) => setfatherOcc(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>

                <div className="col-md-6 col-12 p-2">
                  <Typography>Mother's Occupation</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="mother_occupation"
                    placeholder="Enter Occupation"
                    // onChange={getData}
                    onChange={(e) => setmotherOcc(e.target.value)}
                    Typography="DD/MM/YY"
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>

                <div className="col-md-6 col-12 p-2">
                  <Typography>Work Address</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="parent_work_address"
                    placeholder="Enter Work Address"
                    onChange={(e) => setworkAdd(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>
                <div className="col-md-6 col-12 p-2">
                  <Typography>Home Address</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    name="parent_home_address"
                    required
                    placeholder="Enter Home Address"
                    onChange={(e) => sethomeAdd(e.target.value)}
                    Typography="DD/MM/YY"
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>

                <div className="col-md-6 col-12 p-2">
                  <Typography>Phone Number</Typography>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    required
                    name="parent_phone_number"
                    type="number"
                    placeholder="Enter Number"
                    onChange={(e) => setparentNo(e.target.value)}
                    className="w-100"
                    sx={{ background: "#ffffff" }}
                  />
                </div>



              </div>

              {/* </Card> */}





            </Grid >

            <Grid container px={6}>
              <Grid
                item
                mt={6}
                md={12}
                sm={12}
                xs={12}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  variant="other"
                  sx={{ border: "1px solid #FF0000", color: "red" }}
                  onClick={() => {
                    // setdata({});
                    navigate("/parentdetails");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  onClick={(e) => postData(e, "submit")}
                >
                  Submit
                </Button>
                <Button
                  variant="other"
                  sx={{
                    background: "#1799CD",
                    color: "#ffffff",
                    border: "1px solid #1799CD",
                    marginLeft: "10px",
                  }}
                  onClick={(e) => postData(e, "addChild")}
                >
                  Add child
                </Button>
              </Grid>
            </Grid>
          </Grid >
        </Grid >
      </Sidebar >
    </>
  );
}
