import { Grid, MenuItem, TextField, InputLabel, FormControl, Select, FormControlLabel, Checkbox, Card, Button, InputAdornment, Dialog, Typography } from '@mui/material'
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Sidebar } from "../../components/Sidebar";
export default function Illness() {

    var [popup, setpopup] = useState(false)
    var [graph, setgraph] = useState(false)

    return (
        <>
            <Sidebar>
                <Grid m={3}>
                    <Grid container px={4} py={5} sx={{ backgroundColor: 'white', border: '2px solid #bdbdbd', borderRadius: 2 }}>
                        <form>
                            <Grid container my={2}>
                                <Grid item md={6} >
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5}>
                                            <Typography className=''>Name of ill person</Typography>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} px={2} className=''>
                                            <Typography>Date of Birth</Typography>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField id="outlined-basic" variant="outlined" label='DD/MM/YY' className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container my={2}>
                                <Grid item md={6}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} >
                                            <Typography className=''>Child's room</Typography>
                                        </Grid>
                                        <Grid item md={7}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} px={2} className=''>
                                            <Typography>Date of Birth</Typography>
                                        </Grid>
                                        <Grid item md={7}>
                                            <Grid container sx={{display:"flex",alignItems:'center'}}>
                                                <Grid md={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="11"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid md={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="00"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid md={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="AM"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem >AM</MenuItem>
                                                            <MenuItem >PM</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item md={6} >
                                        <Grid container sx={{display:"flex",alignItems:'center'}}>
                                            <Grid item md={6} >
                                                <Typography>Time illness commenced</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container>
                                                    <Grid item md={6}>
                                                        <FormControlLabel control={<Checkbox color='info' />} label="AM" />
                                                    </Grid>
                                                    <Grid item md={6}>
                                                        <FormControlLabel control={<Checkbox color='info' />} label="PM" />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={6}>
                                        <Grid container>
                                            <Grid item md={3}>
                                                <FormControlLabel control={<Checkbox color='info' />} label="Enrolled Child" />
                                            </Grid>
                                            <Grid item md={3}>
                                                <FormControlLabel control={<Checkbox color='info' />} label="Team Member" />
                                            </Grid>
                                            <Grid item  md={3}>
                                                <FormControlLabel control={<Checkbox color='info' />} label="Parent" />
                                            </Grid>
                                            <Grid item md={3}>
                                                <FormControlLabel control={<Checkbox color='info' />} label="Volunteer" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container sx={{display:"flex",alignItems:'center'}}>
                                <Grid item md={5} sm={12} xs={12} mb={2}>
                                    <div style={{ color: '#0094FF', fontSize: '24px' }}>
                                        Detail of illness
                                    </div>
                                    <Grid container >
                                        <Grid item md={10} >
                                            <div className="d-flex pt-4 justify-content-between">
                                                <Typography>Rash or other skin condition</Typography><Checkbox color='info' />
                                            </div>
                                            <div className="d-flex pt-2 justify-content-between">
                                                <Typography>Discharge from eyes</Typography><Checkbox color='info' />
                                            </div>
                                            <div className="d-flex pt-2 justify-content-between">
                                                <Typography>Vomiting</Typography><Checkbox color='info' />
                                            </div><div className="d-flex pt-2 justify-content-between">
                                                <Typography>Diarrhoea</Typography><Checkbox color='info' />
                                            </div><div className="d-flex pt-2 justify-content-between">
                                                <Typography>Temperature - 38 degrees or over</Typography><Checkbox color='info' />
                                            </div><div className="d-flex pt-2 justify-content-between">
                                                <Typography>Reaction to a known allergen</Typography><Checkbox color='info' />
                                            </div><div className="d-flex pt-2 justify-content-between">
                                                <Typography>Other ( please detal alongside )</Typography><Checkbox color='info' />
                                            </div>

                                            <Button onClick={() => setpopup(true)} sx={{ background: '#3FAEED', color: '#ffffff', padding: '10px 24px 11px 25px', marginTop: '103px' }} >Add Medication record</Button>
                                            <Dialog
                                                open={popup}

                                            >
                                                <Grid container className='d-flex justify-content-center'>
                                                    <Grid item md={12} sm={12} xs={12}>
                                                        <form>
                                                            <Card variant="outlined" className='w-100' sx={{ background: '#FCFDFF' }}>
                                                                <Grid container py={3} px={3}>
                                                                    <Grid item md={12} xs={12} className='d-flex justify-content-end'><Button onClick={() => setpopup(false)}><CloseIcon /></Button></Grid>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12}><Typography>Dosage<br /> administration</Typography></Grid>
                                                                    <Grid item lg={6} md={6} sm={6} xs={12}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12} mt={3}><Typography>Medication administered</Typography></Grid>
                                                                    <Grid item lg={8} md={8} sm={8} xs={12} mt={3}>
                                                                        <Grid container>
                                                                            <Grid item lg={5} md={5} sm={5} xs={6}>
                                                                                <TextField
                                                                                    id="date"
                                                                                    type="date"
                                                                                    defaultValue="2022-05-24"
                                                                                    sx={{ width: '100%' }}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                />
                                                                            </Grid>
                                                                            <Grid item lg={7} md={7} sm={7} xs={6}>
                                                                                <Grid container>
                                                                                    <Grid md={4}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-label">11</InputLabel>
                                                                                            <Select
                                                                                                sx={{ background: '#ffffff' }}
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                // value={age}
                                                                                                label="11"
                                                                                            // onChange={handleChange}
                                                                                            >
                                                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                    <Grid md={4}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-label">00</InputLabel>
                                                                                            <Select
                                                                                                sx={{ background: '#ffffff' }}
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                // value={age}
                                                                                                label="00"
                                                                                            // onChange={handleChange}
                                                                                            >
                                                                                                <MenuItem value={10}>Ten</MenuItem>
                                                                                                <MenuItem value={20}>Twenty</MenuItem>
                                                                                                <MenuItem value={30}>Thirty</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                    <Grid md={4}>
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-label">AM</InputLabel>
                                                                                            <Select
                                                                                                sx={{ background: '#ffffff' }}
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                // value={age}
                                                                                                label="AM"
                                                                                            // onChange={handleChange}
                                                                                            >
                                                                                                <MenuItem >AM</MenuItem>
                                                                                                <MenuItem >PM</MenuItem>
                                                                                            </Select>
                                                                                        </FormControl>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12} mt={3}><Typography>Method of administration</Typography></Grid>
                                                                    <Grid item lg={6} md={6} sm={6} xs={12} mt={3}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12} mt={3}><Typography>Name of witness</Typography></Grid>
                                                                    <Grid item lg={6} md={6} sm={6} xs={12} mt={3}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={4} md={4} sm={4} xs={12} mt={3}><Typography>Name of educator <br />administering</Typography></Grid>
                                                                    <Grid item lg={6} md={6} sm={6} xs={12} mt={3}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}><Typography>Signature of witness:</Typography></Grid>
                                                                    <Grid item lg={9} md={9} sm={9} xs={12} mt={3}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3}><Typography>Signature of educator administering:</Typography></Grid>
                                                                    <Grid item lg={9} md={9} sm={9} xs={12} mt={3}><TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} /></Grid>
                                                                    <Grid item lg={12} md={12} sm={12} xs={12} mt={3} className="d-flex justify-content-center"><Button type='submit' sx={{ background: '#3FAEED', color: '#ffffff', padding: '10px 44px 11px 44px', boxShadow: '4px 4px 30px rgba(169, 255, 129, 0.2)', borderRadius: '4px' }}>Submit</Button></Grid>

                                                                </Grid>
                                                            </Card>
                                                        </form>

                                                    </Grid>
                                                </Grid>

                                            </Dialog>

                                        </Grid>
                                        <Grid item md={4}></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={7} sm={12} xs={12}>
                                    <Card variant="outlined" sx={{ background: '#E4E4E4' }}>
                                        <Grid container mx={5} my={3}>
                                            <Grid item md={4}>
                                                <TextField
                                                    size='medium'
                                                    sx={{ background: '#ffffff',width:'170px' }}
                                                    id="input-with-icon-textfield"
                                                    value='08:59 PM'
                                                    
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccessTimeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item md={2}></Grid>
                                            <Grid item md={4}>
                                                <TextField
                                                    sx={{ background: '#ffffff',width:'170px' }}
                                                    id="input-with-icon-textfield"
                                                    value='08:59 PM'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccessTimeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <hr />
                                        <Grid container mx={2} my={3}>
                                            <Grid item md={3} sm={6} xs={6}><Typography>Initial temperature</Typography></Grid>
                                            <Grid item md={3} mr={4} sm={4} xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <Select
                                                        sx={{ background: '#ffffff' }}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                    // value={age}

                                                    // onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={4} sm={12} xs={12}>
                                                <TextField
                                                    sx={{ background: '#ffffff' }}
                                                    id="input-with-icon-textfield"
                                                    value='08:59 PM'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccessTimeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container mx={2} my={3}>
                                            <Grid item md={3} sm={6} xs={6}><Typography>Follow up temperature</Typography></Grid>
                                            <Grid item md={3} sm={4} xs={4} mr={4
                                            }>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                                    <Select
                                                        sx={{ background: '#ffffff' }}
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                    // value={age}

                                                    // onChange={handleChange}
                                                    >
                                                        <MenuItem value={10}>Ten</MenuItem>
                                                        <MenuItem value={20}>Twenty</MenuItem>
                                                        <MenuItem value={30}>Thirty</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={4}>
                                                <TextField
                                                    sx={{ background: '#ffffff' }}
                                                    id="input-with-icon-textfield"
                                                    value='08:59 PM'
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AccessTimeIcon />
                                                            </InputAdornment>
                                                        ),
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <EditIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container mx={2} my={3}>
                                            <Grid item md={12}>
                                                <Typography>Provide details of actions taken to lower the child's temperature</Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container mx={2} my={3}>
                                            <Grid item md={11} sm={11} xs={11}>
                                                <TextField
                                                    sx={{ background: '#f5f5f5' }}
                                                    placeholder="Details..."
                                                    multiline
                                                    className='w-100'
                                                    rows={8}
                                                    col
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container mx={2} my={3} className='d-flex justify-content-end'>
                                            <Grid item md={6} sm={5} xs={5}>
                                                <Button onClick={() => setgraph(true)} sx={{ color: '#909090' }}>View Temperature History </Button>
                                            </Grid>
                                            <Grid item md={6} sm={4} xs={4}>
                                                <Button sx={{ background: '#3FAEED', color: '#ffffff', padding: '10px 44px 11px 44px' }}>Submit</Button>
                                            </Grid>
                                            <Dialog
                                                open={graph}
                                            >

                                                <Card variant="outlined" className='w-100' sx={{ background: '#FCFDFF' }}>
                                                    <Grid container py={3} px={3}>
                                                        <Grid item md={12} xs={12} className='d-flex justify-content-end'><Button onClick={() => setgraph(false)}><CloseIcon /></Button></Grid>
                                                    </Grid>
                                                    <Grid item md={12} xs={12}>
                                                        For Showing Graph
                                                    </Grid>
                                                </Card>


                                            </Dialog>
                                        </Grid>

                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container mx={2} mt={3}>
                                <Grid item md={12}>
                                    <Typography>Describe the symptoms of the illness and the circumstances under which the illness or condition was observed</Typography>
                                </Grid>
                            </Grid>
                            <Grid container mx={2} my={3}>
                                <Grid item md={12} sm={12} xs={12}>
                                    <TextField
                                        placeholder="Details..."
                                        multiline
                                        className='w-100'
                                        rows={8}
                                        col
                                        sx={{ background: '#f5f5f5' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container mx={2} mt={3} sx={{display:"flex",alignItems:'center'}}>
                                <Grid item md={8}>
                                    <Typography>Did the illness require the parent / guardian to be contacted immediately?</Typography>
                                </Grid>
                                <Grid item md={4} className="d-flex justify-content-between">
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel value="Yes" control={<Radio color='info' />} label="Yes" />
                                            <FormControlLabel value="No" control={<Radio color='info' />} label="No" />

                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item md={4} sm={12} xs={12}>
                                    <Grid container my={2}>
                                        <Grid item md={7} >
                                            <Typography className=''>If yes, who contacted the person / guardian?</Typography>
                                        </Grid>
                                        <Grid item md={5}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff',width:'190px' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container my={2}>
                                        <Grid item md={4} px={2} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography>Date Signed</Typography>
                                        </Grid>
                                        <Grid item md={8} sm={6} xs={6}>
                                            <Grid container > 
                                                <Grid md={4} >
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="11"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid md={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="00"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem value={10}>Ten</MenuItem>
                                                            <MenuItem value={20}>Twenty</MenuItem>
                                                            <MenuItem value={30}>Thirty</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid md={4}>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Age</InputLabel>
                                                        <Select
                                                            sx={{ background: '#ffffff' }}
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            // value={age}
                                                            label="AM"
                                                        // onChange={handleChange}
                                                        >
                                                            <MenuItem >AM</MenuItem>
                                                            <MenuItem >PM</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container mx={2} mt={3}>
                                <Grid item md={11} className='d-flex justify-content-center' sx={{ color: '#3FAEED', fontFamily: 'Roboto', fontWeight: '700', fontSize: '22px' }}>
                                    <Typography>Team member completing report: ( Must be a lead educator or a acting lead educator )</Typography>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6}>
                                            <Typography className=''>Name</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography className=''>Signature</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6} px={2} className='d-flex justify-content-center'>
                                            <Typography>Date Signed</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" label='DD/MM/YY' className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container  >
                                <Grid item md={12} >
                                    <Card variant="outlined" className='w-100 p-4' sx={{ background: '#f5f5f5',height:'200px' }}>
                                        <Grid container px={2}>
                                            <Grid item md={8}>
                                                <p><b className='text-center text-dark'>Important Note: </b><span> <em style={{color:'gray'}}>A clearance will be required before your child will be able to return to the centre</em></span></p>
                                                </Grid>
                                            <Grid item md={4} className='justify-content-between'>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio color='info'/>} label="Yes" />
                                                        <FormControlLabel value="No" control={<Radio color='info'/>} label="No" />

                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={12} mt={2} className='text-center'> <p><Typography sx={{color:'gray'}}><em>A copy of Treating Doctor’s Report Form will be provided for you, please take this form to your doctor with you. This form must be completed and returned to the centre before your child can return to care</em></Typography></p></Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item md={12}>
                                    <b>Parent / Guardian Signature:</b>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6}>
                                            <Typography className=''>Name</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography className=''>Signature</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={4}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} px={2} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography>Date Signed</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" label='DD/MM/YY' className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container my={2}>
                                <Grid item md={12}>
                                    <b>Report Sited By Centre Director:</b>
                                </Grid>
                            </Grid>
                            <Grid container my={2}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6}>
                                            <Typography className=''>Name</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography className=''>Signature</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} sm={6} xs={6}>
                                            <Typography>Position</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <Grid container my={2} sx={{display:"flex",alignItems:'center'}}>
                                        <Grid item md={5} px={2} sm={6} xs={6} className='d-flex justify-content-center'>
                                            <Typography>Date Signed</Typography>
                                        </Grid>
                                        <Grid item md={7} sm={6} xs={6}>
                                            <TextField id="outlined-basic" variant="outlined" className='w-100' sx={{ background: '#ffffff' }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container className='d-flex justify-content-center'>
                                <Grid item md={10} sx={{ color: 'gray' }}>
                                    <p ><b className='text-center text-dark'>Warning: </b>This form must be sited and signed by the centre director before notifying the parent / guardian. Reports must be completed and signed by all parties the same day the illness occured</p>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item md={10}>
                                    <b>Notification Required:</b>
                                </Grid>
                            </Grid>

                            <Grid container columnSpacing={1} mt={1} sx={{display:"flex",alignItems:'center'}}>
                                <Grid item md={3}>
                                    <Typography>Managing Agent</Typography>
                                </Grid>
                                <Grid item>
                                <Checkbox color='info' />
                                </Grid>
                                <Grid item>
                                    <Typography>Approved Provider</Typography>
                                </Grid>
                                <Grid item>
                                <Checkbox color='info' />
                                </Grid>
                                <Grid item>
                                    <Typography>Public Health</Typography>
                                </Grid>
                                <Grid item>
                                <Checkbox color='info' />
                                </Grid>
                                <Grid item>
                                    <Typography>WPH & S</Typography>
                                </Grid>
                                <Grid item>
                                <Checkbox color='info' />
                                </Grid>
                            </Grid>
                

                            <Grid container mt={2}  ml={2} className='d-flex justify-content-end'>
                                <Grid item md={3}>
                                    <Button sx={{ mx: 2 }} className="cancel_btn">Save</Button>
                                    <Button className="btn_primary">Submit</Button>
                                </Grid>
                            </Grid>

                        </form>
                    </Grid>
                </Grid>
            </Sidebar>
        </>
    )
}
