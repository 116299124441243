import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Apis } from "../../auth/GlobalUrl"
import { useNavigate } from "react-router-dom"


export const childCareCentersApis = createAsyncThunk('careCenters', async (data, thunkApi) => {
    try {
        let id = localStorage.getItem("user_id")
        let res = await axios.get(Apis.SUPERADMIN.getAllCentersByAdminid + id)
        console.log(res.data)
        if (res.data.data.length !== undefined) {
            return res.data.data
        }
        else {
            return ([])
        }
    }
    catch (err) {
        console.log(err)
    }
})

export const ChildCareCentersSlice = createSlice({
    name: 'Childcare Centers',
    initialState:
    {
        allcenters: [],
        currentCenter: {}
    },
    reducers: {
        changeCenter: (state, action) => {
            return ({ ...state, currentCenter: action.payload })
        },
        clearCenters: (state) => {
            return ({ allcenters: [], currentCenter: {} })

        }
    },
    extraReducers: (builder) => {
        builder.addCase(childCareCentersApis.fulfilled, (state, action) => {

            if (action.payload[0].center_data.length == 0) {
                return (
                    { allcenters: [], currentCenter: {} }
                )
            }

            let localCare = localStorage.getItem("ChildcareCenterData")
            if (localCare == null) {
                localStorage.setItem("ChildcareCenterData", JSON.stringify(action.payload[0].center_data[0]))
                localStorage.setItem("logo_url", action.payload[0].center_data[0].logo_url)
                localStorage.setItem("tenant_id", JSON.stringify(action.payload[0].center_data[0].tenant_id))

            }
            else {
                let oldData = { ...JSON.parse(localCare) }
                let index = action.payload[0].center_data.findIndex(e => e.tenant_id == oldData.tenant_id)
                if (index == -1) {
                    localStorage.setItem("logo_url", action.payload[0].center_data[0].logo_url)
                    localStorage.setItem("ChildcareCenterData", JSON.stringify(action.payload[0].center_data[0]))
                    localStorage.setItem("tenant_id", JSON.stringify(action.payload[0].center_data[0].tenant_id))
                }
            }

            return (
                {
                    allcenters: action.payload[0].center_data,
                    currentCenter: JSON.parse(localStorage.getItem("ChildcareCenterData"))
                }
            )
        })
    }
})
export const { changeCenter, clearCenters } = ChildCareCentersSlice.actions