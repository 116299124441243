import { useRef } from "react"
import { ShowPastTermGoalCard } from "./ShowPastTermGoalCard"
import ReactToPrint from 'react-to-print'
import { Button } from "@mui/material"
export const PrintTermAndGoal = ({ res }) => {
    let componentRef = useRef()
    return (
        <>
            <ReactToPrint
                trigger={() => {

                    return (
                        <Button onClick={() => console.log('okok')} className="btn_primary">Print</Button>
                    )
                }}
                content={() => componentRef.current}
            />

            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <ShowPastTermGoalCard data={res} />
                </div>
            </div>
        </>
    )
}