import {
  Box,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ArrowBackIos, AssignmentReturn, Info } from "@mui/icons-material";
import { getLatestAge } from "../getLatestAge";
import { ProfileLogoName } from "../../components/ProfileLogoName";

export default function RoomView() {
  const [children, setchildren] = useState([]);
  const navigate = useNavigate();
  let [educators, setEducators] = useState([]);
  let [showChild, setShowChild] = useState(true);
  let [all, setAll] = useState([]);
  let [roomName, setRoomName] = useState();
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
  let { id } = useParams();

  const getData = () => {
    var url = `${baseURL}room/getRoom/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data[0]);
        setRoomName(res.data.data[0].room_name);
        // console.log(res.data.room_name, 'tuyguyguyi')
        setchildren(res.data.data[0].child_data);
        setEducators(res.data.data[0].teacher_data);
        setAll(res.data.data[0]);
        // setchildren(res.data.data)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearchCh = (e) => {
    if (e.target.value !== " ") {
      const filterTable = all.child_data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase().trim())
        )
      );
      setchildren([...filterTable]);
    } else {
      console.log('lll')
      setchildren(all.child_data);
    }
  };
  const onSearchEdu = (e) => {
    if (e.target.value !== " ") {
      const filterTable = all.teacher_data.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase().trim())
        )
      );
      setEducators([...filterTable]);
    } else {
      console.log(children)
      setEducators(all.teacher_data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  function navClick(e) {

    setchildren(all.child_data)
    setEducators(all.teacher_data)

    console.log(e.target.textContent);
    if (e.target.textContent == "All Educators") {
      setShowChild(false);
    } else {
      setShowChild(true);
    }

    let btn = document.getElementsByClassName("nav_item");
    for (let i = 0; i < btn.length; i++) {
      btn[i].style.color = "black";
      btn[i].style.border = "none";
    }
    e.target.style.color = "#fa981d";
    e.target.style.borderBottom = " 1px solid #fa981d";
  }

  return (
    <>
      <Sidebar>
        <Grid px={3}>
          <Link to="/rooms">
            <ArrowBackIos />
          </Link>
        </Grid>
        <Typography variant="h5" align='center' color='#90caf9'>{roomName}</Typography>
        <Box my={2} className='d-flex justify-content-around'>
          <Box item md={6} align="center">
            <span className="active nav_item" onClick={navClick}>
              All Childrens
            </span>
          </Box>
          <Box item md={6} align="center">
            <span className="nav_item" onClick={navClick}>
              All Educators
            </span>
          </Box>
        </Box>

        {showChild ? (
          <Box sx={{ mx: 2 }}>
            <Grid container>
              <Grid container py={2}>
                <Grid item md={6}>
                  <TextField onChange={onSearchCh} className='searchInput' placeholder='Search Child name' sx={{ bgcolor: 'white' }} />
                  {/* <input type="text" onChange={onSearchCh} placeholder='Search Child name' className="form-control py-3 w-75" /> */}
                </Grid>
              </Grid>
            </Grid>

            <Table sx={{ bgcolor: "white" }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">Child Name</TableCell>
                  <TableCell align="left">Age</TableCell>
                  <TableCell align="left">Configuration Age</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {children.map((row, index) => {
                  console.log(row);
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        <ProfileLogoName firstName={row.child_name} lastName={row.child_lastname} />
                      </TableCell>
                      <TableCell align="left">{row.child_name}</TableCell>
                      <TableCell align="left">{getLatestAge(row.child_dob)} years</TableCell>
                      <TableCell align="left">{row.child_age}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        ) : (
          <Box sx={{ mx: 2 }}>
            {/* educator table  */}
            <Grid container>
              <Grid container my={2}>
                <Grid item md={5}>
                  <input type="text" onChange={onSearchEdu} placeholder='Search Educator name' className="form-control py-3 searchInput" />
                  {/* <TextField sx={{border:'2px solid green'}} */}

                  {/* /> */}
                </Grid>
                <Grid item></Grid>
              </Grid>
            </Grid>

            <Table sx={{ bgcolor: "white" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">S.No</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">Educator Name</TableCell>
                  <TableCell align="left">Age</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {educators.map((row, index) => {
                  console.log(row);
                  return (
                    <TableRow key={index}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left">
                        <ProfileLogoName firstName={row.teacher_name} lastName={row.teacher_lastname} />
                      </TableCell>
                      <TableCell align="left">{row.teacher_name}</TableCell>
                      <TableCell align="left">{getLatestAge(row.teacher_dob)} years</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Box>
        )}
      </Sidebar>
    </>
  );
}
