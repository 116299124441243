import { Add } from "@mui/icons-material"
import { Button, CircularProgress, Container, Dialog, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { Apis } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";

export const AddGoal = ({ getAllGoals }) => {

    const [goalDialog, setGoalDialog] = useState(false)
    const [goalName, setGoalName] = useState('')
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "error",
    });


    const [loader, setLoader] = useState('')
    let tenant_id = localStorage.getItem('tenant_id')
    async function addGoal(e) {
        e.preventDefault()
        setLoader(true)
        try {
            console.log(goalName)
            let res = await axios.post(Apis.GOAL.add, { goal_name: goalName.toLowerCase().trim(), tenant_id });
            if (res.data.data.goal_name !== undefined) {
                console.log('data adddede')
                setGoalDialog(false)
                getAllGoals()
                ShowSnackbar({
                    show: true,
                    vertical: "top",
                    horizontal: "right",
                    msg: 'Goal Added Successfully',
                    type: "success",
                });
            }
            else {
                ShowSnackbar({
                    show: true,
                    vertical: "top",
                    horizontal: "right",
                    msg: res.data.data,
                    type: "warning",
                });
            }
            setLoader(false);
        }
        catch (err) {
            setLoader(false);
            console.log(err);
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: 'Some error occured while adding goal',
                type: "error",
            });
        }
    }


    return (
        <>
            <Add className="ms-2 fs-2 pointer" onClick={() => setGoalDialog(true)} />
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            <Dialog fullWidth={true} open={goalDialog}>
                <Typography variant="h4" align="center" sx={{ my: 3 }}>
                    Add Goal
                </Typography>
                <form onSubmit={addGoal}>
                    <Container>
                        <TextField
                            fullWidth
                            required
                            label="Sub Section Name"
                            sx={{ my: 3 }}
                            onChange={(e) => setGoalName(e.target.value)}
                        />
                    </Container>
                    <Container sx={{ my: 3 }} align="right">
                        <Button
                            sx={{ mx: 2 }}
                            onClick={() => {
                                setGoalDialog(false);
                                setGoalName("");
                                setLoader(false)
                            }}
                            className="cancel_btn"
                        >
                            Cancel
                        </Button>
                        {!loader ? (
                            <Button className="btn_primary" type="submit">
                                Add
                            </Button>
                        ) : (
                            <Button sx={{ height: '40px' }}>
                                <CircularProgress mt={3} />
                            </Button>
                        )}
                    </Container>
                </form>
            </Dialog>


        </>
    )
}