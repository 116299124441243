import React, { useEffect, useRef, useState } from "react";
import { Sidebar } from "../../components/Sidebar";
import { Box, Grid, Container, Typography, Backdrop, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CChart } from "@coreui/react-chartjs";
import dayjs from "dayjs";
import { ProfileLogoName } from "../../components/ProfileLogoName";

export const AllParentChild = () => {
  const { id } = useParams();
  localStorage.setItem("parentId", id);
  const [childData, setChildData] = useState([]);
  let dt = new Date();
  let yr = dt.getFullYear();
  let mn = dt.getMonth() + 1;
  let fullmonth = `${yr}-${mn}`;
  let refDate = useRef({ month: mn, year: yr });
  const tableColumns = useRef([])
  const [childIndex, setChildIndex] = useState(0);
  const allActivities = useRef([])

  const allColors = ['#4472C4', '#FFCC30', '#ED7D31', '#D1CFCE', '#FFCC30']

  const [value, setValue] = React.useState(dayjs(fullmonth));
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  let [open, setopen] = useState(true);
  const getData = () => {
    axios
      .get(`${baseURL}parent/getparent/${id}`)
      .then((res) => {
        console.log(res.data.data.Child_data);
        setChildData(res.data.data.Child_data);
        setopen(false);

        let dt = new Date();
        let yr = dt.getFullYear();
        let mn = dt.getMonth() + 1;
        let fullmonth = `${yr}-${mn}`;
        console.log(mn)
        showGraph(
          { month: mn, year: yr },
          res.data.data.Child_data[0].child_assessment
        );
      })
      .catch((err) => {
        console.log(err);
        setopen(false);
      });
  };

  useEffect(() => {
    getAllData()
  }, []);

  async function getAllData() {
    let tenant_id = localStorage.getItem('tenant_id')
    let res = await axios.get(`${baseURL}activity/get/allActivity/${tenant_id}`)
    console.log(res.data.data)
    allActivities.current = res.data.data.map(e => e.activity_name)
    getData();

  }


  async function showGraph(fun, arr) {
    console.log(allActivities)
    let newarr = [];

    for (let i = arr.length - 1; i >= 0; i--) {
      if (
        newarr.findIndex(
          (x) =>
            x.category == arr[i].category &&
            x.assessment_section_name == arr[i].assessment_section_name &&
            x.assessment_name == arr[i].assessment_name &&
            x.created_at.split("-")[1] * 1 ==
            arr[i].created_at.split("-")[1] * 1
        ) == -1
      ) {
        newarr.push(arr[i]);
      }
    }
    console.log(newarr)
    let obj = {};

    let datewise = [];
    newarr.forEach((res2) => {
      if (
        res2.created_at.split("-")[1] * 1 == fun.month &&
        res2.created_at.split("-")[0] * 1 == fun.year
      ) {
        datewise.push(res2);
      }
    });

    datewise.forEach((res) => {
      if (obj[`${res.category}${res.assessment_activity[0]}`] !== undefined) {
        obj[`${res.category}${res.assessment_activity[0]}`]++;
      } else {
        obj[`${res.category}${res.assessment_activity[0]}`] = 1;
      }
    });

    let arrlear = ["Practical", "Sensorial", "Language", "Maths", "Culture"];
    let arract = [...allActivities.current];

    let out = [];
    arract.forEach((res) => {
      let dd = [];
      arrlear.forEach((res2) => {
        dd.push(obj[`${res2}${res}`] !== undefined ? obj[`${res2}${res}`] : 0);
      });
      out.push(dd);
    });

    // console.log(out)
    tableColumns.current = [...out]
    console.log(tableColumns.current)

  }
  function navClick(e, ind) {
    console.log(ind);
    let all = document.getElementsByClassName("child_item");
    for (let i = 0; i < all.length; i++) {
      all[i].style.color = "black";
      all[i].style.borderBottom = "none";
    }
    e.target.style.color = "#3FAEED";
    e.target.style.borderBottom = "1px solid #3FAEED";
    setChildIndex(ind);
    showGraph(refDate.current, childData[ind].child_assessment);
  }

  return (
    <Sidebar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className="d-flex align-items-center">
        <Container sx={{ m: 2, display: "flex" }}>
          {childData.map((item, index) => (
            <Grid key={index} mr={3} sx={{ cursor: "pointer" }}>
              {/* <img
                style={{ width: "50px", height: "50px", borderRadius: "50px" }}
                src={item.child_profileUrl ? item.child_profileUrl : blankImage}
              /> */}
              <ProfileLogoName
                firstName={item.child_name}
                lastName={item.child_lastname}
              />
              <span style={{ paddingTop: "10px", textAlign: "center" }}>
                <Typography
                  onClick={(e) => navClick(e, index)}
                  className={index == 0 ? "child_item active1" : "child_item"}
                >
                  {item.child_name}
                </Typography>
              </span>
            </Grid>
          ))}
        </Container>

        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              defaultValue={value}
              views={["year", "month"]}
              onChange={(e) => {
                // setDate({ year: e.$y, month: e.$M + 1 });
                refDate.current = { year: e.$y, month: e.$M + 1 };
                showGraph(
                  { year: e.$y, month: e.$M + 1 },
                  childData[childIndex].child_assessment
                );
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>

      <Box align="center">
        <CChart
          style={{ width: "80%", height: "90vh" }}
          type="bar"
          data={{
            labels: ["Practical", "Sensorial", "Language", "Maths", "Culture"],
            datasets: tableColumns.current.map((e, index) => {
              return {
                label: allActivities.current[index],
                backgroundColor: allColors[index],
                data: e,
              };
            }),
            // [
            //   {
            //     label: "presented",
            //     backgroundColor: "#4472C4",
            //     data: Arr1,
            //   },
            //   {
            //     label: "assistance required",
            //     backgroundColor: "#ED7D31",
            //     data: Arr2,
            //   },
            //   {
            //     label: "independent",
            //     backgroundColor: "#D1CFCE",
            //     data: Arr3,
            //   },
            //   {
            //     label: "Extensions",
            //     backgroundColor: "#FFCC30",
            //     data: [0, 0, 0, 0, 0],
            //   },
            // ],
          }}
          labels="months"
        />
      </Box>
    </Sidebar>
  );
};
