import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Backdrop, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { SnackBar } from "../SnackBar";
import { useState } from "react";
import axios from "axios";
import { Apis } from "../../auth/GlobalUrl";

export const DeleteEducatorComp = ({ data, getAllData }) => {
    console.log(data)
    const [dialog, setDialog] = useState(false)
    const [loader, setLoader] = useState(false)
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "success",
    });
    const deleteEducator = async (e) => {
        e.preventDefault();
        try {
            setLoader(true)
            setDialog(false)
            let res = await axios.delete(Apis.EDUCATOR.delete + data._id)
            console.log(res)
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Educator deleted successfully",
                type: "error",
            });
            getAllData()
            setLoader(false)

        }
        catch (err) {
            console.log(err)
        }

    };



    return (
        <>
            <div className="pointer" onClick={() => setDialog(true)}>
                <span>
                    <DeleteOutlineOutlinedIcon
                        color="error"
                        sx={{ cursor: "pointer" }}
                    />
                </span>
                <span className="ms-3">Delete</span>
            </div>

            <Dialog
                open={dialog}
            >
                <DialogTitle id="alert-dialog-title">
                    Alert
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are You Sure You Want To Delete Educator?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialog(false)}
                        sx={{ color: "#3FAEED" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={deleteEducator}
                        sx={{ color: "red" }}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>



            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    )
}