import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import { BorderColor, Delete, MoreVert, Visibility } from "@mui/icons-material";
import { Box, Dialog, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Apis, baseURL } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";
import { DeleteEducatorComp } from "./DeleteEducator";
export const ActionEductor = ({ data, getAllEducators }) => {
  console.log(data)

  var navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <MoreVert aria-describedby={id} onClick={handleClick} sx={{ cursor: "pointer" }} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box py={2}>
          <div style={{}}>
            <div
              className="d-flex mx-4 pointer"
              onClick={() => navigate(`/teacherview/${data._id}`)}
            >
              <span>
                <Visibility sx={{ color: "orange", cursor: "pointer" }} />
              </span>
              <span className="ms-3">
                View
              </span>
            </div>
            <hr className="my-2 mx-3" />
            <div
              className="d-flex mx-4 pointer"
              style={{ marginTop: "15px" }}
              onClick={() => navigate(`/editeducator/${data._id}`)}
            >
              <span>
                <BorderColor color="info" sx={{ cursor: "pointer" }} />
              </span>
              <span className="ms-3">
                Edit
              </span>
            </div>
            <hr className="my-2 mx-3" />
            <div
              className="d-flex mx-4"
              style={{
                marginTop: "15px",
              }}
            >
              <DeleteEducatorComp getAllData={getAllEducators} data={data} />
            </div>
          </div>
        </Box>
      </Popover>
    </>
  );
};
