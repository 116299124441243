import { Button, Card, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, alertClasses, checkboxClasses } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { Apis } from "../../../auth/GlobalUrl"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { SnackBar } from "../../SnackBar"


export const Center = ({ allData, formSubmit, mode }) => {

    let [allAdmins, setAllAdmins] = useState([])
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "",
        type: "",
    });
    function inpChange(e) {
        let { name, value } = e.target
        allData.setData((pre) => ({ ...pre, [name]: value }))
    }

    let [logoFile, setLogoFile] = useState({})

    let allCenters = useRef([])

    function submitform(e) {
        e.preventDefault()

        let index = allCenters.current.findIndex(e => e.child_care_center_name.toLowerCase() == allData.data.child_care_center_name.toLowerCase())
        if (index !== -1 && mode == 'Add') {
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Center Name Already exist",
                type: "warning",
            })
            return
        }

        formSubmit(logoFile)


    }
    async function getAllCenters() {
        try {
            let res = await axios.get(Apis.SUPERADMIN.getAllChildCareCenters)
            console.log(res.data.data)

            allCenters.current = res.data.data
            console.log(allCenters.current)
        }
        catch (err) {
            console.log(err)
        }
    }
    let navigate = useNavigate()

    async function fileChange(e) {
        setLogoFile(e.target.files[0])

    }

    async function getAllAdmins() {
        try {
            let res = await axios.get(Apis.SUPERADMIN.getAllAdmins)
            setAllAdmins(res.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getAllAdmins()
        getAllCenters()
    }, [])
    return (
        <>
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
            <Card className="mx-sm-5 mx-2 p-sm-4 p-2">
                <form onSubmit={submitform}>
                    <div className="row">
                        <div className="col-sm-6 col-12">
                            <label>Childcare Center Name</label><br /><br />
                            <TextField placeholder="Center Name" required value={allData.data.child_care_center_name} fullWidth name="child_care_center_name" onChange={inpChange} />
                        </div>
                        <div className="col-sm-6 col-12">
                            <label>Childcare Center Address</label><br /><br />
                            <TextField placeholder="Center Address" required value={allData.data.center_address} fullWidth name='center_address' onChange={inpChange} />
                        </div>
                        <div className="col-sm-6 col-12 mt-3">
                            <label>Login Activity <small>{"(In minutes)"}</small></label><br /><br />
                            <TextField placeholder="Login Activity" type="number" required value={allData.data.login_activity_time} fullWidth name='login_activity_time' onChange={inpChange} />
                        </div>
                        <div className="col-sm-6 col-12 mt-3">
                            <label>Childcare Center Admins</label><br /><br />
                            <FormControl className="w-100">
                                <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
                                <Select required fullWidth
                                    labelId="demo-multiple-checkbox-label"
                                    id="demo-multiple-checkbox"
                                    multiple
                                    value={allData.data.admin_array}
                                    name='admin_array'
                                    onChange={(e) => inpChange(e)}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => allAdmins.filter(e => allData.data.admin_array.indexOf(e._id) !== -1).map(e => e.admin_name).join(',')}
                                >
                                    {allAdmins.map(res => (
                                        <MenuItem key={res.admin_name} value={res._id}>
                                            <Checkbox
                                                sx={{
                                                    [`&, &.${checkboxClasses.checked}`]: {
                                                        color: 'blue',
                                                    },
                                                }}
                                                checked={allData.data.admin_array.indexOf(res._id) > -1} />
                                            <ListItemText primary={res.admin_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div className="col-sm-6 col-12 mt-3">
                            <label>Childcare Center Logo</label><br /><br />
                            <input required={mode == 'Add'} type='file' className="form-control" onChange={fileChange} />
                        </div>
                    </div>

                    <div className="d-flex justify-content-end my-3">
                        <Button
                            variant="other"
                            sx={{ border: "1px solid #FF0000", color: "red" }}
                            onClick={() => navigate("/AllCenters")}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="other"
                            sx={{
                                background: "#1799CD",
                                color: "#ffffff",
                                border: "1px solid #1799CD",
                                marginLeft: "10px",
                            }}
                        >
                            Submit
                        </Button>
                    </div>

                </form>
            </Card>
        </>
    )
}