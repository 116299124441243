import {
  Grid,
  TextField,
  Table,
  TableRow,
  InputAdornment,
  Checkbox,
  Button,
  TableHead,
  TableBody,
  TableCell,
  Select,
  MenuItem,
  Backdrop,
  FormControl,
  InputLabel,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  TableContainer,
  Paper,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { Sidebar } from "../../components/Sidebar";
import { baseURL } from "../../auth/GlobalUrl";
import axios from "axios";
import { SnackBar } from "../SnackBar";
import { getLatestAge } from "../getLatestAge";
import { ProfileLogoName } from "../../components/ProfileLogoName";
import { ActionChildButton } from "./ActionChildButton";
import styles from "./child.module.css";

// import data from './childassign.json'

export default function ChildListing() {
  const [children, setChildren] = useState([]);
  let [allRooms, setAllRooms] = useState([]);
  const navigate = useNavigate();
  let [all, getAll] = useState([]);
  let [loader, setLoader] = useState(true);
  const [open, setOpen] = useState(false);
  const [deletedChildId, setDeletedChildId] = useState("");

  const matches = useMediaQuery("(min-width:800px)");

  const [childFilterObj, setChildFilterObj] = useState({
    childName: "",
    roomName: "",
  });
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });
  let tenant_id = localStorage.getItem("tenant_id");
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const getData = () => {
    var url = `${baseURL}child/getAllChild/${localStorage.getItem(
      "tenant_id"
    )}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setChildren(res.data.data);
        getAll(res.data.data);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log(children);
  useEffect(() => {
    getData();
    getAllRooms();
  }, []);

  function getAllRooms() {
    let url = `${baseURL}room/getAll/${tenant_id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setAllRooms(res.data.data);
      })
      .catch((err) => console.log(err));
  }

  function removeRoom(childId, roomId) {
    setLoader(true);
    console.log(childId);
    console.log(roomId);
    let url = `${baseURL}child/reomveRoomIntoChild/${childId}`;
    axios
      .put(url, { child_room: [roomId] })
      .then((res) => {
        console.log(res);
        setLoader(false);
        getData();
      })
      .catch((err) => console.log(err));
  }
  const [filterObj, setFilterObj] = useState({ childName: "", roomName: "" });
  function searchClass(e) {
    let { name, value } = e.target;
    setFilterObj({ ...filterObj, [name]: value });
    let arar = [];
    if (filterObj.roomName == "") {
      arar = [...all];
    } else if (filterObj.roomName == "noRoom") {
      arar = all.filter((e) => e.room_data.length == 0);
    } else {
      arar = all.filter((e) =>
        e.room_data.length !== 0
          ? e.room_data[0].room_name
              .toLowerCase()
              .includes(filterObj.roomName.toLowerCase())
          : null
      );
    }

    console.log(arar);

    setChildren(
      arar.filter((e) =>
        e.child_name.trim().toLowerCase().includes(value.trim().toLowerCase())
      )
    );
  }

  function searchRoom(e) {
    let { name, value } = e.target;
    value == "all"
      ? setFilterObj({ ...filterObj, [name]: "" })
      : setFilterObj({ ...filterObj, [name]: value });

    let arar = all.filter((e) =>
      e.child_name
        .trim()
        .toLowerCase()
        .includes(filterObj.childName.trim().toLowerCase())
    );
    if (value == "all") {
      setChildren(arar);
    } else if (value == "noRoom") {
      setChildren(arar.filter((e) => e.room_data.length == 0));
    } else {
      console.log(arar);
      setChildren(
        arar.filter((e) =>
          e.room_data.length !== 0
            ? e.room_data[0].room_name
                .trim()
                .toLowerCase()
                .includes(value == "all" ? "" : value.trim().toLowerCase())
            : null
        )
      );
    }
  }

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeletedChildId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const deleteChild = (e) => {
    console.log(deletedChildId);

    e.preventDefault();
    axios
      .delete(`${baseURL}child/deleteChild/${deletedChildId}`)
      .then((res) => {
        console.log(res);
        handleClose();
        ShowSnackbar({
          show: true,
          vertical: "top",
          horizontal: "right",
          msg: "Child deleted successfully",
          type: "success",
        });
        getData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Sidebar>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid container sx={{ backgroundColor: "#EFF6FF" }}>
          <Grid container mt={2} px={2} sx={{ alignItems: "center" }}>
            <Grid item lg={5} md={6}>
              <TextField
                onChange={searchClass}
                name="childName"
                className="searchInput"
                id="input-with-icon-textfield"
                placeholder="Search anything"
                size="medium"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid
              item
              lg={5}
              md={6}
              className="d-flex justify-content-end w-50 Drop_room p-2"
            >
              <FormControl className="searchInput">
                <InputLabel id="demo-simple-select-label">Rooms</InputLabel>
                <Select
                  name="roomName"
                  sx={{ backgroundColor: "white", width: "100%" }}
                  onChange={searchRoom}
                >
                  <MenuItem value="all">All Rooms</MenuItem>
                  <MenuItem value="noRoom">Unassigned Rooms</MenuItem>
                  {allRooms.map((roomData) => {
                    return (
                      <MenuItem value={roomData.room_name}>
                        {roomData.room_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid
              lg={2}
              md={12}
              className="d-flex mt-0 md-0 justify-content-end p-2 "
            >
              <Button
                onClick={() => navigate("/addBaseline")}
                className="btn_primary"
              >
                Add Baseline
              </Button>
            </Grid>
          </Grid>
          <Grid container mt={2} px={2}>
            <Grid item md={12} sm={12} xs={12}>
              <TableContainer
                component={Paper}
                className={`${styles.table_container}`}
              >
                <Table
                  sx={{
                    borderColor: "#B1B1B1",
                    width: "100%",
                  }}
                >
                  <TableHead sx={{ backgroundColor: "#FFFFFF" }}>
                    <TableRow
                      sx={{
                        bgcolor: "#FCFCFC",
                      }}
                    >
                      <TableCell align="left">S.No.</TableCell>
                      <TableCell align="left"></TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        <b>Child Name</b>
                      </TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        Age
                      </TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        Room
                      </TableCell>

                      <TableCell sx={{ color: "#434343" }} align="left">
                        Action
                      </TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        Assessment
                      </TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        Assign Room
                      </TableCell>
                      <TableCell>Term Goals</TableCell>
                      <TableCell sx={{ color: "#434343" }} align="left">
                        Progress
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {children.map((item, index) => {
                    return (
                      <TableBody key={index} sx={{ backgroundColor: "white" }}>
                        <TableRow
                          sx={{
                            bgcolor: "#FCFCFC",
                          }}
                        >
                          <TableCell align="left">
                            {index ? index + 1 : "1"}
                          </TableCell>

                          <TableCell align="left">
                            <ProfileLogoName
                              firstName={item.child_name}
                              lastName={item.child_lastname}
                            />
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#000000" }}>
                            {item.child_name}
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#000000" }}>
                            {getLatestAge(item.child_dob)} years
                          </TableCell>
                          <TableCell sx={{ color: "#434343" }} align="left">
                            {item.room_data.length !== 0
                              ? item.room_data[0].room_name
                              : "----"}
                          </TableCell>
                          <TableCell align="left">
                            {/* <RemoveRedEyeIcon
                              sx={{ color: "#FFB800", cursor: "pointer" }}
                              onClick={() => navigate(`/childview/${item._id}`)}
                            />{" "}
                            <EditIcon
                              onClick={() => navigate(`/editchild/${item._id}`)}
                              sx={{ color: "#169AC9", cursor: "pointer" }}
                            />
                            <DeleteIcon
                              onClick={() => handleClickOpen(item._id)}
                              sx={{ color: "#FF0000", cursor: "pointer" }}
                            /> */}
                            <ActionChildButton data={item} getData={getData} />
                            <Dialog open={open}>
                              <DialogTitle id="alert-dialog-title">
                                Confirm Delete
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  Are you sure you want to delete this child?
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={() => setOpen(false)}
                                  sx={{ color: "#3FAEED" }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  onClick={(e) => deleteChild(e, item._id)}
                                  sx={{ color: "red" }}
                                >
                                  Delete
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </TableCell>
                          <TableCell>
                            <Link to={`/childAssesmentbyadmin/${item._id}`}>
                              <Button
                                className="btn_primary"
                                // variant="contained"
                                sx={{ fontSize: "10px" }}
                              >
                                Assessment
                              </Button>
                            </Link>
                          </TableCell>
                          <TableCell align="left" sx={{ color: "#000000" }}>
                            {item.room_data.length == 0 ? (
                              <Link to={`/assignroomtochild/${item._id}`}>
                                <Button
                                  className="btn_primary"
                                  // variant="contained"
                                  sx={{ fontSize: "10px" }}
                                >
                                  Assign Room
                                </Button>
                              </Link>
                            ) : (
                              <Button
                                onClick={() =>
                                  removeRoom(item._id, item.room_data[0]._id)
                                }
                                className={`btn_primary ${styles.remove_room}`}
                                // variant="contained"
                                // sx={{ fontSize: "10px" }}
                              >
                                Remove Room
                              </Button>
                            )}
                          </TableCell>
                          <TableCell>
                            <Button
                              sx={{ fontSize: "10px" }}
                              // size="small"
                              // variant="contained"
                              className="btn_primary"
                              onClick={() =>
                                navigate(`/termsGoalsByAdmin/${item._id}`)
                              }
                            >
                              Term Goals
                            </Button>
                          </TableCell>

                          <TableCell>
                            <Button
                              onClick={() =>
                                navigate(`/childGraph/${item._id}`)
                              }
                              className="btn_primary"
                              // variant="contained"
                              sx={{ fontSize: "10px" }}
                            >
                              Graph
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
              <Typography></Typography>
            </Grid>
          </Grid>
        </Grid>
      </Sidebar>
    </>
  );
}
