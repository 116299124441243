import { Box } from "@mui/material"

export const ProfileLogoName = ({ firstName, lastName }) => {
    console.log(lastName)
    let first = firstName !== undefined ? firstName[0].toUpperCase() : ''
    let last = lastName !== undefined ? lastName[0].toUpperCase() : ''



    return (
        <>
            <Box className="d-flex justify-content-center align-items-center"
                sx={{
                    width: '45px', height: '45px', color: 'white', borderRadius: '50%', backgroundColor: '#fff1f6',
                    boxShadow: 'inset 0 0 4px #d26666',

                }}>
                <div style={{ color: '#991b1b', fontSize: '16px' }}>{first}</div >
                <div style={{ color: '#991b1b', fontSize: '16px' }}>{last}</div>
            </Box >
        </>
    )
}