import { Search } from "@mui/icons-material";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../../auth/GlobalUrl";
import { Sidebar } from "../../components/Sidebar";
import { ProfileLogoName } from "../../components/ProfileLogoName";
import { getLatestAge } from "../getLatestAge";



export const AllChildOfRoom = () => {
  let { id } = useParams();
  let [childs, allChilds] = useState([]);
  console.log(childs);
  let [loader, setLoader] = useState(true);
  let [noDataError, setNoDataError] = useState(false);
  let [all, setAll] = useState([]);
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  let navigate = useNavigate();
  useEffect(() => {
    getRoomChild();
  }, []);
  function getRoomChild() {
    setNoDataError(false);
    let url = `${baseURL}room/getRoom/${id}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data[0].child_data);
        allChilds(res.data.data[0].child_data);
        setAll(res.data.data[0].child_data);
        setLoader(false);
        setLoader(false);
        if (res.data.data[0].child_data.length == 0) {
          setNoDataError(true);
        }
      })
      .catch((err) => console.log(err));
  }

  function onSearch(e) {
    let { value } = e.target;
    allChilds(
      all.filter(
        (r) =>
          r.child_name.toLowerCase().includes(value.toLowerCase()) ||
          String(r.child_age)
            .toLowerCase()
            .includes(String(value.toLowerCase()))
      )
    );
  }

  return (
    <Sidebar>
      <Grid px={3}>
        <Link to={`/assessment/${localStorage.getItem("id")}`}>
          <ArrowBackIos />
        </Link>
      </Grid>
      {loader ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box sx={{ m: 2 }}>
          {noDataError ? (
            <Box>No data available</Box>
          ) : (
            <Box>
              <Grid container my={3}>
                <Grid item md={5} sm={5} xs={12}>
                  <TextField
                    id="input-with-icon-textfield"
                    placeholder="Search Name"
                    size="medium"
                    onChange={onSearch}
                    sx={{ backgroundColor: "white", width: "100%" }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ bgcolor: "white", overflow: "scroll" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sno.</TableCell>
                      <TableCell>Img</TableCell>
                      <TableCell>Child Name</TableCell>
                      <TableCell>Age</TableCell>
                      <TableCell>Assessment</TableCell>
                      <TableCell>Term Goals</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {childs.map((res, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {/* <img
                              src={
                                res.child_profileUrl
                                  ? res.child_profileUrl
                                  : blankImage
                              }
                              alt="#"
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50px",
                              }}
                            /> */}
                            <ProfileLogoName
                              firstName={res.child_name}
                              lastName={res.child_lastname}
                            />
                          </TableCell>
                          <TableCell>{res.child_name}</TableCell>
                          <TableCell>
                            {getLatestAge(res.child_dob
)}years
                            {/* {res.child_age} */}
                          </TableCell>
                          <TableCell>
                            <Button
                              className="btn_primary"
                              onClick={() =>
                                navigate(`/childAssesment/${res._id}`)
                              }
                            >
                              Assessment
                            </Button>
                          </TableCell>
                          <TableCell>
                            <Button
                              className="btn_primary"
                              onClick={() => navigate(`/termsGoals/${res._id}`)}
                            >
                              Term Goals
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Sidebar>
  );
};
