import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { Apis } from "../../auth/GlobalUrl"



export const ParentLogoApis = createAsyncThunk('parentLogo', async (data) => {
    try {
        let tenant_id = localStorage.getItem("tenant_id")
        let res = await axios.get(Apis.LOGO.get + tenant_id)
        return res.data.data
    }
    catch (err) {
        console.log(err)
    }
})

export const ParentLogoSlice = createSlice({
    name: 'Parent_logo',
    initialState: { logo_url: '' },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(ParentLogoApis.fulfilled, (state, action) => {
            localStorage.setItem("logo_url", action.payload.logo_url)
            localStorage.setItem("childcare_center", action.payload.childcare_center)
            console.log(action.payload)
            return action.payload
        })
    }
})
// export const { changeCenter, clearCenters } = ChildCareCentersSlice.actions