import axios from "axios"
import { useEffect, useState } from "react"
import { Apis } from "./GlobalUrl"
import { Backdrop, Box, Button, CircularProgress, Grid } from "@mui/material"
import { SnackBar } from "../pages/SnackBar"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { setPinStatus } from "../Redux-toolkit/Slices/EducatorPinSlice"
import styles from './EducatorPin.module.css'
import { ArrowBackIos, ArrowForward, BackspaceOutlined, Image, LogoutSharp } from "@mui/icons-material"
// import bcrypt from 'bcryptjs'
export const EducatorPin = () => {
    let { id } = useParams()

    const [pin, setPin] = useState('')
    const [loader, setLoader] = useState(false)
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "",
        type: "",
    });
    let navigate = useNavigate()
    let dispatch = useDispatch()
    async function formSubmit(e) {


        e.preventDefault()
        console.log(pin)


        try {
            setLoader(true)
            let res = await axios.put(Apis.EDUCATOR.verifyPin + id, { pin })
            console.log(res)
            if (res.data.data._id !== undefined) {

                localStorage.setItem('educatorId', id)
                localStorage.setItem('teacher_name', res.data.data.teacher_name)
                localStorage.setItem('roleName', res.data.data.teacher_name)

                // const salt = bcrypt.genSaltSync(10)
                let pinAuth = "dGhpcyBpcyBzdHJpbmc=dGhpcyBpcyBzdHJpbmc="
                // console.log(rrr)
                // const doesPasswordMatch = bcrypt.compareSync('Thisis password', rrr)
                // console.log(doesPasswordMatch)

                // let pinAuth = await bcrypt.hash(id.toString(), 12);

                sessionStorage.setItem("EducatorPinAuth", pinAuth)

                console.log(res.data.data)
                dispatch(setPinStatus(true))
                navigate(`/assessment/${res.data.data._id}`)
                localStorage.setItem("nav_name", "Assessment")
                setLoader(false)
            }
            else {
                ShowSnackbar({
                    show: true,
                    vertical: "top",
                    horizontal: "right",
                    msg: res.data.data,
                    type: "warning",
                });
                setLoader(false)
            }
        }
        catch (err) {
            console.log(err)
        }

    }
    let numArr = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    function btnClick(e) {
        setPin(pin + e)
    }
    function logout() {
        dispatch(setPinStatus(false))
        localStorage.clear()
        navigate('/')
    }
    function goBack() {
        navigate('/allEducatorsForRoom')
    }

    useEffect(() => {
        let inp = document.getElementById('pinId')
        inp.focus()
    }, [])
    function okok(e) {
        e.preventDefault()
        console.log('form submitted')
    }

    return (
        <div className={styles.pin_main_con}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />

            {/* <Button className="btn_primary" onClick={goBack}>Back</Button> */}
            <Grid>
                <ArrowBackIos
                    sx={{ color: "#3FAEED", cursor: "pointer", ml: 2, mt: 2 }}
                    onClick={goBack}
                />
            </Grid>

            <div class={`${styles.main_container}`}>
                <div className={styles.pin_main_container}>
                    <div className={styles.name_container}>
                        <div className="text-center fs-5 w-100">
                            Welcome to {localStorage.getItem('roomName')}
                        </div>
                    </div>
                    <div className={styles.pin_container}>
                        <div className={`${styles.input_con}`}>
                            <input
                                id='pinId'
                                maxLength={4}
                                readonly
                                className={styles.input_field}
                                onChange={(e) => {

                                    if (e.target.value[e.target.value.length - 1] * 0 == 0 || e.target.value == '') {
                                        setPin(e.target.value)
                                    }

                                    // e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                                }}
                                type="text"
                                value={pin}
                            />
                        </div>
                        <div className={`${styles.num_container}`}>
                            {numArr.map(num => {
                                return (
                                    <>
                                        <div className={styles.single_num_con}>
                                            <button disabled={pin.length == 4} onClick={() => btnClick(num)} className={styles.num}>
                                                {num}
                                            </button>
                                        </div>
                                    </>
                                )
                            }
                            )}
                            <div className={styles.single_num_con}>
                                <button onClick={() => {
                                    let ss = pin.split('')
                                    ss.pop()
                                    setPin(ss.join(''))
                                }} className={`${styles.num_backSpace} ${styles.num}`}>
                                    <BackspaceOutlined sx={{ fontSize: '20px' }} />
                                </button>
                            </div>
                            <div className={styles.single_num_con}>
                                <button disabled={pin.length == 4} onClick={() => btnClick(0)} className={styles.num}>
                                    0
                                </button>
                            </div>
                            <div className={styles.single_num_con}>
                                <button disabled={pin.length !== 4} onClick={formSubmit} className={`${styles.num_next} ${styles.num}`}>
                                    <ArrowForward />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Box onClick={logout} sx={{ ml: 2, cursor: 'pointer' }}>
                <b>
                    <LogoutSharp sx={{ color: "red" }} />{" "}
                    <span style={{ color: "gray" }}>Logout</span>
                </b>
            </Box>
        </div >
    )
}