import { Grid, Button, Typography, FormControl, Radio, InputLabel, Select, MenuItem, Checkbox, TextField, FormControlLabel, FormGroup, InputAdornment, } from "@mui/material";
import React from "react";
// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';
import { Sidebar } from "../../components/Sidebar";
import { useNavigate } from "react-router-dom";
const IntakeSummary = () => {
    const navigate = useNavigate();
    return (
        <>
            <Sidebar>
                <Grid m={3}>
                    <Typography>Injury On Intake Report Summary</Typography>
                    <Typography>&nbsp;</Typography>
                    <Grid px={2} py={5} sx={{ backgroundColor: 'white', border: '2px solid #bdbdbd', borderRadius: 1 }}>
                        <Grid container className='d-flex justify-content-end '>
                            <Grid item md={8} sm={8} xs={12} sx={{ height: '100%' }}>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginRight: '8px' }}> + </Button>
                                <TextField
                                    id="input-with-icon-textfield"

                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}

                                />
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <AutoAwesomeMotionIcon /> </Button>
                                <Button variant="outlined" sx={{ color: 'black', border: '1px solid gray', padding: '15px 0', marginLeft: '8px' }}> <DeleteIcon /> </Button>

                            </Grid>
                        </Grid>
                        <Grid container my={3} className='d-flex justify-content-center'>
                            <Grid item md={5} lg={5} xs={8} className='d-flex justify-content-between'>
                                <Typography variant="h4">Summary</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item >
                                <Typography>Name of child</Typography>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="select/enter"


                                    >
                                        <MenuItem value="select/enter">select/enter</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item >
                                <Typography>Reported Date/Time</Typography>
                            </Grid>
                            <Grid item >
                                <TextField type='date' />
                            </Grid>
                            <Grid item md={6}>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="11"


                                    >
                                        <MenuItem value="11">11</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="00"


                                    >
                                        <MenuItem value="00">00</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="am"


                                    >
                                        <MenuItem value="am">am</MenuItem>
                                        <MenuItem value="pm">pm</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={3}>
                                <Typography>Responsible person</Typography>
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="select/enter"


                                    >
                                        <MenuItem value="select/enter">select/enter</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox color='info' />} label="Me" />
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={3}>
                                <Typography>Room</Typography>
                            </Grid>
                            <Grid item MD={3}>
                                <TextField type='text' placeholder="Room Name" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={3}>
                                <Typography>Room Lead</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <TextField type='text' placeholder="Room Lead Name" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={6}>
                                <img src="https://t3.ftcdn.net/jpg/02/38/00/56/360_F_238005627_QgvAPoIEet2f14EAZHt0CqAmyLCByqIE.jpg" alt="" />
                            </Grid>
                            <Grid item md={6}>
                                <Grid container spacing={2} mt={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Abrasion, Scrape" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Cut" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label=" Bite" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label=" Allergic reaction" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Broken bone/fracture" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Anaphylaxis reaction" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label=" Brise" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Asthma respiratory" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Burn" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Sunburn" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Concussion" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={6}>
                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox color='info' />} label="Other" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item md={12}>
                                        <TextField type='text' placeholder="xyza" className="w-100 text1" />
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={4} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Grid item >
                                <Typography>Incident Date/Time</Typography>
                            </Grid>
                            <Grid item >
                                <TextField type="date" />
                            </Grid>
                            <Grid item md={6}>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="11"


                                    >
                                        <MenuItem value="11">11</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="00"


                                    >
                                        <MenuItem value="00">00</MenuItem>

                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label"></InputLabel>
                                    <Select

                                        id="demo-simple-select"
                                        value="am"


                                    >
                                        <MenuItem value="am">am</MenuItem>
                                        <MenuItem value="pm">pm</MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item >
                                <Typography>Incident Description</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} >
                            <Grid item sm={12}>
                                <TextField type='text' className=" textarea1" placeholder=" Describe the  story" />
                                <MicIcon />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }} >
                            <Grid item >
                                <Typography>Location of the incident</Typography>
                            </Grid>
                            <Grid item>
                                <TextField type='text' />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Was medical personal contracted?</Typography>
                            </Grid>
                            <Grid item md={3}>
                                <FormControlLabel value="Yes" control={<Radio color='info' />} label="Yes" />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item>
                                <Typography>If yes please provide the full name and number of the practitioner who gave further treatment</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item >
                                <Typography>Medical  Practitioner Name</Typography>
                            </Grid>
                            <Grid item >
                                <TextField type='text' placeholder=" Name" />
                            </Grid>
                            <Grid item >
                                <Typography>Contact Number</Typography>
                            </Grid>
                            <Grid item >
                                <TextField type='text' placeholder=" 0123456789" />
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid item md={4}>
                                <Typography>Was medical personal contracted?</Typography>
                            </Grid>
                            <Grid item md={2}>
                                <FormControlLabel value="Yes" control={<Radio color='info' />} label="Yes" />
                            </Grid>
                            <Grid item md={3}>
                                <TextField type='text' placeholder=" Name" />
                            </Grid>
                            <Grid item md={3}>
                                <TextField type='text' placeholder=" Pin" />
                            </Grid>
                            <Grid container ml={1} spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item>
                                    <Typography>Parent/Guardian Signature</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField type='text' placeholder=" Sign" />
                                </Grid>
                            </Grid>
                            <Grid container ml={1} spacing={2} mt={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item>
                                    <Typography>Parent/Guardian Signature</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField type='text' placeholder=" Name" />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid container sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <Grid item mt={6} >
                                <Button sx={{ mx: 2 }} className="cancel_btn" onClick={() => navigate('/injuryonintake')}>Back</Button>
                                <Button className="btn_primary">Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Sidebar>
        </>
    );
};
export default IntakeSummary;