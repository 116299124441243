import {
  Grid,
  TextField,
  Table,
  TableRow,
  InputAdornment,
  Checkbox,
  Button,
  TableHead,
  TableBody,
  TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Sidebar } from "../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../auth/GlobalUrl";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { contextData } from "../../Context";

export default function AssignRoom() {
  const navigate = useNavigate();
  const blankImage =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const [rooms, setRooms] = useState([]);
  const [all, setAll] = useState([]);

  let [roomId, setRoomId] = useState([]);
  const { id } = useParams();
  console.log(roomId);
  console.log(`${baseURL}teacher/addChildIntoTeacher/${id}`);
  console.log(id);

  const getData = () => {
    var url = `${baseURL}room/getAll/${localStorage.getItem('tenant_id')}`;
    axios
      .get(url)
      .then((res) => {
        console.log(res.data.data);
        setRooms(res.data.data);
        setAll(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postData = (e) => {
    e.preventDefault();

    console.log(roomId);
   
    axios
      .put(`${baseURL}teacher/addRoomsIntoTeacher/${id}`, {
        teacher_room: roomId,
      })
      .then((res) => {
        console.log(res);
        navigate("/educators");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getDataByCheck = (e, index) => {
    var { value, checked } = e.target;
    checked
      ? setRoomId([...roomId, value])
      : setRoomId(roomId.filter((e) => e !== value));
  };
  useEffect(() => {
    getData();
  }, []);
  function searchRoom(e){
    setRooms(all.filter(r=>r.room_name.trim().toLowerCase().includes(e.target.value.trim().toLowerCase())))
  }

  return (
    <>
      <Sidebar>
        <Grid px={3}>
          <Link to="/educators">
            <ArrowBackIosIcon />
          </Link>
        </Grid>
        <Grid container p={4} sx={{ backgroundColor: "#EFF6FF" }}>
          <Grid container>
            <Grid item md={5} sm={5} xs={10}>
              <TextField
              onChange={searchRoom}
                id="input-with-icon-textfield"
                placeholder="Search anything"
                className="searchInput"
                size="medium"
                sx={{ backgroundColor: "white" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              md={7}
              sm={7}
              xs={2}
              className="d-flex justify-content-end"
            >
              <Button
                onClick={() => navigate(`/removeassignroom/${id}`)}
                sx={{ height:'38px'}}
                className='btn_primary'
              >
                Remove Room
              </Button>
            </Grid>
          </Grid>
          <Grid container mt={4}>
            <Grid item md={12} sm={12} xs={12}>
              <Table
                sx={{
                  border: "2px solid",
                  borderColor: "#B1B1B1",
                  width: "100%",
                }}
              >
                <TableHead sx={{ backgroundColor: "#FFFFFF" }}>
                  <TableRow
                    sx={{ bgcolor: "#FCFCFC", boxShadow: "0 0 5px 0 #888888" }}
                  >
                    <TableCell></TableCell>
                    <TableCell sx={{ paddingLeft: "100px", color: "#434343" }}>
                      Room Name{" "}
                    </TableCell>
                    <TableCell sx={{ color: "#434343" }}>
                      Number of Child
                    </TableCell>
                    <TableCell sx={{ color: "#434343" }}>
                      Number of Educators
                    </TableCell>
                  </TableRow>
                </TableHead>
                {rooms.map((item, index) => {
                  if(item.room_teacherArray.indexOf(id)==-1){
                  return (
                    <TableBody key={index} sx={{ backgroundColor: "white" }}>
                      <TableRow
                        sx={{
                          bgcolor: "#FCFCFC",
                          boxShadow: "0 0 5px 0 #888888",
                        }}
                      >
                        <TableCell>
                          <Checkbox
                            value={item._id}
                            color="info"
                            onChange={getDataByCheck}
                            type="checkbox"
                          />
                        </TableCell>

                        <TableCell
                          sx={{ paddingLeft: "100px", color: "#868686" }}
                        >
                          {item.room_name}
                        </TableCell>
                        <TableCell sx={{ color: "#868686" }}>
                          {item.room_childArray.length}
                        </TableCell>
                        <TableCell sx={{ color: "#868686" }}>
                          {item.room_teacherArray.length}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  );
                }
                })}
              </Table>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              mt={6}
              md={12}
              sm={12}
              xs={12}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                variant="other"
                sx={{ border: "1px solid #FF0000", color: "red" }}
                onClick={() => {
                  navigate("/educators");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="other"
                className="btn_primary ms-2"
                onClick={postData}
              >
                Add Room
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Sidebar>
    </>
  );
}
