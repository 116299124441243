import { AddCircle } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useRef, useState } from "react";
import { Apis } from "../../auth/GlobalUrl";
import { SnackBar } from "../SnackBar";

export const AddRoom = ({ getAllRooms }) => {
  const [dialog, setDialog] = useState(false);
  const [passValidation, setPassValidation] = useState("");
  const [loader, setLoader] = useState(false);
  const [snackbar, ShowSnackbar] = useState({
    show: false,
    vertical: "top",
    horizontal: "right",
    msg: "data added",
    type: "error",
  });

  const roomData = useRef({
    room_name: "",
    room_email: "",
    room_password: "",
  });
 
  let tenant_id = localStorage.getItem("tenant_id");

  function inpChange({ name, value }) {
    roomData.current[name] = value;
  }
  const addRoom = async (e) => {
    setPassValidation("");
    e.preventDefault();
    console.log(roomData.current);

    let passwordregex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d!"#$%&'()*+,-.:;<=>?@[\]^_`{|}~"]{8,16}$/;

    if (roomData.current.room_password.match(passwordregex) !== null) {
      setLoader(true);
      setDialog(false);
      try {
        let res = await axios.post(Apis.ROOM.add, {
          ...roomData.current,
          tenant_id,
        });
        console.log(res , "jhgvhgdvuyeuydeuyokokok");
        roomData.current = {
          room_name: "",
          room_email: "",
          room_password: "",
        };
        setLoader(false);
        getAllRooms();
      } catch (err) {
        setDialog(true);
        setLoader(false);
        // if(err.response.data.errormessage){
          ShowSnackbar({
            show: true,
            vertical: "top",
            horizontal: "right",
            msg: err.response.data.errormessage,
            type: "warning",
          });
        // }
      }
    } else {
      setPassValidation(
        "Minimum length of password should be 8 character Password must contain: At least one UPPERCASE, one lowercase, one number, one special character(!,@,#,$"
      );
    }
  };

  return (
    <>
        <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
      <AddCircle
        sx={{ cursor: "pointer" }}
        color="info"
        onClick={() => setDialog(true)}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={dialog}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "500px",
            },
          },
        }}
      >
        <Box sx={{ p: 3 }}>
          <Typography className="text-center fs-3 mb-3">Add Room</Typography>
          <form onSubmit={addRoom}>
            <div>
              <TextField
                defaultValue={roomData.current.room_name}
                onChange={(e) => inpChange(e.target)}
                name="room_name"
                required
                fullWidth
                label="Room Name"
                className="mb-4"
              />
            </div>
            <div>
              <TextField
                defaultValue={roomData.current.room_email}
                type="email"
                name="room_email"
                onChange={(e) => inpChange(e.target)}
                required
                fullWidth
                label="Email Id"
              />
            </div>
            <div className="my-4">
              <TextField
                defaultValue={roomData.current.room_password}
                name="room_password"
                onChange={(e) => inpChange(e.target)}
                required
                label="Password"
                fullWidth
              />
            </div>
            <div style={{ color: "red" }}>{passValidation}</div>
            <div className="d-flex justify-content-end">
              <Button
                className="cancel_btn me-3"
                size="small"
                type="button"
                onClick={() => {
                  setDialog(false);

                  roomData.current = {
                    room_name: "",
                    room_email: "",
                    room_password: "",
                  };
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="info"
                size="small"
              >
                Submit
              </Button>
            </div>
          </form>
        </Box>
      </Dialog>
    </>
  );
};
