import { Backdrop, Box, Button, CircularProgress } from "@mui/material"
import { Sidebar } from "../../components/Sidebar"
import styles from './AddBaseLine.module.css'
import { useEffect, useState } from "react"
import axios from "axios"
import { Apis } from "../../auth/GlobalUrl"
import { SnackBar } from "../SnackBar"
import { useNavigate } from "react-router-dom"
export const AddBaseLine = () => {

    const [baseLine, setBaseLine] = useState([])
    const [loader, setLoader] = useState(false)
    const [snackbar, ShowSnackbar] = useState({
        show: false,
        vertical: "top",
        horizontal: "right",
        msg: "data added",
        type: "error",
    });
    let navigate = useNavigate()

    let tenant_id = localStorage.getItem('tenant_id')
    useEffect(() => {

        setBaseLine([])
        let arr = [];
        [...Array(45)].map((res, index) => {
            arr.push({
                month: index + 1,
                activity: null
            })
        })
        console.log(arr)
        setBaseLine([...arr])

    }, [])

    function inpChange({ value }, index) {
        let arr = [...baseLine]
        arr[index].activity = value
        setBaseLine([...arr])
    }

    async function addBaseLine(e) {
        e.preventDefault()
        console.log(baseLine)
        setLoader(true)
        try {
            let res = await axios.post(Apis.BASELINE.add, { data: baseLine, tenant_id })
            setLoader(false)
            if (res.data.data.data !== undefined) {
                ShowSnackbar({
                    show: true,
                    vertical: "top",
                    horizontal: "right",
                    msg: "Base Line Added Successfully",
                    type: "success",
                });
                let arr = [];
                [...Array(45)].map((res, index) => {
                    arr.push({
                        month: index + 1,
                        activity: ''
                    })
                })
                setBaseLine([...arr])
            }
            else {
                ShowSnackbar({
                    show: true,
                    vertical: "top",
                    horizontal: "right",
                    msg: "Base Line Already Exist",
                    type: "warning",
                });
            }
        }
        catch (err) {
            setLoader(false)
            ShowSnackbar({
                show: true,
                vertical: "top",
                horizontal: "right",
                msg: "Some Error Occured",
                type: "error",
            });
            console.log(err)
        }
    }

    return (
        <>
            <Sidebar>
                <div className="fs-3 text-center">Add Base Line</div>
                <Box className="mt-4">
                    <form onSubmit={addBaseLine}>

                        <div className="row mb-4 p-0 m-0 gx-3 px-4">

                            {baseLine.map((res, key) => {
                                return (
                                    <div key={key} className="row m-0 p-0 col-lg-4 col-6 mt-2 px-3"
                                    >
                                        <div className="col-4 p-0 m-0">M{key + 1}</div>
                                        <div className="col-8 p-0 m-0">
                                            <input required onChange={(e) => inpChange(e.target, key)} value={baseLine[key].activity} type="number" className={`${styles.inputField} ps-2 w-100`} />
                                        </div>
                                    </div>

                                )
                            })}
                        </div>

                        <Box className="d-flex justify-content-end px-5">
                            <Button type='button' className="cancel_btn me-3" onClick={() => navigate('/childlisting')}>Cancel</Button>
                            <Button type='submit' className="btn_primary">Submit</Button>
                        </Box>
                    </form>

                </Box>
                <SnackBar snackBarData={snackbar} setData={ShowSnackbar} />
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Sidebar>
        </>
    )
}